import * as React from "react";

import MatchChannel from "../../containers/matchChannel";
import MatchNews from "../../containers/matchNews";
import MatchPoll from "../../containers/matchPoll";
import MatchPotm from "../../containers/matchPotm";
import BannerAdvertisement from "../bannerAdvertisement";
import MatchVenue from "../matchVenue";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

const NATIONAL_COMPETITIONS = ["58544"]; // TODO

const LABELS = {
  TITLE_FEATURED_HIGHLIGHTS: "Featured Highlights",
  TITLE_HIGHLIGHTS: "Latest Highlights",
  TITLE_FEATURED_NEWS: "Featured News",
  TITLE_NEWS: "Latest News",
  NO_RESULTS: "There are currently no news items related to this match.",
};

export const MatchNewsPage = ({ match }) => (
  <section className={styles.section}>
    {!match.isEnded && (
      <MatchChannel id={match.id} date={match.formattedStartDate} />
    )}
    {match.venue && <MatchVenue venue={match.venue} />}
    <div className={styles.potm}>
      <MatchPotm match={match} />
    </div>
    {NATIONAL_COMPETITIONS.includes(match.competitionId) && (
      <MatchPoll match={match} />
    )}
    <BannerAdvertisement
      uid="match-news-page-1"
      competitionId={match.competitionId}
    />
    <MatchNews
      title={LABELS.TITLE_FEATURED_NEWS}
      isFeatured
      matchId={match.id}
      competitionId={match.competitionId}
    />
    <MatchNews
      title={LABELS.TITLE_NEWS}
      noResults={LABELS.NO_RESULTS}
      matchId={match.id}
      competitionId={match.competitionId}
    />
  </section>
);
