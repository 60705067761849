import Api from "../../../../service";
import {
  RESET_COMPETITIONSEASONS,
  SET_COMPETITIONSEASONS,
  SET_ERROR,
} from "./constants";

const setCompetitionSeasons = (data) => ({
  type: SET_COMPETITIONSEASONS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getCompetitionSeasons = (data) => (dispatch) =>
  Api.competition
    .getCompetitionSeasons(data)
    .then((response) => {
      dispatch(setCompetitionSeasons(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetCompetitionSeasons = (data) => ({
  type: RESET_COMPETITIONSEASONS,
  data,
});
