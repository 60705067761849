import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitionSeasons, resetCompetitionSeasons } from "./actions";
import { CompetitionSeasons as Component } from "./competitionSeasons";
import {
  selectCompetitionSeasons,
  selectIsError,
  selectIsReady,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  seasons: selectCompetitionSeasons,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionSeasons: (data) => dispatch(getCompetitionSeasons(data)),
  onResetCompetitionSeasons: (data) => dispatch(resetCompetitionSeasons(data)),
});

export function fetchData(store) {
  return store.dispatch(getCompetitionSeasons());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
