import * as React from "react";

import MFA_FB_LOGO from "./img/facebook.png";
import MFA_LOGO from "./img/mfa.png";
import TSN_LOGO from "./img/tsn.png";
import TVM_NEWS_PLUS_LOGO from "./img/tvm-news-plus.png";
import TVM_SPORTS_PLUS_LOGO from "./img/tvm-sports-plus.png";
import TVM_LOGO from "./img/tvm.png";
import MFA_YT_LOGO from "./img/youtube.png";
import styles from "./style.scss";

const TVM_SPORTS_PLUS_URL = "https://tvmi.mt/live/4";
const TVM_NEWS_PLUS_URL = "https://tvmi.mt/live/3";
const TVM_URL = "https://tvmi.mt/live/2";
const MFA_URL = "https://live.mfa.com.mt";
const MFA_FB_URL = "https://www.facebook.com/mfa1900";
const MFA_YT_URL = "https://www.youtube.com/user/mfavids";

const TVM_SPORTS_PLUS = /^tvm\ssports\+$/i;
const TVM_NEWS_PLUS = /^tvm\snews\+$/i;
const TVM = /^tvm$/i;
const MFA = /^mfa$/i;
const MFA_FB = /^facebook$/i;
const MFA_YT = /^youtube$/i;
const YT = /youtu\.?be/i;
const TSN = /^tsn$/i;

const MatchChannelLogo = ({ channel, doubleSize }) => (
  <>
    {MFA.test(channel.toLowerCase()) && (
      <a
        href={MFA_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={MFA_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {MFA_FB.test(channel.toLowerCase()) && (
      <a
        href={MFA_FB_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={MFA_FB_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {YT.test(channel.toLowerCase()) && (
      <a
        href={channel}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={MFA_YT_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {MFA_YT.test(channel.toLowerCase()) && (
      <a
        href={MFA_YT_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={MFA_YT_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {TVM.test(channel.toLowerCase()) && (
      <a
        href={TVM_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={TVM_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {TVM_NEWS_PLUS.test(channel.toLowerCase()) && (
      <a
        href={TVM_NEWS_PLUS_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={TVM_NEWS_PLUS_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {TVM_SPORTS_PLUS.test(channel.toLowerCase()) && (
      <a
        href={TVM_SPORTS_PLUS_URL}
        target="_blank"
        rel="noreferrer"
        className={styles.channelLink}
      >
        <img
          src={TVM_SPORTS_PLUS_LOGO}
          alt={channel}
          title={channel}
          className={`${styles.channelIcon} ${
            doubleSize ? styles.doubleSize : ""
          }`}
          loading="lazy"
        />
      </a>
    )}
    {TSN.test(channel.toLowerCase()) && (
      <img
        src={TSN_LOGO}
        alt={channel}
        title={channel}
        className={`${styles.channelIcon} ${
          doubleSize ? styles.doubleSize : ""
        }`}
      />
    )}
  </>
);

export default MatchChannelLogo;
