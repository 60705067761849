import fetch from "node-fetch";

export default class {
  async getAdvertisement({ uid, type, competitionId }) {
    const res = await fetch(
      `${process.env.CMS_URL}/advertisement/?type=${type}&${
        competitionId ? `competitionTypeId=${competitionId}&` : ""
      }${uid ? `uid=${uid}` : ""}`.replace(/&$/, "")
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
