import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_MATCHEVENTS, getKey } from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  events: {},
  score: {},
  isLive: {},
  matchTime: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHEVENTS:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        events: {
          ...state.events,
          [action.key]: {
            homeEvents: action.data.homeEvents,
            awayEvents: action.data.awayEvents,
          },
        },
        score: {
          ...state.score,
          [action.key]: {
            id: action.data.id,
            isPenalties: Boolean(
              action.data.homePenalties || action.data.awayPenalties
            ),
            homeScore: action.data.homeScore,
            awayScore: action.data.awayScore,
            homePenalties: action.data.homePenalties,
            awayPenalties: action.data.awayPenalties,
            resultSupplement: action.data.resultSupplement,
          },
        },
        isLive: {
          ...state.isLive,
          [action.key]: action.data.isLive,
        },
        matchTime: {
          ...state.matchTime,
          [action.key]: action.data.matchTime,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectHomeEvents = (state, props) => {
  const events = getState(state).events[getKey(props)];
  if (events) {
    return events.homeEvents.filter((n) => n.time !== "'");
  }
  return [];
};

export const makeSelectHomeEvents = () =>
  createSelector(selectHomeEvents, (n) => n);

const selectAwayEvents = (state, props) => {
  const events = getState(state).events[getKey(props)];
  if (events) {
    return events.awayEvents.filter((n) => n.time !== "'");
  }
  return [];
};

export const makeSelectAwayEvents = () =>
  createSelector(selectAwayEvents, (n) => n);

const selectScore = (state, props) => getState(state).score[getKey(props)];

export const makeSelectScore = () =>
  createSelector(selectScore, (score) => {
    if (score) {
      return {
        isPenalties: score.isPenalties,
        homeScore: score.homeScore,
        awayScore: score.awayScore,
        homePenalties: score.homePenalties,
        awayPenalties: score.awayPenalties,
        resultSupplement: score.resultSupplement,
      };
    }
    return {};
  });

const selectIsLive = (state, props) => getState(state).isLive[getKey(props)];

export const makeSelectIsLive = () => createSelector(selectIsLive, (n) => n);

const selectIsEnded = (state, props) =>
  getState(state).matchTime[getKey(props)];

export const makeSelectIsEnded = () =>
  createSelector(
    selectIsEnded,
    (n) => n === "FT" || n === "PEN" || n === "PEN FT" || n === "AET"
  );

const selectMatchTime = (state, props) =>
  getState(state).matchTime[getKey(props)];

export const makeSelectMatchTime = () =>
  createSelector(selectMatchTime, (n) => n);

reducerRegistry.register(REDUCER_NAME, reducer);
