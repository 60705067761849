import { match } from "path-to-regexp";

import data from "./page.json";

export default class {
  constructor() {
    this.data = data;
  }

  getPage({ url }) {
    return new Promise((resolve, reject) => {
      const response = this.data.reduce((acc, n) => {
        const page = match(n.url)(url.split("?")[0]);
        let output = acc;
        if (page) {
          output = { ...n, ...page };
        }
        return output;
      }, null);

      if (response) {
        resolve(response);
      } else {
        reject(new Error(`404: ${url} not found`));
      }
    });
  }
}
