import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getPlayerSeasons, resetPlayerSeasons } from "./actions";
import { PlayerSeasons as Component } from "./playerSeasons";
import { selectIsError, selectIsReady, selectPlayerSeasons } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  seasons: selectPlayerSeasons,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPlayerSeasons: (data) => dispatch(getPlayerSeasons(data)),
  onResetPlayerSeasons: (data) => dispatch(resetPlayerSeasons(data)),
});

export function fetchData(store) {
  return store.dispatch(getPlayerSeasons());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
