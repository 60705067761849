import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getPlayerHistory, resetPlayerHistory } from "./actions";
import Component from "./playerHistory";
import { selectIsError, selectIsReady, selectPlayerHistory } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  history: selectPlayerHistory,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPlayerHistory: (data) => dispatch(getPlayerHistory(data)),
  onResetPlayerHistory: (data) => dispatch(resetPlayerHistory(data)),
});

export function fetchData(store) {
  return store.dispatch(getPlayerHistory());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
