// eslint-disable-next-line no-unused-vars
import IFrameResizer from "iframe-resizer/js/iframeResizer.contentWindow";
import * as React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import WidgetNationalTeam from "../widgetNationalTeam";
import { ROUTES } from "./constants";

export const WidgetRoutes = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const limit = searchParams.get("limit") || 1;

  return (
    <Switch>
      <Route path={`${ROUTES.NATIONAL_TEAM}`}>
        <WidgetNationalTeam limit={limit} />
      </Route>
    </Switch>
  );
};
