import * as React from "react";
import { useParams } from "react-router-dom";

import NationalTeamMatches from "../../containers/nationalTeamMatches";
import NationalTeams from "../../containers/nationalTeams";
import styles from "./style.scss";

const WidgetNationalTeam = ({ limit }) => {
  const { type } = useParams();
  return (
    <ul className={styles.list}>
      <NationalTeams>
        {(nationalTeams) =>
          nationalTeams
            .splice(0, limit)
            .map(({ name, themeColor1, nationalTeamId }) => (
              <li key={nationalTeamId}>
                <NationalTeamMatches
                  isWidget
                  nationalTeamId={nationalTeamId}
                  type={type}
                  subtitle={name}
                  subtitleColor={themeColor1}
                  pageSize={1}
                />
              </li>
            ))
        }
      </NationalTeams>
    </ul>
  );
};

export default WidgetNationalTeam;
