import Api from "../../../../service";
import { setCompetitionId } from "../app/actions";
import { makeGetPage } from "../page/actions";
import { RESET_COMPETITION, SET_COMPETITION, SET_ERROR } from "./constants";

const setCompetition = (data) => ({
  type: SET_COMPETITION,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getCompetition = (data) => (dispatch) =>
  Api.competition
    .getCompetitionDetails({ id: data })
    .then((response) => {
      dispatch(setCompetition(response));
      dispatch(setCompetitionId(response.id));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

const getPage = (data) => (dispatch) =>
  Api.competition
    .getPage(data)
    .then((response) => {
      dispatch(setCompetition(response.competition));
      dispatch(setCompetitionId(response.competition.id));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getCompetitionPage = (data) => (dispatch) =>
  makeGetPage(getPage)(data)(dispatch);

export const resetCompetition = (data) => ({
  type: RESET_COMPETITION,
  data,
});
