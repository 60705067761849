import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getTeamPlayers, resetTeamPlayers } from "./actions";
import { selectIsError, selectIsReady, selectTeamPlayers } from "./reducer";
import Component from "./teamPlayers";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  players: selectTeamPlayers,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTeamPlayers: (data) => dispatch(getTeamPlayers(data)),
  onResetTeamPlayers: (data) => dispatch(resetTeamPlayers(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
