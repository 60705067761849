import Api from "../../../../service";
import {
  RESET_COMPETITIONNEWS,
  SET_COMPETITIONNEWS,
  SET_ERROR,
  getKey,
} from "./constants";

const setCompetitionNews = (data, key) => ({
  type: SET_COMPETITIONNEWS,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getCompetitionNews = (data) => (dispatch) =>
  Api.competition
    .getCompetitionNews(data)
    .then((response) => {
      dispatch(setCompetitionNews(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });

export const resetCompetitionNews = () => ({
  type: RESET_COMPETITIONNEWS,
});
