export const REDUCER_NAME = "team";
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;
export const SET_TEAM = `${REDUCER_NAME}/SET_TEAM`;

export const ROUTES = {
  NEWS: "/articles",
  HIGHLIGHTS: "/highlights",
  PAST_SEASONS: "/archive",
  HISTORY: "/history",
  PLAYERS: "/players",
  TABLE: "/table",
  EVENTS: "/",
};
