import fetch from "node-fetch";

export default class {
  async getNewsPage({ url }) {
    const res = await fetch(
      `${process.env.CMS_URL}/news/getDetailPage?url=${url}`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
