import { connect } from "react-redux";

import { getMatchLineup, resetMatchLineup } from "./actions";
import { MatchLineup as Component } from "./matchLineup";
import {
  makeSelectAwayLineup,
  makeSelectHomeLineup,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectAwayLineup = makeSelectAwayLineup();
  const selectHomeLineup = makeSelectHomeLineup();
  const selectIsError = makeSelectIsError();
  const selectIsReady = makeSelectIsReady();

  const mapStateToProps = (state, ownProps) => ({
    homeLineup: selectHomeLineup(state, ownProps),
    awayLineup: selectAwayLineup(state, ownProps),
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetMatchLineup: (data) => dispatch(getMatchLineup(data)),
  onResetMatchLineup: (data) => dispatch(resetMatchLineup(data)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
