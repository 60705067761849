import * as React from "react";
import { Link } from "react-router-dom";

import styles from "./style.scss";

const Component = ({ navigation }) => (
  <ul className={styles.list}>
    {navigation.map((n) => (
      <li key={n.id} className={styles.item}>
        {n.isExternal ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={n.url}
            className={styles.link}
            target={n.isNewWindow ? "_blank" : "_self"}
            rel={n.isNewWindow ? "noreferrer" : ""}
          >
            {n.title}
          </a>
        ) : (
          <Link to={n.url} className={styles.link}>
            {n.title}
          </Link>
        )}
      </li>
    ))}
  </ul>
);

export default Component;
