import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getNationalTeamCoaches, resetNationalTeamCoaches } from "./actions";
import Component from "./nationalTeamCoaches";
import {
  selectIsError,
  selectIsReady,
  selectNationalTeamCoaches,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  players: selectNationalTeamCoaches,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNationalTeamCoaches: (data) => dispatch(getNationalTeamCoaches(data)),
  onResetNationalTeamCoaches: (data) =>
    dispatch(resetNationalTeamCoaches(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
