import fetch from "node-fetch";

export default class {
  async getTeamNews({ id, isFeatured = false, pageSize }) {
    try {
      const res = await fetch(
        `${
          process.env.CMS_URL
        }/news/getTeamNews?teamId=${id}&isFeatured=${isFeatured}${
          pageSize ? `&pageSize=${pageSize}` : ""
        }`
      );
      if (res.ok) {
        return res.json();
      }
      throw new Error(res.statusText);
    } catch (e) {
      return console.error(e);
    }
  }
}
