import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getPlayerStatistics, resetPlayerStatistics } from "./actions";
import Component from "./playerStatistics";
import {
  selectIsError,
  selectIsReady,
  selectPlayerStatistics,
  selectPlayerStatisticsKeys,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  competitions: selectPlayerStatistics,
  keys: selectPlayerStatisticsKeys,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPlayerStatistics: (data) => dispatch(getPlayerStatistics(data)),
  onResetPlayerStatistics: (data) => dispatch(resetPlayerStatistics(data)),
});

export function fetchData(store) {
  return store.dispatch(getPlayerStatistics());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
