import * as React from "react";

import ErrorMessage from "../errorMessage";

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { errorInfo, error } = this.state;

    if (error) {
      return <ErrorMessage error={error} errorInfo={errorInfo} />;
    }

    return children;
  }
}

export default Component;
