import Api from "../../../../service";
import {
  RESET_PLAYERSTATISTICS,
  SET_ERROR,
  SET_PLAYERSTATISTICS,
} from "./constants";

const setPlayerStatistics = (data) => ({
  type: SET_PLAYERSTATISTICS,
  data,
});

export const resetPlayerStatistics = (data) => ({
  type: RESET_PLAYERSTATISTICS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getPlayerStatistics = (data) => (dispatch) =>
  Api.player
    .getPlayerStatistics(data)
    .then((response) => {
      dispatch(setPlayerStatistics(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
