import * as React from "react";

import ErrorBoundary from "../../components/errorBoundary";
import styles from "./style.scss";

const LABELS = {
  SHOW_MORE: "Show more",
  DATE_PREFIX: "Published",
  NO_RESULTS:
    "We were unable to retrieve any highlights. Please try again later.",
};

const HomeHighlights = ({
  title,
  onGetHighlights,
  onResetHighlights,
  isReady,
  isError,
  highlights,
  nextPageToken,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetHighlights();
    }
  }, [onGetHighlights, isReady]);

  React.useEffect(() => () => onResetHighlights(), [onResetHighlights]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return (
      <div className={styles.section}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.highlights}>
          {[...Array(4)].map((n, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.item} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.section}>
      <h2 className={styles.title}>{title}</h2>
      <ErrorBoundary>
        {highlights.length > 0 ? (
          <>
            <ul className={styles.highlights}>
              {highlights.map((n) => (
                <li key={n.id} className={styles.item}>
                  <div className={styles.wrapper}>
                    <iframe
                      loading="lazy"
                      type="text/html"
                      width="560"
                      height="315"
                      frameBorder="0"
                      src={`https://www.youtube.com/embed/${n.id}?controls=1&modestbranding=1`}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={n.title}
                      className={styles.iframe}
                    />
                  </div>
                  <div className={styles.content}>
                    <h3 className={styles.itemTitle}>{n.title}</h3>
                    <p className={styles.itemDescription}>{n.description}</p>
                    <div className={styles.footer}>
                      <span
                        className={styles.date}
                      >{`${LABELS.DATE_PREFIX}: ${n.formattedDate}`}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {nextPageToken && (
              <button
                type="button"
                onClick={() =>
                  onGetHighlights({
                    pageToken: nextPageToken,
                  })
                }
                className={styles.showMore}
              >
                {LABELS.SHOW_MORE}
              </button>
            )}
          </>
        ) : (
          <p>{LABELS.NO_RESULTS}</p>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default HomeHighlights;
