import * as React from "react";

import styles from "./style.scss";

const MatchChannel = ({
  id,
  onGetMatchChannel,
  isReady,
  channel,
  isYouTube,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchChannel({ id });
    }
  }, [onGetMatchChannel, id, isReady]);

  if (!(isReady && channel && isYouTube)) {
    return null;
  }

  const youTubeId = channel.split("/").pop();

  if (!youTubeId) {
    return null;
  }

  const embedURL = `https://www.youtube.com/embed/${youTubeId}`;

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <iframe
          type="text/html"
          width="560"
          height="315"
          src={embedURL}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={styles.iframe}
        />
      </div>
    </section>
  );
};

export default MatchChannel;
