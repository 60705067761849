import Api from "../../../../service";
import { SET_ERROR, SET_MATCHCHANNEL, getKey } from "./constants";

const setMatchChannel = (data, key) => ({
  type: SET_MATCHCHANNEL,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getMatchChannel = (data) => (dispatch) =>
  Api.match
    .getChannel(data)
    .then((response) => {
      dispatch(setMatchChannel(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });
