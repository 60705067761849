import * as React from "react";
import { Link } from "react-router-dom";

import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import { NewsArticle } from "../../components/newsArticle/newsArticle";
import styles from "./style.scss";

const LABELS = {
  LOAD_MORE: "See all",
};

const READ_MORE_URL = "/articles";
const PAGE_SIZE = 4;

export const HomeNewsSection = ({
  onGetHomeNews,
  isReady,
  isError,
  news,
  title,
  limit,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetHomeNews({ pageSize: PAGE_SIZE });
    }
  }, [onGetHomeNews, isReady]);

  if (isError) {
    return (
      <section className={styles.section}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section className={styles.section}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <ul className={`${styles.list}`}>
          {[...Array(limit || PAGE_SIZE)].map((n, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  return (
    <section className={styles.section}>
      <h2 className={styles.title}>{title}</h2>
      <Link
        to={READ_MORE_URL}
        className={styles.readMore}
        onClick={() => window.scrollTo(0, 0)}
      >
        {LABELS.LOAD_MORE}
      </Link>
      <ErrorBoundary>
        <ul className={styles.list}>
          {news.slice(0, limit || Infinity).map((n) => (
            <li key={n.id} className={styles.listItem}>
              <Link to={n.url} title={n.title} className={styles.listItemLink}>
                <NewsArticle data={n} displayAsRow />
              </Link>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};
