import * as React from "react";
import { Route, Switch } from "react-router-dom";

import HomeHighlightsPage from "../../components/homeHighlightsPage";
import HomeNewsPage from "../../components/homeNewsPage";
import HomePage from "../../components/homePage";
import PastMatchesPage from "../../components/pastMatchesPage";

const DEFAULT_COMPETITION_ID = 58539;

export const ROUTES = {
  HOME: "/",
  PAST_MATCHES: "/past-matches",
  HIGHLIGHTS: "/highlights",
  NEWS: "/articles",
};

export const Routes = React.memo(() => {
  return (
    <Switch>
      <Route path={`${ROUTES.PAST_MATCHES}`}>
        <PastMatchesPage />
      </Route>
      <Route path={`${ROUTES.NEWS}`}>
        <HomeNewsPage />
      </Route>
      <Route path={`${ROUTES.HIGHLIGHTS}`}>
        <HomeHighlightsPage />
      </Route>
      <Route path={`${ROUTES.HOME}`} exact>
        <HomePage competitionId={DEFAULT_COMPETITION_ID} />
      </Route>
    </Switch>
  );
});
