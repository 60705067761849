import Api from "../../../../service";
import {
  RESET_COMPETITIONCUP,
  SET_COMPETITIONCUP,
  SET_ERROR,
} from "./constants";

const setCompetitionCup = (data) => ({
  type: SET_COMPETITIONCUP,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getCompetitionCup = (data) => (dispatch) =>
  Api.competition
    .getCompetitionCup(data)
    .then((response) => {
      dispatch(setCompetitionCup(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetCompetitionCup = (data) => ({
  type: RESET_COMPETITIONCUP,
  data,
});
