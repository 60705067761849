/* eslint-disable css-modules/no-undef-class */
import * as React from "react";
import GliderComponent from "react-glider";
import { useRouteMatch } from "react-router-dom";

import Gallery from "../../components/gallery";
import LeaderboardOrSquareAdvertisement from "../../components/leaderboardOrSquareAdvertisement";
import ResponsiveImage from "../../components/shared/responsiveImage";
import SquareAdvertisement from "../../components/squareAdvertisement";
import HomeNewsSection from "../homeNewsSection";
import Page from "../page";
import styles from "./critical.scss";

const LABELS = {
  TITLE_NEWS: "LATEST NEWS",
};

export const News = ({ date, category, tags, onGetNewsPage }) => {
  const { url } = useRouteMatch();

  const getPage = React.useCallback(onGetNewsPage, [onGetNewsPage]);

  return (
    <Page url={url} onGetPage={getPage} scrollToTop>
      {({ isReady, page: { html, title, banners, gallery } }) => {
        if (!isReady) {
          return (
            <>
              <div className={`${styles.banner} ${styles.skeleton}`} />
              <div className={styles.pageContent}>
                <div className={`${styles.date} ${styles.skeletonDate}`} />
                <div
                  className={`${styles.pageContentRichtext} ${styles.skeletonContent}`}
                />
              </div>
            </>
          );
        }

        return (
          <section className={styles.news}>
            {banners && (
              <div className={styles.banner}>
                <GliderComponent
                  hasDots={banners.length > 1}
                  hasArrows={false}
                  slidesToShow={1}
                  scrollLock
                  scrollLockDelay={50}
                  duration={0.5}
                >
                  {banners.map(
                    ({ id, images }) =>
                      // eslint-disable-next-line react/no-array-index-key
                      images && (
                        <div key={id} className={styles.gliderContent}>
                          <div className={styles.image}>
                            <ResponsiveImage
                              src={images.src}
                              srcset={images.srcset}
                              alt={images.alt}
                              className={styles.imageImg}
                              lazyload
                            />
                          </div>
                        </div>
                      )
                  )}
                </GliderComponent>
                <div className={styles.textWrapper}>
                  <div
                    className={styles.text}
                    style={{ color: banners[0].color }}
                  >
                    {category && (
                      <h2 className={styles.category}>{category.title}</h2>
                    )}
                    <h1 className={`${styles.title} ${styles.bannerTitle}`}>
                      {title}
                    </h1>
                  </div>
                </div>
              </div>
            )}
            {!banners && (
              <div className={styles.header}>
                {Boolean(category) && (
                  <h2 className={styles.category}>{category.title}</h2>
                )}
                <h1 className={styles.title}>{title}</h1>
                <span className={styles.date}>Published: {date}</span>
                {tags && Boolean(tags.length) && (
                  <div className={styles.tags}>
                    {tags.map((n) => (
                      <span className={styles.tag}>{n}</span>
                    ))}
                  </div>
                )}
                <div className={styles.thinLine} />
              </div>
            )}
            <div className={styles.container}>
              <div className={styles.content}>
                {!!banners && (
                  <>
                    <span className={styles.date}>Published: {date}</span>
                    {tags && Boolean(tags.length) && (
                      <div className={styles.tags}>
                        {tags.map((n) => (
                          <span className={styles.tag}>{n}</span>
                        ))}
                      </div>
                    )}
                    <div className={styles.thinLine} />
                  </>
                )}
                {gallery && Boolean(gallery.length) && (
                  <>
                    <Gallery data={gallery} />
                    <div className={styles.thinLine} />
                  </>
                )}
                <div
                  className="richtext"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                <LeaderboardOrSquareAdvertisement uid="corporate-news-page-1" />
              </div>
              <aside className={styles.aside}>
                <HomeNewsSection limit={4} title={LABELS.TITLE_NEWS} />
                <SquareAdvertisement uid="corporate-news-page-2" />
              </aside>
            </div>
          </section>
        );
      }}
    </Page>
  );
};
