import * as React from "react";
import { Link } from "react-router-dom";

import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import usePoll from "../../hooks/poll";
import styles from "./style.scss";

const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const COMPETITION_PLACEHOLDER = ":competitionId";

const POLL_INTERVAL = 60000;

const LABELS = {
  POS: "",
  CLUB: "",
  MP: "MP",
  GD: "GD",
  PTS: "PTS",
};

const CompetitionTableSummary = ({
  competitionId,
  teamId,
  onGetCompetitionTable,
  onResetCompetitionTable,
  isReady,
  isError,
  standings,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionTable({ id: competitionId });
    }
  }, [competitionId, onGetCompetitionTable, isReady]);

  React.useEffect(
    () => () => onResetCompetitionTable(competitionId),
    [onResetCompetitionTable, competitionId]
  );

  const handleGetCompetitionTable = React.useCallback(
    () => onGetCompetitionTable({ id: competitionId }),
    [onGetCompetitionTable, competitionId]
  );

  usePoll({
    callback: handleGetCompetitionTable,
    interval: POLL_INTERVAL,
  });

  if (isError) {
    return (
      <section>
        <h3 className={styles.title}>&nbsp;</h3>
        <ul className={styles.list}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnPos}`}>
              {LABELS.POS}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.MP}</span>
            <span className={styles.column}>{LABELS.GD}</span>
            <span className={styles.column}>{LABELS.PTS}</span>
          </li>
        </ul>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <h3 className={styles.title}>&nbsp;</h3>
        <ul className={styles.list}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnPos}`}>
              {LABELS.POS}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.MP}</span>
            <span className={styles.column}>{LABELS.GD}</span>
            <span className={styles.column}>{LABELS.PTS}</span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(16)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!standings.length) {
    return null;
  }

  return (
    <section>
      <ErrorBoundary>
        {standings[standings.length - 1]
          .sort((a, b) => a.id - b.id)
          .map((n) => (
            <React.Fragment key={n.id}>
              {n.title && <h3 className={styles.title}>{n.title}</h3>}
              <ul className={styles.list}>
                <li className={`${styles.listItem} ${styles.listItemHeading}`}>
                  <span className={`${styles.column} ${styles.columnPos}`}>
                    {LABELS.POS}
                  </span>
                  <span className={`${styles.column} ${styles.columnTeam}`}>
                    {LABELS.CLUB}
                  </span>
                  <span className={styles.column}>{LABELS.MP}</span>
                  <span className={styles.column}>{LABELS.GD}</span>
                  <span className={styles.column}>{LABELS.PTS}</span>
                </li>
              </ul>
              <ul className={`${styles.list} ${styles.listData}`}>
                {n.table.map((m) => (
                  <li
                    key={m.id}
                    className={`${styles.listItem} ${
                      teamId === m.teamId ? styles.listItemSelected : ""
                    }`}
                  >
                    <span className={`${styles.column} ${styles.columnPos}`}>
                      {m.pos}
                    </span>
                    <div
                      className={`${styles.column} ${styles.columnTeam} ${
                        m.isLive ? styles.isLive : ""
                      }`}
                    >
                      <span className={styles.columnImgWrapper}>
                        <img
                          src={m.logoUrl}
                          alt=""
                          className={styles.columnImg}
                          loading="lazy"
                        />
                      </span>
                      <Link
                        to={TEAM_URL_TEMPLATE.replace(
                          TEAM_PLACEHOLDER,
                          m.teamId
                        ).replace(COMPETITION_PLACEHOLDER, competitionId)}
                        className={styles.columnName}
                      >
                        {m.name}
                      </Link>
                    </div>
                    <span className={styles.column}>{m.mp}</span>
                    <span className={styles.column}>{m.gd}</span>
                    <span className={`${styles.column} ${styles.columnPts}`}>
                      {m.pts}
                    </span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
      </ErrorBoundary>
    </section>
  );
};

export default CompetitionTableSummary;
