import { connect } from "react-redux";
import { compose } from "redux";

import { getCompetitionMatches, resetCompetitionMatches } from "./actions";
import Component from "./nationalTeamCompetitionResults";
import {
  makeSelectCompetitionMatches,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectCompetitionMatches = makeSelectCompetitionMatches();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    matches: selectCompetitionMatches(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionMatches: (data) => dispatch(getCompetitionMatches(data)),
  onResetCompetitionMatches: (data) => dispatch(resetCompetitionMatches(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
