import { pathToRegexp } from "path-to-regexp";
import * as React from "react";

import ScrollToTopOnMount from "../../components/routes/scrollToTopOnMount";

const DEFAULT_VALUE = {
  meta: {},
  id: 0,
  title: null,
  html: null,
  url: null,
  thumbnail: null,
  banners: null,
};

const Page = ({
  url,
  isReady,
  page,
  params,
  onGetPage,
  onSetTitle,
  onSetMeta,
  children,
  scrollToTop,
}) => {
  React.useEffect(() => {
    const currentUrl = page ? page.url : undefined;

    if (!(pathToRegexp(url).test(currentUrl) || currentUrl === url)) {
      // content doesn't exist in store
      onGetPage({ url, ...params });
    } else {
      // content exists in store and user has navigated to a new URL
      onSetTitle(page.title);
      onSetMeta(page.meta);
    }
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, onSetTitle, onSetMeta, onGetPage, params]);

  if (typeof onGetPage === "undefined") {
    console.warn("<Page /> requires 'onGetPage' prop to be defined");
    return null;
  }

  return (
    <>
      {scrollToTop && <ScrollToTopOnMount />}
      {children({ isReady, page: page ?? DEFAULT_VALUE })}
    </>
  );
};

export default Page;
