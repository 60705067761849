import fetch from "node-fetch";

export default class {
  async getSponsors() {
    const res = await fetch(`${process.env.CMS_URL}/sponsors`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getFeaturedMatches() {
    const res = await fetch(`${process.env.CMS_URL}/matches/featured`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
