import * as React from "react";

import styles from "./style.scss";

const SkeletonMatchLineup = () => (
  <section className={styles.section}>
    <ul className={styles.list}>
      {[...Array(4)].map((n, index) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`${styles.listItem} ${styles.listItemHome}`}
        />
      ))}
    </ul>
    <div className={styles.separator} />
    <ul className={styles.list}>
      {[...Array(4)].map((n, index) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`${styles.listItem} ${styles.listItemAway}`}
        />
      ))}
    </ul>
  </section>
);

export default SkeletonMatchLineup;
