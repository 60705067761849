import React, { useState } from "react";

import styles from "./critical.scss";

const LOCALSTORAGE_KEY = "favorite_teams";

const LABELS = {
  TEXT: "Add to favorites",
};

const FavoriteTeam = ({ teamId }) => {
  const [isActive, setActive] = useState(false);
  const [isReady, setReady] = useState(false);

  React.useEffect(() => {
    const teams = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || [];
    if (teams.includes(teamId)) {
      setActive(true);
    }
    setReady(true);
  }, [teamId]);

  if (!isReady) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={() => {
        const teams = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || [];
        if (teams.includes(teamId)) {
          localStorage.setItem(
            LOCALSTORAGE_KEY,
            JSON.stringify([
              ...teams.slice(0, teams.indexOf(teamId)),
              ...teams.slice(teams.indexOf(teamId) + 1),
            ])
          );
          setActive(false);
        } else {
          localStorage.setItem(
            LOCALSTORAGE_KEY,
            JSON.stringify([...teams, teamId])
          );
          setActive(true);
        }
      }}
      className={`${styles.button} ${isActive ? styles.active : ""}`}
    >
      <span className={styles.text}>{LABELS.TEXT}</span>
    </button>
  );
};

export default FavoriteTeam;
