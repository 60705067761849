import { connect } from "react-redux";
import { compose } from "redux";

import { getMatchChannel } from "../matchChannel/actions";
import {
  makeSelectChannel,
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectIsYouTube,
} from "../matchChannel/reducer";
import Component from "./matchLiveEmbed";

const makeMapStateToProps = () => {
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const selectChannel = makeSelectChannel();
  const selectIsYouTube = makeSelectIsYouTube();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    channel: selectChannel(state, ownProps),
    isYouTube: selectIsYouTube(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetMatchChannel: (data) => dispatch(getMatchChannel(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
