import * as React from "react";
import { Link } from "react-router-dom";

import { useCompetitionContext } from "../../components/competition/context";
import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import LeaderboardOrSquareAdvertisement from "../../components/leaderboardOrSquareAdvertisement";
import SkeletonMatchLineup from "../../components/skeletonMatchLineup";
import usePoll from "../../hooks/poll";
import { NATIONAL_TEAM_ID } from "../nationalTeam/constants";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";
import styles from "./style.scss";

const POLL_INTERVAL_LIVE = 12000;

const PLACEHOLDER = ":competitionId";
const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const PLAYER_URL_TEMPLATE = "/player/:playerId";
const PLAYER_PLACEHOLDER = ":playerId";

const LABELS = {
  COACHES: "Coaches",
  REFEREES: "Referees",
  LINEUP_COUNTDOWN: "Lineups will be available",
  LINEUP_UNAVAILABLE: "Lineups for this match are unavailable.",
  SUBSTITUTIONS: "Substitutions",
  DISCLAIMER:
    "The data related to match events is entered by a third party and under no circumstances should be considered as the official match report of the Malta FA",
};

export const MatchLineup = ({
  onGetMatchLineup,
  onResetMatchLineup,
  homeLineup,
  awayLineup,
  homeTeam,
  awayTeam,
  isLineupAvailable,
  matchId,
  isReady,
  isLive,
  isEnded,
  referees,
  formattedLineupAvailableDate,
}) => {
  const {
    state: { id: competitionId },
  } = useCompetitionContext();

  const isNationalTeamParticipating =
    homeTeam.id === NATIONAL_TEAM_ID || awayTeam.id === NATIONAL_TEAM_ID;

  React.useEffect(() => {
    if (!isReady) {
      onGetMatchLineup({ matchId });
    }
  }, [onGetMatchLineup, matchId, isReady]);

  React.useEffect(
    () => () => onResetMatchLineup(matchId),
    [onResetMatchLineup, matchId]
  );

  const handleGetMatchLineup = React.useCallback(
    () => isLineupAvailable && onGetMatchLineup({ matchId }),
    [onGetMatchLineup, matchId, isLineupAvailable]
  );

  usePoll({
    callback: handleGetMatchLineup,
    interval: isLive ? POLL_INTERVAL_LIVE : 0,
  });

  if (isLineupAvailable) {
    if (!isReady) {
      return <SkeletonMatchLineup />;
    }

    return (
      <>
        <section className={styles.section}>
          <ul className={`${styles.list} ${styles.listHome}`}>
            <li
              className={`${styles.listItem} ${styles.listItemTitle} ${styles.listItemTitleHome}`}
            >
              {isNationalTeamParticipating ? (
                <span className={styles.listItemTitleHeading}>
                  {homeTeam.name}
                </span>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(
                    TEAM_PLACEHOLDER,
                    homeTeam.id
                  ).replace(PLACEHOLDER, competitionId)}
                  className={styles.listItemTitleHeading}
                >
                  {homeTeam.name}
                </Link>
              )}
              <div className={styles.listItemTitleLogo}>
                <img
                  src={homeTeam.logoUrl}
                  alt=""
                  className={styles.listItemTitleLogoImg}
                  loading="lazy"
                />
              </div>
            </li>
            {homeLineup.startingLineup.map((n) => (
              <li
                key={n.id}
                className={`${styles.listItem} ${styles.listItemHome}`}
              >
                <div
                  className={`${styles.listItemProfilePhoto} ${styles.listItemProfilePhotoPlayer}`}
                >
                  <img
                    src={n.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                    alt=""
                    className={styles.listItemProfilePhotoImg}
                    loading="lazy"
                  />
                </div>
                <div className={styles.listItemName}>
                  {isNationalTeamParticipating ? (
                    <span className={styles.listItemName}>
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.name}
                    </span>
                  ) : (
                    <Link
                      to={PLAYER_URL_TEMPLATE.replace(PLAYER_PLACEHOLDER, n.id)}
                      className={styles.listItemName}
                    >
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.name}
                    </Link>
                  )}
                  {n.events && !!n.events.length && (
                    <ul className={styles.listItemEventList}>
                      {n.events.map((m) => (
                        <li
                          key={m.id}
                          className={`${styles.listItemEventListItem}`}
                          title={m.type}
                          data-type={m.type}
                        >
                          {`${m.time === "'" ? "" : m.time}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <span
                  className={`${styles.listItemNumber} ${styles.listItemNumberHome}`}
                >
                  {n.number}
                </span>
              </li>
            ))}
            <li
              className={`${styles.listItem} ${styles.listItemHome} ${styles.listItemSeparator}`}
            >
              <h3>{LABELS.SUBSTITUTIONS}</h3>
            </li>
            {homeLineup.substitutions.map((n) => (
              <li
                key={n.id}
                className={`${styles.listItem} ${styles.listItemHome}`}
              >
                <div
                  className={`${styles.listItemProfilePhoto} ${styles.listItemProfilePhotoPlayer}`}
                >
                  <img
                    src={n.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                    alt=""
                    className={styles.listItemProfilePhotoImg}
                    loading="lazy"
                  />
                </div>
                <div className={styles.listItemName}>
                  {isNationalTeamParticipating ? (
                    <span className={styles.listItemName}>
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.name}
                    </span>
                  ) : (
                    <Link
                      to={PLAYER_URL_TEMPLATE.replace(PLAYER_PLACEHOLDER, n.id)}
                      className={styles.listItemName}
                    >
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.name}
                    </Link>
                  )}
                  {n.events && !!n.events.length && (
                    <ul className={styles.listItemEventList}>
                      {n.events.map((m) => (
                        <li
                          key={m.id}
                          className={`${styles.listItemEventListItem}`}
                          title={m.type}
                          data-type={m.type}
                        >
                          {`${m.time === "'" ? "" : m.time}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <span
                  className={`${styles.listItemNumber} ${styles.listItemNumberHome}`}
                >
                  {n.number}
                </span>
              </li>
            ))}
          </ul>
          <div className={styles.separator} />
          <ul className={`${styles.list} ${styles.listAway}`}>
            <li
              className={`${styles.listItem} ${styles.listItemTitle} ${styles.listItemTitleAway}`}
            >
              <div className={styles.listItemTitleLogo}>
                <img
                  src={awayTeam.logoUrl}
                  alt=""
                  className={styles.listItemTitleLogoImg}
                  loading="lazy"
                />
              </div>
              {isNationalTeamParticipating ? (
                <span className={styles.listItemTitleHeading}>
                  {awayTeam.name}
                </span>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(
                    TEAM_PLACEHOLDER,
                    awayTeam.id
                  ).replace(PLACEHOLDER, competitionId)}
                  className={styles.listItemTitleHeading}
                >
                  {awayTeam.name}
                </Link>
              )}
            </li>
            {awayLineup.startingLineup.map((n) => (
              <li
                key={n.id}
                className={`${styles.listItem} ${styles.listItemAway}`}
              >
                <span
                  className={`${styles.listItemNumber} ${styles.listItemNumberAway}`}
                >
                  {n.number}
                </span>
                <div className={styles.listItemName}>
                  {isNationalTeamParticipating ? (
                    <span className={styles.listItemName}>
                      {n.name}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                    </span>
                  ) : (
                    <Link
                      to={PLAYER_URL_TEMPLATE.replace(PLAYER_PLACEHOLDER, n.id)}
                      className={styles.listItemName}
                    >
                      {n.name}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                    </Link>
                  )}
                  {n.events && !!n.events.length && (
                    <ul className={styles.listItemEventList}>
                      {n.events.map((m) => (
                        <li
                          key={m.id}
                          className={`${styles.listItemEventListItem}`}
                          title={m.type}
                          data-type={m.type}
                        >
                          {`${m.time === "'" ? "" : m.time}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div
                  className={`${styles.listItemProfilePhoto} ${styles.listItemProfilePhotoPlayer}`}
                >
                  <img
                    src={n.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                    alt=""
                    className={styles.listItemProfilePhotoImg}
                    loading="lazy"
                  />
                </div>
              </li>
            ))}
            <li
              className={`${styles.listItem} ${styles.listItemAway} ${styles.listItemSeparator}`}
            >
              <h3>{LABELS.SUBSTITUTIONS}</h3>
            </li>
            {awayLineup.substitutions.map((n) => (
              <li
                key={n.id}
                className={`${styles.listItem} ${styles.listItemAway}`}
              >
                <span
                  className={`${styles.listItemNumber} ${styles.listItemNumberAway}`}
                >
                  {n.number}
                </span>
                <div className={styles.listItemName}>
                  {isNationalTeamParticipating ? (
                    <span className={styles.listItemName}>
                      {n.name}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                    </span>
                  ) : (
                    <Link
                      to={PLAYER_URL_TEMPLATE.replace(PLAYER_PLACEHOLDER, n.id)}
                      className={styles.listItemName}
                    >
                      {n.name}
                      {n.isCaptain ? (
                        <span className={styles.position}>(C)</span>
                      ) : null}
                      {n.isGoalkeeper ? (
                        <span className={styles.position}>(GK)</span>
                      ) : null}
                    </Link>
                  )}
                  {n.events && !!n.events.length && (
                    <ul className={styles.listItemEventList}>
                      {n.events.map((m) => (
                        <li
                          key={m.id}
                          className={`${styles.listItemEventListItem}`}
                          title={m.type}
                          data-type={m.type}
                        >
                          {`${m.time === "'" ? "" : m.time}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div
                  className={`${styles.listItemProfilePhoto} ${styles.listItemProfilePhotoPlayer}`}
                >
                  <img
                    src={n.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                    alt=""
                    className={styles.listItemProfilePhotoImg}
                    loading="lazy"
                  />
                </div>
              </li>
            ))}
          </ul>
        </section>
        <div className={styles.footer}>
          {Boolean(homeLineup.coach || awayLineup.coach) && (
            <>
              <div className={styles.refereesHeading}>
                <CompetitionThemedHeading>
                  <h2 className={styles.footerTitle}>{LABELS.COACHES}</h2>
                </CompetitionThemedHeading>
              </div>
              <div>
                <ul className={`${styles.list}`}>
                  <li
                    className={`${styles.listItem} ${styles.listItemCoaches}`}
                  >
                    <div className={styles.listItemTitleLogo}>
                      <img
                        src={homeTeam.logoUrl}
                        alt=""
                        className={styles.listItemTitleLogoImg}
                        loading="lazy"
                      />
                    </div>
                    <span
                      className={`${styles.listItemName} ${styles.listItemNameCoaches}`}
                    >
                      {homeLineup.coach || "-"}
                    </span>
                  </li>
                  <li
                    className={`${styles.listItem} ${styles.listItemCoaches}`}
                  >
                    <div className={styles.listItemTitleLogo}>
                      <img
                        src={awayTeam.logoUrl}
                        alt=""
                        className={styles.listItemTitleLogoImg}
                        loading="lazy"
                      />
                    </div>
                    <span
                      className={`${styles.listItemName} ${styles.listItemNameCoaches}`}
                    >
                      {awayLineup.coach || "-"}
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
          {Boolean(referees && referees.length) && (
            <>
              <div className={styles.refereesHeading}>
                <CompetitionThemedHeading>
                  <h2 className={styles.footerTitle}>{LABELS.REFEREES}</h2>
                </CompetitionThemedHeading>
              </div>
              <div>
                <ul className={`${styles.list}`}>
                  {referees.map((n) => (
                    <li
                      key={n.id}
                      className={`${styles.listItem} ${styles.listItemReferees}`}
                    >
                      <div className={`${styles.listItemProfilePhoto}`}>
                        <img
                          src={n.image || DEFAULT_PROFILE_PHOTO}
                          alt=""
                          className={styles.listItemProfilePhotoImg}
                          loading="lazy"
                        />
                      </div>
                      <div
                        className={`${styles.listItemName} ${styles.listItemNameReferees}`}
                      >
                        <span className={styles.listItemName}>{n.name}</span>
                        <span className={styles.listItemRole}>{n.role}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
          <p className={styles.disclaimer}>{LABELS.DISCLAIMER}</p>
        </div>
      </>
    );
  }

  if (!isEnded) {
    return (
      <>
        <section className={styles.unavailable}>
          <h3 className={styles.unavailableTitle}>{LABELS.LINEUP_COUNTDOWN}</h3>
          <div className={styles.unavailableTimer}>
            {formattedLineupAvailableDate}
          </div>
          <ul className={styles.unavailableList}>
            <li
              className={`${styles.unavailableListItem} ${styles.unavailableListItemHome}`}
            >
              {isNationalTeamParticipating ? (
                <h3 className={styles.unavailableListItemHeading}>
                  {homeTeam.name}
                </h3>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(
                    TEAM_PLACEHOLDER,
                    homeTeam.id
                  ).replace(PLACEHOLDER, competitionId)}
                >
                  <h3 className={styles.unavailableListItemHeading}>
                    {homeTeam.name}
                  </h3>
                </Link>
              )}
              <div className={styles.unavailableListItemLogo}>
                <img
                  src={homeTeam.logoUrl}
                  alt=""
                  className={styles.unavailableListItemLogoImg}
                  loading="lazy"
                />
              </div>
            </li>
            <li
              className={`${styles.unavailableListItem} ${styles.unavailableListItemAway}`}
            >
              <div className={styles.unavailableListItemLogo}>
                <img
                  src={awayTeam.logoUrl}
                  alt=""
                  className={styles.unavailableListItemLogoImg}
                  loading="lazy"
                />
              </div>
              {isNationalTeamParticipating ? (
                <h3 className={styles.unavailableListItemHeading}>
                  {awayTeam.name}
                </h3>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(
                    TEAM_PLACEHOLDER,
                    awayTeam.id
                  ).replace(PLACEHOLDER, competitionId)}
                >
                  <h3 className={styles.unavailableListItemHeading}>
                    {awayTeam.name}
                  </h3>
                </Link>
              )}
            </li>
          </ul>
        </section>
        <LeaderboardOrSquareAdvertisement
          uid="match-lineup-page-1"
          competitionId={competitionId}
        />
      </>
    );
  }

  return (
    <>
      <section className={styles.unavailable}>
        <span className={styles.unavailableTitle}>
          {LABELS.LINEUP_UNAVAILABLE}
        </span>
      </section>
      <LeaderboardOrSquareAdvertisement
        uid="match-lineup-page-2"
        competitionId={competitionId}
      />
    </>
  );
};
