import Api from "../../../../service";
import { RESET_MATCHPOTM, SET_ERROR, SET_MATCHPOTM } from "./constants";

const setMatchPotm = (data) => ({
  type: SET_MATCHPOTM,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getMatchPotm = (data) => (dispatch) =>
  Api.match
    .getMatchPotm(data)
    .then((response) => {
      dispatch(setMatchPotm(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetMatchPotm = (data) => ({
  type: RESET_MATCHPOTM,
  data,
});
