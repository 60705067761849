import Api from "../../../../service";
import { SET_ERROR, SET_MATCHEVENTS, getKey } from "./constants";

const setMatchEvents = (data, key) => ({
  type: SET_MATCHEVENTS,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

// TODO: use RxJS epics to combine requests
export const getMatchEvents = (data) => (dispatch) =>
  Api.result
    .get(data)
    .then((response) => {
      dispatch(setMatchEvents(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });
