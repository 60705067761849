import Api from "../../../../service";
import {
  APPEND_HOMENEWS,
  RESET_HOMENEWS,
  SET_ERROR,
  SET_HOMENEWS,
  getKey,
} from "./constants";

const setHomeNews = (data, key) => ({
  type: SET_HOMENEWS,
  data,
  key,
});

const appendHomeNews = (data, key) => ({
  type: APPEND_HOMENEWS,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getHomeNews = (data) => (dispatch) =>
  Api.home
    .getHomeNews(data)
    .then((response) => {
      dispatch(
        data.append
          ? appendHomeNews(response, getKey(data))
          : setHomeNews(response, getKey(data))
      );
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });

export const resetHomeNews = () => ({
  type: RESET_HOMENEWS,
});
