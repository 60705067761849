import * as React from "react";

import styles from "./critical.scss";

const Component = React.memo(
  ({ isReady, competitions, competitionId, className }) => {
    if (!isReady) {
      return null;
    }

    const competition = competitions.find(
      (n) => `${n.id}` === `${competitionId}`
    );

    return competition ? (
      <div className={`${styles.competitionLogo} ${className || ""}`}>
        <img
          src={competition.logoUrl}
          alt=""
          className={styles.competitionLogoImg}
        />
      </div>
    ) : null;
  }
);

export default Component;
