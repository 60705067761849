import { format, formatDistanceStrict, isToday, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_HOMENEWS,
  SET_ERROR,
  SET_HOMENEWS,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  news: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMENEWS:
      return {
        ...state,
        isReady: true,
        isError: false,
        news: [...(state.news[action.key] || []), ...action.data],
      };
    case RESET_HOMENEWS:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return formatDistanceStrict(dateObj, new Date(), { addSuffix: true });
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "dd-MM-yyyy");
};

export const selectIsReady = createSelector(getState, ({ isReady }) => isReady);

export const selectIsError = createSelector(getState, ({ isError }) => isError);

export const selectNews = createSelector(getState, ({ news }) => {
  if (news) {
    return news.map((m) => ({
      ...m,
      date: toFormattedDate(m.date),
    }));
  }
  return [];
});

reducerRegistry.register(REDUCER_NAME, reducer);
