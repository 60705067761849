import Cookies from "js-cookie";
import React, { useState } from "react";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import styles from "./style.scss";

const COOKIE_NAME = "MATCH_POLL";

const LABELS = {
  POLL: "Vote for your Player of the Match",
  CLOSED: "Poll has ended. Thank you for voting.",
  SUBMITTED: "Thank you for voting.",
  ERROR: "We have encountered an error, please try again later.",
};

export const MatchPoll = ({
  match,
  onGetMatchPoll,
  onSubmitMatchPoll,
  isReady,
  sortedOptions,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchPoll({ id: match.id });
    }
  }, [onGetMatchPoll, match.id, isReady]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  let hasCookie = false;

  if (!isReady) {
    return null;
  }

  const cookie = Cookies.get(COOKIE_NAME);
  if (cookie && cookie.split(",").includes(match.id.toString())) {
    hasCookie = true;
  }

  if (!(match.isRecent || sortedOptions.length)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2>{LABELS.POLL}</h2>
      </CompetitionThemedHeading>
      {!match.isRecent && (
        <span className={styles.caption}>{LABELS.CLOSED}</span>
      )}
      {isSubmitted && (
        <span className={styles.caption}>{LABELS.SUBMITTED}</span>
      )}
      {isError && (
        <span className={`${styles.caption} ${styles.captionError}`}>
          {LABELS.ERROR}
        </span>
      )}
      <ul className={styles.options}>
        {sortedOptions.map((n) => (
          <li key={n.id} className={styles.optionsItem}>
            {match.isRecent && !(isSubmitted || hasCookie) ? (
              <button
                type="button"
                className={styles.optionsItemButton}
                onClick={() =>
                  onSubmitMatchPoll({ id: match.id, option: n.id })
                    .then(() => {
                      onGetMatchPoll({ id: match.id }).then(() => {
                        setIsSubmitted(true);
                      });
                      Cookies.set(
                        COOKIE_NAME,
                        `${cookie ? `${cookie},` : ""}${match.id}`,
                        {
                          expires: 7,
                        }
                      );
                    })
                    .catch(() => {
                      setIsError(true);
                    })
                }
              >
                <div>{n.title}</div>
              </button>
            ) : (
              <>
                <div>{n.title}</div>
                <div className={styles.optionsItemChart}>
                  <div
                    className={`${styles.optionsItemChart} ${styles.optionsItemChartValue}`}
                    style={{ width: `${n.value}%` }}
                    title={`${n.value}%`}
                  >
                    {n.value}
                  </div>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};
