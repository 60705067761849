import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitions } from "../competitions/actions";
import {
  selectActiveCompetitions,
  selectIsError,
  selectIsReady,
} from "../competitions/reducer";
import { UpcomingMatchesSection as Component } from "./upcomingMatchesSection";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  competitions: selectActiveCompetitions,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitions: (data) => dispatch(getCompetitions(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
