import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getSocialLinks } from "./actions";
import { selectIsError, selectIsReady, selectSocialLinks } from "./reducer";
import Component from "./socialLinks";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  socialLinks: selectSocialLinks,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSocialLinks: (data) => dispatch(getSocialLinks(data)),
});

export function fetchData(store) {
  return store.dispatch(getSocialLinks());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
