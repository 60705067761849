import * as React from "react";

import TeamMatches from "../../containers/teamMatches";
import { TYPE } from "../../containers/teamMatches/constants";
import TeamNews from "../../containers/teamNews";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE_LIVE: "Live Events",
  TITLE_UPCOMING: "Upcoming Events",
  TITLE_PAST: "Past Results",
  TITLE_NEWS: "Featured News",
};

const TeamEventsPage = ({ teamId, competitionId }) => (
  <section className={styles.section}>
    <TeamMatches
      title={LABELS.TITLE_LIVE}
      teamId={teamId}
      competitionId={competitionId}
      type={TYPE.LIVE}
    />
    <TeamMatches
      title={LABELS.TITLE_UPCOMING}
      teamId={teamId}
      competitionId={competitionId}
      type={TYPE.UPCOMING}
    />
    <TeamNews
      title={LABELS.TITLE_NEWS}
      isFeatured
      teamId={teamId}
      className={styles.news}
    />
    <TeamMatches
      title={LABELS.TITLE_PAST}
      teamId={teamId}
      competitionId={competitionId}
      type={TYPE.PAST}
    />
    <div className={styles.news}>
      <LeaderboardOrSquareAdvertisement
        uid="team-events-page-1"
        competitionId={competitionId}
      />
    </div>
  </section>
);

export default TeamEventsPage;
