import { format } from "date-fns";
import fetch from "node-fetch";

export default class {
  async getAll() {
    const res = await fetch(
      `${process.env.CMS_URL}/competitions/getCompetitionItemStub`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getCompetitionDetails({ id }) {
    const res = await fetch(`${process.env.CMS_URL}/competitions/${id}`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getMatches({ competitionId, teamId, type, season, pageSize, page }) {
    return fetch(
      `${process.env.API_URL}/competitions/${competitionId}/matches?${
        type ? `status=${type}&` : ""
      }${teamId ? `teamId=${teamId}&` : ""}${
        season ? `season=${season}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );
  }

  getPastMatches({ competitionId, date, pageSize, page, teamId, season }) {
    return fetch(
      `${process.env.API_URL}/competitions/${competitionId}/pastMatches?${
        date ? `date=${format(date, "yyyy-MM-dd")}&` : ""
      }${teamId ? `teamId=${teamId}&` : ""}${
        season ? `season=${season}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );
  }

  getUpcomingMatches({ competitionId, date, teamId, season, pageSize, page }) {
    return fetch(
      `${process.env.API_URL}/competitions/${competitionId}/upcomingMatches?${
        date ? `date=${format(date, "yyyy-MM-dd")}&` : ""
      }${teamId ? `teamId=${teamId}&` : ""}${
        season ? `season=${season}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );
  }

  async getClubs({ id }) {
    const res = await fetch(`${process.env.API_URL}/competitions/${id}/teams`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getTable({ id, season }) {
    const res = await fetch(
      `${process.env.API_URL}/competitions/${id}/standings${
        season ? `?season=${season}` : ""
      }`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getCup({ id, season }) {
    const res = await fetch(
      `${process.env.API_URL}/competitions/standings?${
        id ? `competitionTypeId=${id}&` : ""
      }${season ? `season=${season}&` : ""}`
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getTopScorers({ id, season, pageSize }) {
    const res = await fetch(
      `${process.env.API_URL}/competitions/${id}/topScorers?${
        season ? `season=${season}&` : ""
      }${pageSize ? `pageSize=${pageSize}` : ""}`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getSponsors({ id }) {
    const res = await fetch(`${process.env.CMS_URL}/sponsors/${id}`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getSeasons({ id, teamId }) {
    const res = await fetch(
      `${process.env.API_URL}/competitions/${id}/seasons?${
        teamId ? `teamId=${teamId}` : ""
      }`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
