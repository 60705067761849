import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getMatchPotm, resetMatchPotm } from "./actions";
import { MatchPotm as Component } from "./matchPotm";
import { selectIsError, selectIsReady, selectMatchPotm } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  potm: selectMatchPotm,
});

const mapDispatchToProps = (dispatch) => ({
  onGetMatchPotm: (data) => dispatch(getMatchPotm(data)),
  onResetMatchPotm: (data) => dispatch(resetMatchPotm(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
