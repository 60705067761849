import fetch from "node-fetch";

export default class {
  async getPlayerDetails({ id }) {
    const res = await fetch(
      `${process.env.API_URL}/players/${id}/GetPlayerDetails`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getPlayerStatistics({ id, season }) {
    const res = await fetch(
      `${process.env.API_URL}/players/${id}/GetPlayerStatistics?${
        season ? `season=${season}&` : "season=-1&"
      }`
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getSeasons({ id }) {
    const res = await fetch(
      `${process.env.API_URL}/players/${id}/GetPlayerSeasons`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getPlayerHistory({ id }) {
    const res = await fetch(
      `${process.env.API_URL}/players/${id}/GetPlayerHistory`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
