import Api from "../../../../service";
import { RESET_TEAMSPONSORS, SET_ERROR, SET_TEAMSPONSORS } from "./constants";

const setTeamSponsors = (data) => ({
  type: SET_TEAMSPONSORS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getTeamSponsors = (data) => (dispatch) =>
  Api.team
    .getTeamSponsors(data)
    .then((response) => {
      dispatch(setTeamSponsors(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetTeamSponsors = (data) => ({
  type: RESET_TEAMSPONSORS,
  data,
});
