import * as React from "react";
import { Link } from "react-router-dom";

import { NewsArticle } from "../newsArticle/newsArticle";
import styles from "./style.scss";

const HomeNewsCategory = React.memo(
  ({ news, title, noResults, isReady, isError }) => {
    if (isError) {
      return null;
    }

    if (!isReady) {
      return (
        <div>
          <h2 className={styles.newsTitle}>{title}</h2>
          {[...Array(1)].map((n, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.newsListItem} ${styles.skeleton}`}
            />
          ))}
        </div>
      );
    }

    if (news && news.length) {
      return (
        <div>
          <h2 className={styles.newsTitle}>{title}</h2>
          <div className={styles.newsListItem}>
            <Link
              to={news[0].url}
              title={news[0].title}
              className={styles.newsListItemLink}
            >
              <NewsArticle data={{ ...news[0], category: { title } }} />
            </Link>
          </div>

          {news.length > 1 && (
            <ul className={styles.newsList}>
              {news.splice(1).map((n) => (
                <li
                  key={n.id}
                  className={`${styles.newsListItem} ${styles.condensed}`}
                >
                  <Link
                    to={n.url}
                    title={n.title}
                    className={styles.newsListItemLink}
                  >
                    <NewsArticle
                      data={{ ...n, category: { title } }}
                      displayAsRow
                    />
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }

    if (noResults) {
      return (
        <>
          <h2 className={styles.newsTitle}>{title}</h2>
          <p>{noResults}</p>
        </>
      );
    }

    return null;
  }
);

export default HomeNewsCategory;
