import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  SET_ERROR,
  SET_NATIONALTEAMAPPEARANCES,
  getKey,
} from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  appearances: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NATIONALTEAMAPPEARANCES:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        appearances: {
          ...state.appearances,
          [action.key]: action.data,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "dd-mm-yyyy");
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectNationalTeamAppearances = (state, props) => {
  return getState(state).appearances[getKey(props)];
};

export const makeSelectNationalTeamAppearances = () =>
  createSelector(
    selectNationalTeamAppearances,
    (n) =>
      n &&
      n.map((m) => ({
        id: m.id,
        profilePhotoUrl: m.profilePhotoUrl,
        name: m.name,
        dob: m.dob,
        debutDate: toFormattedDate(m.debutDate),
        debut: m.debut,
        mp: m.mp,
      }))
  );

reducerRegistry.register(REDUCER_NAME, reducer);
