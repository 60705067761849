import Api from "../../../../service";
import { RESET_TEAMNEWS, SET_ERROR, SET_TEAMNEWS, getKey } from "./constants";

const setTeamNews = (data, key) => ({
  type: SET_TEAMNEWS,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getTeamNews = (data) => (dispatch) =>
  Api.team
    .getTeamNews(data)
    .then((response) => {
      dispatch(setTeamNews(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });

export const resetTeamNews = () => ({
  type: RESET_TEAMNEWS,
});
