import * as React from "react";
import Countdown from "react-countdown";

import ResponsiveImage from "../shared/responsiveImage";
import styles from "./critical.scss";

const LABELS = {
  LIVE: "live",
};

const HomeBanner = ({
  images,
  startDate,
  formattedStartDate,
  hasMatch,
  isLive,
  isEnded,
  isRecent,
  matchTime,
  venue,
  className,
  children,
}) => {
  if (!images) {
    return null;
  }

  return (
    <section className={`${styles.section} ${className ?? ""}`}>
      <div className={styles.container}>
        <div className={styles.image}>
          <ResponsiveImage
            src={images.src}
            srcset={images.srcset}
            alt={images.alt}
            className={styles.imageImg}
            lazyload
            important="true"
          />
        </div>
        {hasMatch && (
          <div className={styles.text}>
            {isLive || isRecent ? (
              <>
                <div className={styles.textLive}>
                  <span className={styles.textLiveLabel}>{LABELS.LIVE}</span>
                </div>
                {/* TODO: matchTime should come from the results API */}
                <span className={styles.textStartDate}>
                  {matchTime && matchTime.startsWith("-") ? "-" : matchTime}
                </span>
                <span className={styles.venue}>{venue}</span>
              </>
            ) : (
              <>
                <span className={styles.textStartDate}>
                  {formattedStartDate}
                </span>
                <span className={styles.venue}>{venue}</span>
                {!(isLive && isEnded) && (
                  <div className={styles.countdown}>
                    <Countdown
                      date={startDate}
                      zeroPadDays={0}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <ul className={styles.time}>
                          <li className={styles.timeItem}>
                            <span className={styles.timeValue}>{days}</span>
                            <span className={styles.timeTitle}>Days</span>
                          </li>
                          <li className={styles.timeItem}>
                            <span className={styles.timeValue}>
                              {hours.toString().padStart(2, "0")}
                            </span>
                            <span className={styles.timeTitle}>Hours</span>
                          </li>
                          <li className={styles.timeItem}>
                            <span className={styles.timeValue}>
                              {minutes.toString().padStart(2, "0")}
                            </span>
                            <span className={styles.timeTitle}>Minutes</span>
                          </li>
                          <li className={styles.timeItem}>
                            <span className={styles.timeValue}>
                              {seconds.toString().padStart(2, "0")}
                            </span>
                            <span className={styles.timeTitle}>Seconds</span>
                          </li>
                        </ul>
                      )}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {children}
      </div>
    </section>
  );
};

export default HomeBanner;
