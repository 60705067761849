import * as React from "react";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";
import styles from "./style.scss";

const LABELS = {
  POTM: "Player of the Match",
};

export const MatchPotm = ({
  match,
  onGetMatchPotm,
  onResetMatchPotm,
  isReady,
  potm,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchPotm({ id: match.id });
    }
  }, [onGetMatchPotm, match.id, isReady]);

  React.useEffect(
    () => () => onResetMatchPotm(match.id),
    [onResetMatchPotm, match.id]
  );

  if (!(isReady && potm)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{LABELS.POTM}</h2>
      </CompetitionThemedHeading>
      <div className={styles.player}>
        <div className={styles.playerThumbnail}>
          <img
            className={styles.playerThumbnailImg}
            src={potm.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
            alt=""
            title={potm.name}
          />
        </div>
        <div className={styles.playerName}>{potm.name}</div>
      </div>
    </section>
  );
};
