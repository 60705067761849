import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./style.scss";

const getPaginationUrl = (location, variableName) => {
  if (typeof URLSearchParams !== "undefined") {
    const searchParams = new URLSearchParams(location.search);
    let page = searchParams.get(variableName);
    if (page && !Number.isNaN(page)) {
      page = Number(page) + 1;
    } else {
      page = 2;
    }
    searchParams.set(variableName, page);
    return `${location.pathname}?${searchParams.toString()}`;
  }
  return null;
};

export const NewsPagination = ({
  label,
  className,
  linkClassName,
  variableName,
}) => {
  const location = useLocation();
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <div className={className ?? ""}>
      <Link
        to={getPaginationUrl(location, variableName)}
        className={`${linkClassName} ${styles.disabled}`}
      >
        {label}
      </Link>
    </div>
  );
};
