import * as React from "react";

import CompetitionNews from "../../containers/competitionNews";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

const LABELS = {
  TITLE_FEATURED: "Featured News",
  TITLE: "Latest News",
};

export const CompetitionNewsPage = ({ competitionId }) => (
  <section className={styles.section}>
    <CompetitionNews
      title={LABELS.TITLE_FEATURED}
      isFeatured
      competitionId={competitionId}
    />
    <CompetitionNews
      title={LABELS.TITLE}
      competitionId={competitionId}
      showPagination
      paginationQueryStringParamName="page"
    />
  </section>
);
