import * as React from "react";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";
import styles from "./style.scss";

const LABELS = {
  TITLE: "Coaches",
  PRESENT: "present",
};

const Component = ({
  nationalTeamId,
  onGetNationalTeamCoaches,
  onResetNationalTeamCoaches,
  isReady,
  isError,
  players,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetNationalTeamCoaches({ nationalTeamId });
    }
  }, [nationalTeamId, onGetNationalTeamCoaches, isReady]);

  React.useEffect(
    () => () => onResetNationalTeamCoaches(nationalTeamId),
    [onResetNationalTeamCoaches, nationalTeamId]
  );

  if (isError) {
    return (
      <section className={styles.section}>
        <CompetitionThemedHeading>
          <h2>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section className={styles.section}>
        <CompetitionThemedHeading>
          <h2>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={styles.list}>
          {[...Array(1)].map(() =>
            [...Array(4)].map((m, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`${styles.listItem} ${styles.skeleton}`}
              />
            ))
          )}
        </ul>
      </section>
    );
  }

  if (!(players && players.length)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2>{LABELS.TITLE}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.list}>
          {players.map((n) => (
            <li className={styles.listItem} key={n.id}>
              <div className={styles.listItemContent}>
                <div className={styles.player}>
                  <div className={styles.container}>
                    <div className={styles.thumbnail}>
                      <img
                        src={n.profilePhoto.src || DEFAULT_PROFILE_PHOTO}
                        alt={n.profilePhoto.alt}
                        className={styles.img}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={styles.details}>
                    <span className={styles.name}>{n.name}</span>
                    <span className={styles.position}>
                      {n.formattedFromDate} -{" "}
                      {n.formattedToDate || LABELS.PRESENT}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default Component;
