import * as React from "react";

const CompetitionContext = React.createContext();

export const defaultCompetition = {
  id: "",
  name: "",
  themeColor1: "#d23a44",
  themeColor2: "#a4050f",
  isCup: false,
  banner: {
    src: "/default-banner.jpg",
    srcset: "/default-banner.jpg 540w, /default-banner-2x.jpg 1080w",
    alt: "",
  },
};

export const CompetitionContextProvider = CompetitionContext.Provider;

export const useCompetitionContext = () => React.useContext(CompetitionContext);
