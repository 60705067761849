import * as React from "react";

import HomeSponsors from "../../containers/homeSponsors";
import Navigation from "../../containers/navigation";
import FooterNavigation from "../footerNavigation";
import QuickLinks from "../quickLinks";
import styles from "./style.scss";

const LABELS = {
  SPONSORS_TITLE: "Commercial Partners",
  COPYRIGHT_TEXT: `Copyright © ${new Date().getFullYear()} MFA.`,
  RIGHTS_RESERVED_TEXT: "All rights reserved.",
};

const QUICKLINKS_NAVIGATION_TYPE = "mc-quickLinks";
const FOOTER_NAVIGATION_TYPE = "mc-footer";

const Footer = () => (
  <>
    <div className={styles.sponsorsTierOne}>
      <HomeSponsors tier={1} />
    </div>
    <section className={styles.section}>
      <div className={styles.content}>
        <div>
          <h3 className={styles.sponsorsTitle}>{LABELS.SPONSORS_TITLE}</h3>
          <HomeSponsors tier={2} />
        </div>
        <Navigation type={QUICKLINKS_NAVIGATION_TYPE}>
          {({ navigation }) => <QuickLinks navigation={navigation} />}
        </Navigation>
        <Navigation type={FOOTER_NAVIGATION_TYPE}>
          {({ navigation }) => <FooterNavigation navigation={navigation} />}
        </Navigation>
        <div className={styles.footerText}>
          <span className={styles.copyright}>{LABELS.COPYRIGHT_TEXT}</span>
          <span>{LABELS.RIGHTS_RESERVED_TEXT}</span>
        </div>
      </div>
    </section>
  </>
);

export default Footer;
