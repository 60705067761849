import * as React from "react";

import { useCompetitionContext } from "../competition/context";
import styles from "./critical.scss";

const CompetitionThemedBackground = ({ children }) => {
  const {
    state: { themeColor1, themeColor2 },
  } = useCompetitionContext();

  const style = React.useMemo(
    () => ({
      backgroundColor: themeColor1,
      backgroundImage: `linear-gradient(to bottom right, ${themeColor1}, ${themeColor2}`,
    }),
    [themeColor1, themeColor2]
  );

  return (
    <div style={style} className={styles.background}>
      {children}
    </div>
  );
};

export default CompetitionThemedBackground;
