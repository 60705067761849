import fetch from "node-fetch";

export default class {
  async getHighlights({ pageToken } = {}) {
    const res = await fetch(
      `/api/home/highlights?${pageToken ? `pageToken=${pageToken}` : ""}`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
