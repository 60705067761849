import Api from "../../../../service";
import { SET_ERROR, SET_SOCIALLINKS } from "./constants";

const setSocialLinks = (data) => ({
  type: SET_SOCIALLINKS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getSocialLinks = (data) => (dispatch) =>
  Api.social
    .getSocialLinks(data)
    .then((response) => {
      dispatch(setSocialLinks(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
