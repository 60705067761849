import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_MATCHLINEUP,
  SET_ERROR,
  SET_MATCHLINEUP,
  getKey,
} from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  homeLineup: {},
  awayLineup: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHLINEUP:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        homeLineup: {
          ...state.homeLineup,
          [action.key]: action.data.homeLineup,
        },
        awayLineup: {
          ...state.awayLineup,
          [action.key]: action.data.awayLineup,
        },
      };
    case RESET_MATCHLINEUP:
      return {
        ...initialState,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectHomeLineup = (state, props) => {
  return getState(state).homeLineup[getKey(props)];
};

export const makeSelectHomeLineup = () =>
  createSelector(selectHomeLineup, (n) =>
    n
      ? {
          ...n,
          startingLineup: n.startingLineup.sort((a, b) => {
            if (a.isGoalkeeper) {
              if (b.isGoalkeeper) {
                return a.number - b.number;
              }
              return -1;
            }
            if (b.isGoalkeeper) {
              return 1;
            }

            return a.number - b.number;
          }),
          substitutions: n.substitutions.sort((a, b) => {
            if (a.isGoalkeeper) {
              if (b.isGoalkeeper) {
                return a.number - b.number;
              }
              return -1;
            }
            if (b.isGoalkeeper) {
              return 1;
            }

            return a.number - b.number;
          }),
        }
      : []
  );

const selectAwayLineup = (state, props) => {
  return getState(state).awayLineup[getKey(props)];
};

export const makeSelectAwayLineup = () =>
  createSelector(selectAwayLineup, (n) =>
    n
      ? {
          ...n,
          startingLineup: n.startingLineup.sort((a, b) => {
            if (a.isGoalkeeper) {
              if (b.isGoalkeeper) {
                return a.number - b.number;
              }
              return -1;
            }
            if (b.isGoalkeeper) {
              return 1;
            }

            return a.number - b.number;
          }),
          substitutions: n.substitutions.sort((a, b) => {
            if (a.isGoalkeeper) {
              if (b.isGoalkeeper) {
                return a.number - b.number;
              }
              return -1;
            }
            if (b.isGoalkeeper) {
              return 1;
            }

            return a.number - b.number;
          }),
        }
      : []
  );

reducerRegistry.register(REDUCER_NAME, reducer);
