const toNavigationItems = (data) =>
  data && data.length
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
        url: n.url,
        isNewWindow: n.isNewWindow,
        isExternal: n.isExternal,
        children: toNavigationItems(n.children),
      }))
    : [];

export const toNavigation = (data) =>
  data && data.children ? toNavigationItems(data.children) : [];
