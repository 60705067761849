import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_MATCHCHANNEL, getKey } from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  channel: {},
  isYouTube: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHCHANNEL:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        channel: {
          ...state.channel,
          [action.key]: action.data.channel,
        },
        isYouTube: {
          ...state.isYouTube,
          [action.key]: action.data.isYouTube,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectChannel = (state, props) => getState(state).channel[getKey(props)];

export const makeSelectChannel = () => createSelector(selectChannel, (n) => n);

const selectIsYouTube = (state, props) =>
  getState(state).isYouTube[getKey(props)];

export const makeSelectIsYouTube = () =>
  createSelector(selectIsYouTube, (n) => n);

reducerRegistry.register(REDUCER_NAME, reducer);
