import * as React from "react";

import styles from "./style.scss";

const PlayerOverview = ({ overview }) => {
  if (!overview) {
    return null;
  }

  return (
    <div className={styles.list}>
      <ul className={styles.listData}>
        {overview.map((n) => (
          <li key={n.id} className={styles.listItem}>
            <span className={styles.itemTitle}>{n.title}</span>
            <span className={styles.itemValue}>{n.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayerOverview;
