import * as React from "react";
import ReactJWPlayer from "react-jw-player";

import Thumbnail from "../thumbnail";
import styles from "./style.scss";

const JWPLAYER_SRC = "https://cdn.jwplayer.com/libraries/D0OAWleI.js";

const LABELS = {
  DATE_PREFIX: "Published",
  READ_MORE: "Read More",
};

export const NewsArticle = ({ data, displayAsRow }) => {
  return (
    <div className={`${styles.article} ${displayAsRow ? styles.row : ""}`}>
      {!displayAsRow && data.video ? (
        <div className={styles.articleVideoContainer}>
          <div
            className={styles.articleVideo}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            role="none"
          >
            <ReactJWPlayer
              playerId={`jwplayer_${data.id}`}
              playerScript={JWPLAYER_SRC}
              playlist={data.video}
              className={styles.articleVideoPlayer}
            />
          </div>
        </div>
      ) : (
        data.thumbnail && (
          <Thumbnail
            image={data.thumbnail}
            className={`${styles.articleThumbnail} ${
              displayAsRow ? styles.articleThumbnailRow : ""
            }`}
          />
        )
      )}
      <div
        className={`${styles.articleContent} ${
          displayAsRow ? styles.articleContentRow : ""
        }`}
      >
        <div>
          {data.category && (
            <span
              className={`${styles.articleContentCategory} ${
                displayAsRow ? styles.articleContentCategoryRow : ""
              }`}
            >
              {data.category.title}
            </span>
          )}
          <h3
            className={`${styles.articleContentTitle} ${
              displayAsRow ? styles.articleContentTitleRow : ""
            }`}
          >
            {data.title}
          </h3>
          {!displayAsRow && (
            <div
              className={styles.articleContentSummary}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: data.summary }}
            />
          )}
        </div>
        <div
          className={`${styles.articleFooter} ${
            displayAsRow ? styles.articleFooterRow : ""
          }`}
        >
          <span>
            <span
              className={styles.datePrefix}
            >{`${LABELS.DATE_PREFIX}:`}</span>{" "}
            {data.date}
          </span>
          {Boolean(data.url) && (
            <span
              to={data.url}
              title={data.title}
              className={styles.articleFooterReadMore}
            >
              {LABELS.READ_MORE}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
