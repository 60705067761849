import * as React from "react";

import styles from "./style.scss";

const ThemedHeading = ({ color, children }) => {
  const style = React.useMemo(
    () => ({
      color: `${color}`,
    }),
    [color]
  );

  return (
    <div style={style} className={styles.heading}>
      {children}
    </div>
  );
};

export default ThemedHeading;
