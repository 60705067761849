import * as React from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import styles from "./critical.scss";

const getUrl = (route, teamId, competitionId, season, tab) => {
  return generatePath(route, { teamId, competitionId, season, tab });
};

const Component = ({
  label,
  team,
  options,
  competitions,
  alwaysShow,
  active,
  competitionId,
}) => {
  const history = useHistory();
  const { season, tab } = useParams();
  const { path } = useRouteMatch();

  if (!(competitions || competitions.length || options || options.length)) {
    return null;
  }

  if (competitions.filter((n) => options.includes(n.id)).length < 2) {
    return null;
  }

  return (
    <section
      className={`${styles.section} ${alwaysShow ? styles.alwaysShow : ""}`}
    >
      <select
        className={styles.list}
        onChange={(e) => {
          history.push(getUrl(path, team, e.target.value, season, tab));
          window.scrollTo(0, 0);
        }}
        defaultValue={competitionId || ""}
      >
        <option className={styles.listItem} value="" disabled>
          {label}
        </option>
        {competitions.map(
          (n) =>
            options.includes(n.id) && (
              <option
                className={`${styles.listItem} ${
                  n.id === active ? styles.listItemActive : ""
                }`}
                key={n.id}
                value={n.id}
              >
                {n.name}
              </option>
            )
        )}
      </select>
    </section>
  );
};

export default Component;
