import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./style.scss";

const URL_TEMPLATE = "/team/:teamId";
const TEAM_PLACEHOLDER = ":teamId";
const COMPETITION_PLACEHOLDER = ":competitionId";

const LABELS = {
  TITLE: "All Clubs",
};

export const CompetitionClubs = ({
  competitionId,
  onGetCompetitionClubs,
  onResetCompetitionClubs,
  isReady,
  isError,
  clubs,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionClubs({ id: competitionId });
    }
  }, [competitionId, onGetCompetitionClubs, isReady, onResetCompetitionClubs]);

  React.useEffect(
    () => () => onResetCompetitionClubs(competitionId),
    [onResetCompetitionClubs, competitionId]
  );

  if (isError) {
    return (
      <section className={styles.section}>
        <CompetitionThemedHeading>
          <h2>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section className={styles.section}>
        <CompetitionThemedHeading>
          <h2>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={styles.skeletonList}>
          {[...Array(3)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(clubs && clubs.length)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2>{LABELS.TITLE}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.competitionList}>
          {clubs.map((n) => (
            <li key={n.id}>
              <CompetitionThemedHeading>
                <h3 className={styles.title}>{n.name}</h3>
              </CompetitionThemedHeading>
              <ul className={styles.list}>
                {n.data.map((m) => (
                  <li className={styles.listItem} key={m.id}>
                    <Link
                      to={URL_TEMPLATE.replace(TEAM_PLACEHOLDER, m.id).replace(
                        COMPETITION_PLACEHOLDER,
                        competitionId
                      )}
                      className={styles.listItemContent}
                    >
                      <div className={styles.team}>
                        <img
                          src={m.logoUrl}
                          alt=""
                          className={styles.teamImg}
                          loading="lazy"
                        />
                        <span className={styles.teamName}>{m.name}</span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};
