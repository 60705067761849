import NodeCache from "node-cache";
import { match } from "path-to-regexp";

import Service from "./service";

// Settings for the route table cache. It needs both a TTL and a check period
// or it will never be cleared
const cache = new NodeCache({
  stdTTL: 0,
  useClones: false,
});

export default class {
  constructor({ service } = {}) {
    this.service = service || new Service();
  }

  // Removes the domain and TLD, returning just the path requested
  // http://www.example.com/foo/bar becomes '/foo/bar'
  extractUrlPath(url) {
    let urlSections = url.split("/");
    urlSections = urlSections.filter((sectionString) => {
      return sectionString.length > 0;
    });

    let urlPath = null;
    if (urlSections.length === 0) {
      urlPath = "/";
    } else {
      urlPath = `/${urlSections.join("/")}`;
    }
    return urlPath;
  }

  isValidPath(urlPath) {
    return new Promise((resolve, reject) => {
      this.getValidRoutes()
        .then((routes) => {
          if (routes.find((n) => match(n)(urlPath))) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          reject(new Error(`Unable to validate path: ${error}`));
        });
    });
  }

  getRoutingTable() {
    return this.getCachedData("routeTable");
  }

  getValidRoutes() {
    return this.getCachedData("validRoutes");
  }

  getUrl(docType) {
    return this.getRoutingTable().then((routingTable) => {
      return routingTable.routes.find((item) => {
        return item.value === docType;
      }).key;
    });
  }

  getUrls(docTypes) {
    return this.getRoutingTable().then((routingTable) => {
      const results = routingTable.routes.filter((item) => {
        return docTypes.indexOf(item.value) > -1;
      });
      // convert into object
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = results.length - 1; i >= 0; i--) {
        obj[results[i].value] = results[i].key;
      }

      return obj;
    });
  }

  getCachedData(cacheKey) {
    const self = this;
    return new Promise((resolve, reject) => {
      const value = cache.get(cacheKey);
      // if no routing table is cached,
      // then it will retrieve a new routing table
      if (!value) {
        self
          .updateRoutingTable()
          .then((response) => {
            cache.set("routeTable", Object.freeze(response));
            cache.set(
              "validRoutes",
              Object.freeze(this.extractValidRoutes(response))
            );
            resolve(cache.get(cacheKey));
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // Otherwise, we retrieve the cached version
        resolve(value);
      }
    });
  }

  extractValidRoutes(data) {
    const validRoutes = [];

    const entries = Object.entries(data.routes);
    for (let i = 0; i < entries.length; i += 1) {
      const [, value] = entries[i];
      validRoutes.push(this.extractUrlPath(value.key));
    }
    return validRoutes;
  }

  // Returns the promise provided by the service to retrieve the
  // routing table from the API.
  updateRoutingTable() {
    return this.service.get({});
  }
}
