import { connect } from "react-redux";
import { compose } from "redux";

import { getCompetitionTopScorers } from "./actions";
import { CompetitionTopScorers as Component } from "./competitionTopScorers";
import {
  makeSelectCompetitionTopScorers,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectCompetitionTopScorers = makeSelectCompetitionTopScorers();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    table: selectCompetitionTopScorers(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionTopScorers: (data) =>
    dispatch(getCompetitionTopScorers(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
