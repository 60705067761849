import { format, formatDistance, isToday, parseISO, subHours } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_MATCH,
  SET_ERROR,
  SET_MATCH,
  STATUS_CANCELLED,
  STATUS_POSTPONED,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  id: null,
  startDate: null,
  homeTeam: null,
  awayTeam: null,
  venue: null,
  referees: [],
  isLive: null,
  matchTime: null,
  isLineupAvailable: null,
  competitionName: null,
  competitionId: null,
  status: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCH:
      return {
        ...state,
        id: action.data.id,
        startDate: action.data.startDate,
        homeTeam: action.data.homeTeam,
        awayTeam: action.data.awayTeam,
        venue: action.data.venue,
        referees: action.data.referees,
        isLive: action.data.isLive,
        matchTime: action.data.matchTime,
        isLineupAvailable: action.data.isLineupAvailable,
        competitionName: action.data.competitionName,
        competitionId: action.data.competitionId,
        status: action.data.status,
        isReady: true,
        isError: false,
      };
    case RESET_MATCH:
      return {
        ...initialState,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj) && dateObj > new Date()) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "E dd-MM-yyyy HH:mm");
};

const toFormattedLineupAvailableDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  const lineupDate = subHours(dateObj, 1);

  if (isToday(dateObj)) {
    return formatDistance(new Date(), lineupDate);
  }
  return format(lineupDate, "E dd-MM-yyyy HH:mm");
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectMatch = createSelector(getState, (n) => ({
  id: n.id,
  startDate: n.startDate,
  homeTeam: n.homeTeam,
  awayTeam: n.awayTeam,
  venue: n.venue,
  referees: n.referees,
  isLive: n.isLive,
  matchTime: n.matchTime,
  isLineupAvailable: n.isLineupAvailable,
  competitionName: n.competitionName,
  competitionId: n.competitionId,
  isRecent:
    parseISO(n.startDate) < new Date() &&
    parseISO(n.startDate) > subHours(new Date(), 24),
  formattedStartDate: toFormattedDate(n.startDate),
  formattedLineupAvailableDate: toFormattedLineupAvailableDate(n.startDate),
  isEnded:
    !n.isLive &&
    parseISO(n.startDate) < new Date() &&
    n.status !== STATUS_POSTPONED &&
    n.status !== STATUS_CANCELLED,
  isToday: isToday(parseISO(n.startDate)),
  isPostponed: n.status === STATUS_POSTPONED,
  isCancelled: n.status === STATUS_CANCELLED,
}));

reducerRegistry.register(REDUCER_NAME, reducer);
