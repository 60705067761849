import * as React from "react";

import MatchChannelLogo from "../../components/matchChannelLogo";
import styles from "./style.scss";

const CompetitionMatchChannel = ({
  id,
  onGetMatchChannel,
  isReady,
  channel,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchChannel({ id });
    }
  }, [onGetMatchChannel, id, isReady]);

  if (!(isReady && channel)) {
    return <div className={styles.channel} />;
  }

  return <MatchChannelLogo channel={channel} />;
};

export default CompetitionMatchChannel;
