import * as React from "react";

import TeamPlayers from "../../containers/teamPlayers";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const TeamPlayersPage = ({ teamId, competitionId }) => {
  return (
    <section className={styles.section}>
      <TeamPlayers teamId={teamId} competitionId={competitionId} />
      <LeaderboardOrSquareAdvertisement
        uid="team-players-page-1"
        competitionId={competitionId}
      />
    </section>
  );
};

export default TeamPlayersPage;
