import * as React from "react";

import NationalTeamAppearances from "../../containers/nationalTeamAppearances";
import styles from "./critical.scss";

const Component = ({ nationalTeamId }) => (
  <section className={styles.section}>
    <NationalTeamAppearances nationalTeamId={nationalTeamId} />
  </section>
);

export default Component;
