import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_COMPETITIONTABLE,
  SET_COMPETITIONTABLE,
  SET_ERROR,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  standings: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITIONTABLE:
      return {
        ...state,
        isReady: true,
        isError: false,
        standings: action.data,
      };
    case RESET_COMPETITIONTABLE:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (state) => state.isReady);

export const selectIsError = createSelector(getState, (state) => state.isError);

export const selectCompetitionStandings = createSelector(
  getState,
  (state) => state.standings
);

reducerRegistry.register(REDUCER_NAME, reducer);
