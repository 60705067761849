import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_MATCHPOTM,
  SET_ERROR,
  SET_MATCHPOTM,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  potm: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHPOTM:
      return {
        ...state,
        isReady: true,
        isError: false,
        potm: action.data,
      };
    case RESET_MATCHPOTM:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectMatchPotm = createSelector(getState, (n) => n.potm);

reducerRegistry.register(REDUCER_NAME, reducer);
