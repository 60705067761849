import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import {
  selectCompetitions,
  selectIsError,
  selectIsReady,
} from "../competitions/reducer";
import Component from "./competitionLogo";

const mapStateToProps = createStructuredSelector({
  competitions: selectCompetitions,
  isReady: selectIsReady,
  isError: selectIsError,
});

export default compose(connect(mapStateToProps))(Component);
