import Api from "../../../../service";
import { SET_ERROR, SET_MATCHPOLL } from "./constants";

const setMatchPoll = (data) => ({
  type: SET_MATCHPOLL,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getMatchPoll = (data) => (dispatch) =>
  Api.match
    .getMatchPoll(data)
    .then((response) => {
      dispatch(setMatchPoll(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const submitMatchPoll = (data) => () =>
  Api.match
    .submitMatchPoll(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
