import {
  toAppearances,
  toCoaches,
  toCompetitions,
  toMatches,
  toNextPage,
  toTable,
  toTeamDetails,
  toTeamNews,
  toTeams,
  toTopScorers,
} from "./adapter";
import DataService from "./dataService";
import NewsService from "./newsService";
import PageService from "./pageService";

export const TYPE = {
  LIVE: "live",
  UPCOMING: "upcoming",
  PAST: "past",
};

const MATCHES_PAGE_SIZE = 8;
const TOP_SCORERS_PAGE_SIZE = 30;
const APPEARANCES_PAGE_SIZE = 30;
const COACHES_PAGE_SIZE = 100;

export default class {
  constructor({ pageService, dataService, newsService } = {}) {
    this.pageService = pageService || new PageService();
    this.dataService = dataService || new DataService();
    this.newsService = newsService || new NewsService();
  }

  getPage(data) {
    return this.pageService
      .getPage(data)
      .then((page) => {
        const { nationalTeamId } = page.params;
        return this.getNationalTeam({ id: nationalTeamId });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getAll(data) {
    return this.dataService
      .getNationalTeams(data)
      .then((response) => toTeams(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getNationalTeam(data) {
    return this.dataService
      .getNationalTeams(data)
      .then((response) => {
        const regex = new RegExp(`${data.id}/?$`);
        const team = response.find((n) => regex.test(n.nationalTeamId));
        return toTeamDetails(team);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getTeamNews(data) {
    return this.newsService
      .getTeamNews(data)
      .then((response) => toTeamNews(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatches(data) {
    const { page, pageSize } = data;

    if (data.competitionId) {
      return this.dataService
        .getPastMatches({ pageSize: pageSize || MATCHES_PAGE_SIZE, ...data })
        .then((res) => {
          if (res.ok) {
            return res
              .json()
              .then((response) => ({
                nextPage: toNextPage({
                  page: page || 1,
                  pageCount: res.headers.get("PageCount"),
                }),
                matches: toMatches(response),
              }))
              .catch((error) => {
                console.error(error);
                throw error;
              });
          }
          throw new Error(res.statusText);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }

    return this.dataService
      .getMatches({
        pageSize: pageSize || MATCHES_PAGE_SIZE,
        showAllCompetitions: true,
        ...data,
      })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((response) => ({
              nextPage: toNextPage({
                page: page || 1,
                pageCount: res.headers.get("PageCount"),
              }),
              matches: toMatches(response),
            }))
            .catch((error) => {
              console.error(error);
              throw error;
            });
        }
        throw new Error(res.statusText);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getNationalTeamTopScorers(data) {
    return this.dataService
      .getNationalTeamTopScorers({ pageSize: TOP_SCORERS_PAGE_SIZE, ...data })
      .then((response) => toTopScorers(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getNationalTeamAppearances(data) {
    return this.dataService
      .getNationalTeamAppearances({ pageSize: APPEARANCES_PAGE_SIZE, ...data })
      .then((response) => toAppearances(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCoaches(data) {
    return this.dataService
      .getNationalTeamCoaches({ pageSize: COACHES_PAGE_SIZE, ...data })
      .then((response) => toCoaches(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getNationalTeamCompetitions(data) {
    return this.dataService
      .getNationalTeamCompetitions(data)
      .then((response) => toCompetitions(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionTable(data) {
    return this.dataService
      .getCompetitionTable(data)
      .then((response) => ({
        table: toTable(response.competitionStanding),
      }))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
