import * as React from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import NationalTeams from "../nationalTeams";
import styles from "./critical.scss";

const getUrl = (route, id, competitionId, tab) => {
  return generatePath(route, { nationalTeamId: id, competitionId, tab });
};

const NationalTeamSelector = ({ label, alwaysShow, active, id }) => {
  const history = useHistory();
  const { competitionId, tab } = useParams();
  const { path } = useRouteMatch();

  return (
    <NationalTeams>
      {(nationalTeams) => (
        <section
          className={`${styles.section} ${alwaysShow ? styles.alwaysShow : ""}`}
        >
          <select
            className={styles.list}
            onChange={(e) => {
              history.push(
                getUrl(
                  path,
                  e.target.value.replace(/^\//, ""),
                  competitionId,
                  tab
                )
              );
              window.scrollTo(0, 0);
            }}
            defaultValue={id || ""}
          >
            <option className={styles.listItem} value="" disabled>
              {label}
            </option>
            {nationalTeams.map((n) => (
              <option
                className={`${styles.listItem} ${
                  n.nationalTeamId === active ? styles.listItemActive : ""
                }`}
                key={n.nationalTeamId}
                value={n.nationalTeamId}
              >
                {n.name}
              </option>
            ))}
          </select>
        </section>
      )}
    </NationalTeams>
  );
};

export default NationalTeamSelector;
