import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_PLAYER } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  id: null,
  name: null,
  position: null,
  profilePhotoUrl: null,
  team: {},
  nationalTeam: {},
  overview: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER:
      return {
        ...state,
        id: action.data.id,
        name: action.data.name,
        position: action.data.position,
        profilePhotoUrl: action.data.profilePhotoUrl,
        team: action.data.team,
        nationalTeam: action.data.nationalTeam,
        overview: action.data.overview,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectPlayer = createSelector(getState, (n) => ({
  id: n.id,
  name: n.name,
  position: n.position,
  profilePhotoUrl: n.profilePhotoUrl,
  team: n.team,
  nationalTeam: n.nationalTeam,
  overview: n.overview,
}));

reducerRegistry.register(REDUCER_NAME, reducer);
