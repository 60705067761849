import React, { useState } from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import Scoreboard from "../../components/scoreboard";
import ThemedHeading from "../../components/themedHeading";
import usePoll from "../../hooks/poll";
import CompetitionMatchChannel from "../competitionMatchChannel";
import MatchEvents from "../matchEvents";
import { TYPE } from "./constants";
import styles from "./style.scss";

const URL_TEMPLATE = "/match/:matchId";
const PLACEHOLDER = ":matchId";

const POLL_INTERVAL = 60000;
const POLL_INTERVAL_LIVE = 12000;

const LABELS = {
  DETAILS: "Details",
  SEPARATOR: "vs",
  UNKNOWN_VENUE: "Venue: TBC",
  SHOW_MORE: "Load more",
  POSTPONED: "Post.",
};

export const CompetitionMatches = ({
  title,
  subtitle,
  subtitleColor,
  competitionId,
  onGetCompetitionMatches,
  isReady,
  matches,
  type,
  noResultsMessage,
  date,
  showPagination,
  nextPage,
  pageSize,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionMatches({ competitionId, type, date, pageSize });
    }
  }, [competitionId, type, date, onGetCompetitionMatches, isReady, pageSize]);

  const handleGetCompetitionMatches = React.useCallback(() => {
    if (nextPage < 2) {
      onGetCompetitionMatches({ competitionId, type, date, pageSize });
    }
  }, [onGetCompetitionMatches, competitionId, type, date, pageSize, nextPage]);

  usePoll({
    callback: handleGetCompetitionMatches,
    interval: type === TYPE.LIVE ? POLL_INTERVAL_LIVE : POLL_INTERVAL,
  });

  const [isLoadingPage, setIsLoadingPage] = useState(false);

  if (!isReady && !(type === TYPE.LIVE)) {
    return (
      <section className={title ? "" : styles.container}>
        {title && (
          <CompetitionThemedHeading>
            <h2 className={styles.title}>{title}</h2>
          </CompetitionThemedHeading>
        )}
        <ul className={`${styles.list}`}>
          {[...Array(pageSize)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(matches && matches.length)) {
    if (!noResultsMessage) {
      return null;
    }

    return (
      <div className={`${styles.list} ${styles.container}`}>
        <div className={styles.listItem}>
          <span className={`${styles.noResultsMessage}`}>
            {noResultsMessage}
          </span>
        </div>
      </div>
    );
  }

  return (
    <section className={title ? "" : styles.container}>
      {title && (
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{title}</h2>
        </CompetitionThemedHeading>
      )}
      <ErrorBoundary>
        <ul className={styles.list}>
          {matches.map((n) => (
            <li className={styles.listItem} key={n.id}>
              <Link
                to={URL_TEMPLATE.replace(PLACEHOLDER, n.id)}
                className={styles.listItemContent}
              >
                {n.isLive ? (
                  <div className={styles.matchTime}>
                    <span className={styles.matchTimeValue}>
                      {n.matchTime && n.matchTime.startsWith("-")
                        ? "-"
                        : n.matchTime}
                    </span>
                  </div>
                ) : (
                  <span className={styles.startDate}>
                    {n.isPostponed ? LABELS.POSTPONED : n.formattedStartDate}
                  </span>
                )}
                <div className={styles.details}>
                  <div className={styles.detailsVenue}>
                    {subtitle && (
                      <ThemedHeading color={subtitleColor}>
                        <span className={styles.subtitle}>{subtitle}</span>
                      </ThemedHeading>
                    )}
                    {n.competitionName && (
                      <span className={styles.competitionName}>
                        {n.competitionName}
                      </span>
                    )}
                    <span className={styles.detailsVenueName}>
                      {n.venue ? n.venue.name : LABELS.UNKNOWN_VENUE}
                    </span>
                  </div>
                  <div className={styles.teams}>
                    <div className={`${styles.team} ${styles.teamHome}`}>
                      <span
                        className={`${styles.teamName} ${styles.teamNameHome}`}
                      >
                        {n.homeTeam.name}
                      </span>
                      <img
                        src={n.homeTeam.logoUrl}
                        alt=""
                        className={styles.teamImg}
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.teamsSeparator}>
                      {n.isEnded || n.isLive ? (
                        <MatchEvents matchId={n.id} isLive={n.isLive}>
                          {({ score, isReady: isScoreboardReady }) => (
                            <Scoreboard
                              score={score}
                              isReady={isScoreboardReady}
                            />
                          )}
                        </MatchEvents>
                      ) : (
                        LABELS.SEPARATOR
                      )}
                    </div>
                    <div className={`${styles.team} ${styles.teamAway}`}>
                      <img
                        src={n.awayTeam.logoUrl}
                        alt=""
                        className={styles.teamImg}
                        loading="lazy"
                      />
                      <span
                        className={`${styles.teamName} ${styles.teamNameAway}`}
                      >
                        {n.awayTeam.name}
                      </span>
                    </div>
                  </div>
                </div>
                {!n.isEnded ? <CompetitionMatchChannel id={n.id} /> : <div />}
                <span className={styles.link}>{LABELS.DETAILS}</span>
              </Link>
            </li>
          ))}
          {isLoadingPage &&
            [...Array(pageSize)].map((_n, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`${styles.listItem} ${styles.skeleton}`}
              />
            ))}
        </ul>
        {showPagination && nextPage && (
          <button
            type="button"
            disabled={isLoadingPage}
            onClick={() => {
              setIsLoadingPage(true);
              onGetCompetitionMatches({
                competitionId,
                type,
                date,
                page: nextPage,
                append: true,
                pageSize,
              })
                .then(() => {
                  setIsLoadingPage(false);
                })
                .catch(() => {
                  setIsLoadingPage(false);
                });
            }}
            className={styles.showMore}
          >
            {LABELS.SHOW_MORE}
          </button>
        )}
      </ErrorBoundary>
    </section>
  );
};
