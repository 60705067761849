import * as React from "react";
import { useParams } from "react-router-dom";

import NationalTeamCompetitionResults from "../../containers/nationalTeamCompetitionResults";
import NationalTeamCompetitions from "../../containers/nationalTeamCompetitions";
import NationalTeamCompetitionTable from "../../containers/nationalTeamCompetitionTable";
import { TYPE } from "../../containers/teamMatches/constants";
import styles from "./critical.scss";

const LABELS = {
  TITLE_PAST: "Results",
};

const NationalTeamPastCompetitionsPage = ({ teamId, nationalTeamId }) => {
  const { competitionId } = useParams();

  return (
    <>
      <section className={styles.section}>
        <NationalTeamCompetitions
          title={LABELS.TITLE_UPCOMING}
          nationalTeamId={nationalTeamId}
          type={TYPE.UPCOMING}
          competitionId={competitionId}
        />
      </section>
      <section className={styles.section}>
        <NationalTeamCompetitionResults
          nationalTeamId={nationalTeamId}
          competitionId={competitionId}
          title={LABELS.TITLE_PAST}
          type={TYPE.PAST}
        />
      </section>
      <section className={styles.section}>
        <NationalTeamCompetitionTable
          teamId={teamId}
          nationalTeamId={nationalTeamId}
          competitionId={competitionId}
        />
      </section>
    </>
  );
};

export default NationalTeamPastCompetitionsPage;
