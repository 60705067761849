import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import Scoreboard from "../../components/scoreboard";
import usePoll from "../../hooks/poll";
import MatchEvents from "../matchEvents";
import styles from "./style.scss";

const URL_TEMPLATE = "/match/:matchId";
const MATCH_PLACEHOLDER = ":matchId";

const POLL_INTERVAL = 60000;

const LABELS = {
  TITLE: "Cup",
  SEPARATOR: "vs",
};

const CompetitionCup = ({
  competitionId,
  teamId,
  season,
  onGetCompetitionCup,
  onResetCompetitionCup,
  isReady,
  isError,
  cup,
  isCurrent,
}) => {
  const listDataEl = React.useRef(null);

  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionCup({ id: competitionId, season });
    }
  }, [competitionId, season, onGetCompetitionCup, isReady]);

  React.useEffect(
    () => () => onResetCompetitionCup(competitionId, season),
    [onResetCompetitionCup, competitionId, season]
  );

  React.useLayoutEffect(() => {
    if (listDataEl.current) {
      listDataEl.current
        .querySelector("ul:last-child")
        .scrollIntoView({ block: "center", inline: "center" });
    }
  }, []);

  const handleGetCompetitionCup = React.useCallback(
    () => onGetCompetitionCup({ id: competitionId, season }),
    [onGetCompetitionCup, competitionId, season]
  );

  usePoll({
    callback: handleGetCompetitionCup,
    interval: isCurrent ? POLL_INTERVAL : 0,
  });

  if (isError) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <div className={styles.listData}>
          <ul className={`${styles.list}`}>
            {[...Array(4)].map((n, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`${styles.listItem}`}
              >
                <div className={styles.link}>
                  <div className={`${styles.team} ${styles.skeleton}`} />
                  <div className={`${styles.team} ${styles.skeleton}`} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
  }

  return (
    <section>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{LABELS.TITLE}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <div className={styles.container} ref={listDataEl}>
          <div className={styles.listData}>
            {Object.entries(cup).map((n) => (
              <ul key={n[0]} className={styles.list}>
                {n[1].map((m) => (
                  <li
                    key={m.id}
                    className={`${styles.listItem} ${
                      teamId === m.homeTeam.id || teamId === m.awayTeam.id
                        ? styles.listItemSelected
                        : ""
                    }`}
                  >
                    <Link
                      to={URL_TEMPLATE.replace(MATCH_PLACEHOLDER, m.id)}
                      className={styles.link}
                    >
                      <span
                        className={`${styles.date} ${
                          m.isLive ? styles.isLive : ""
                        }`}
                      >
                        {m.formattedStartDate}
                      </span>
                      <div className={styles.details}>
                        <div className={styles.teams}>
                          <div className={styles.team}>
                            <span className={styles.imgWrapper}>
                              <img
                                src={m.homeTeam.logoUrl}
                                alt=""
                                className={styles.img}
                                loading="lazy"
                              />
                            </span>
                            <span className={styles.name}>
                              {m.homeTeam.name}
                            </span>
                          </div>
                          <div className={styles.team}>
                            <span className={styles.imgWrapper}>
                              <img
                                src={m.awayTeam.logoUrl}
                                alt=""
                                className={styles.img}
                                loading="lazy"
                              />
                            </span>
                            <span className={styles.name}>
                              {m.awayTeam.name}
                            </span>
                          </div>
                        </div>
                        <div className={styles.teamsSeparator}>
                          {m.isEnded || m.isLive ? (
                            <MatchEvents matchId={m.id} isLive={m.isLive}>
                              {({ score, isReady: isScoreboardReady }) => (
                                <Scoreboard
                                  score={score}
                                  isReady={isScoreboardReady}
                                  className={styles.scoreboard}
                                  separator=""
                                />
                              )}
                            </MatchEvents>
                          ) : (
                            LABELS.SEPARATOR
                          )}
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
};

export default CompetitionCup;
