import { toMeta, toPage, toResponsiveImage } from "../adapter";

const toTeam = (data) =>
  data
    ? {
        ...toPage(data),
        meta: {
          ...toMeta(data.meta),
          thumbnail: data.logoUrl,
        },
        team: {
          id: data.teamId,
          name: data.name,
          logoUrl: data.logoUrl,
          images:
            data.banners &&
            data.banners.length &&
            toResponsiveImage(data.banners[0].images),
          competitions: data.competitionTypes,
          competitionId: data.competitionTypes[0],
          website: data.websiteUrl,
          social: data.socialLinks,
        },
      }
    : null;

export const toTeamDetails = (data) => toTeam(data);

export const toTeams = (data) => (data ? data.map((n) => toTeam(n)) : []);

export const toSponsors = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        url: n.url,
        tier: n.tier,
        image: n.logo && n.logo.imageUrl,
      }))
    : null;

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
        url: data.url,
      }
    : null;

export const toTeamNews = (data) =>
  data
    ? data.map((n) => ({
        ...toPage(n),
        summary: n.summary,
        tags: n.tags && n.tags.filter((m) => m),
        date: n.date,
        isFeatured: n.isFeatured,
        category: toNewsCategory(n.category),
        video: n.video,
      }))
    : [];

export const toPlayers = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        number: n.number,
        position: n.position,
        profilePhotoUrl: n.profilePhoto,
        isGoalkeeper: n.isGoalkeeper,
        isCaptain: n.isCaptain,
      }))
    : [];
