import * as React from "react";

import { useCompetitionContext } from "../competition/context";
import CompetitionThemedHeading from "../competitionThemedHeading";
import TROPHY_BRONZE from "./img/trophy-bronze.svg";
import TROPHY_GOLD from "./img/trophy-gold.svg";
import TROPHY_SILVER from "./img/trophy-silver.svg";
import styles from "./style.scss";

const LABELS = {
  ACHIEVEMENTS_TITLE: "Achievements",
  FIRST_PLACE: "Winners",
  SECOND_PLACE: "Runners Up",
  THIRD_PLACE: "3rd Place",
};

const CompetitionAchievements = ({ achievement }) => {
  const {
    state: { name: competitionName },
  } = useCompetitionContext();

  return (
    <>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{LABELS.ACHIEVEMENTS_TITLE}</h2>
      </CompetitionThemedHeading>
      <div className={styles.achievement}>
        <div className={styles.icon}>
          {achievement.pos === 1 && (
            <img src={TROPHY_GOLD} alt="" className={styles.iconImg} />
          )}
          {achievement.pos === 2 && (
            <img src={TROPHY_SILVER} alt="" className={styles.iconImg} />
          )}
          {achievement.pos === 3 && (
            <img src={TROPHY_BRONZE} alt="" className={styles.iconImg} />
          )}
        </div>
        <div className={styles.text}>
          <span className={styles.title}>
            {achievement.pos === 1 && LABELS.FIRST_PLACE}
            {achievement.pos === 2 && LABELS.SECOND_PLACE}
            {achievement.pos === 3 && LABELS.THIRD_PLACE}
          </span>
          <span className={styles.competition}>{competitionName}</span>
        </div>
      </div>
    </>
  );
};

export default CompetitionAchievements;
