import * as React from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import NationalTeamBanner from "../../components/nationalTeamBanner";
import SkeletonTabs from "../../components/skeletonTabs";
import { TabButton } from "../../components/tabButton";
import { Tabs } from "../../components/tabs";
import Page from "../page";
import { ROUTES } from "./constants";
import styles from "./critical.scss";
import { Routes } from "./routes";

const LABELS = {
  HOME: "Home",
  NEWS: "News",
  PAST_COMPETITIONS: "Past Competitions",
  HISTORY: "History",
  TOP_SCORERS: "Top Scorers",
  APPEARANCES: "Appearances",
  COACHES: "Coaches",
  PLAYERS: "Players",
  TABLE: "Table",
};

const getUrl = (route, nationalTeamId) => {
  return generatePath(route, {
    nationalTeamId: nationalTeamId.replace(/^\//, ""),
  });
};

const getArchiveUrl = (route, competitionId) => {
  return generatePath(route, { competitionId });
};

const Component = ({ isReady, team, onGetTeamPage }) => {
  const { path } = useRouteMatch();
  const { nationalTeamId } = useParams();

  const getPage = React.useCallback(onGetTeamPage, [onGetTeamPage]);

  return (
    <Page
      url={`/national-team/${nationalTeamId}`}
      onGetPage={getPage}
      scrollToTop
    >
      {({ isReady: isPageReady }) => {
        if (!(isPageReady && isReady)) {
          return (
            <>
              <div className={styles.banner} />
              <SkeletonTabs />
            </>
          );
        }

        return (
          <section important="true">
            <NationalTeamBanner team={team} />
            <Tabs>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.EVENTS}`}
                exact
                replace
              >
                {LABELS.HOME}
              </TabButton>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.TABLE}`}
                replace
              >
                {LABELS.TABLE}
              </TabButton>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.NEWS}`}
                replace
              >
                {LABELS.NEWS}
              </TabButton>
              {/* <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.PLAYERS}`}
                replace
              >
                {LABELS.PLAYERS}
              </TabButton> */}
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.TOP_SCORERS}`}
                replace
              >
                {LABELS.TOP_SCORERS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.APPEARANCES}`}
                replace
              >
                {LABELS.APPEARANCES}
              </TabButton>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.COACHES}`}
                replace
              >
                {LABELS.COACHES}
              </TabButton>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${getArchiveUrl(
                  ROUTES.PAST_COMPETITIONS
                )}`}
                replace
              >
                {LABELS.PAST_COMPETITIONS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, team.nationalTeamId)}${ROUTES.HISTORY}`}
                replace
              >
                {LABELS.HISTORY}
              </TabButton>
            </Tabs>
            <Routes path={path} team={team} />
          </section>
        );
      }}
    </Page>
  );
};

export default Component;
