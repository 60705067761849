import * as React from "react";
import { Link } from "react-router-dom";

import logo from "../../../img/logo.png";
import SocialLinks from "../../containers/socialLinks";
import HamburgerNavigation from "../hamburgerNavigation";
import styles from "./critical.scss";

const Header = React.memo(() => {
  return (
    <header className={styles.header}>
      <div className={styles.headerBackground} />
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logo}>
            <img
              src={logo}
              alt={process.env.DESCRIPTION}
              title={process.env.DESCRIPTION}
              className={styles.logoImg}
            />
            <span className={styles.logoText}>{process.env.DESCRIPTION}</span>
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <HamburgerNavigation />
          <div className={styles.socialLinks}>
            <SocialLinks />
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
