import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../competitionThemedHeading";
import { NewsArticle } from "../newsArticle/newsArticle";
import { NewsHighlight } from "../newsHighlight/newsHighlight";
import styles from "./style.scss";

export const NewsSection = React.memo(({ news, title, noResults, isReady }) => {
  if (!isReady && noResults) {
    return (
      <div>
        <CompetitionThemedHeading>
          <h2 className={styles.newsTitle}>{title}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.newsList}`}>
          {[...Array(4)].map((n, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.newsListItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </div>
    );
  }

  if (news && news.length) {
    return (
      <div>
        <CompetitionThemedHeading>
          <h2 className={styles.newsTitle}>{title}</h2>
        </CompetitionThemedHeading>
        <ul className={styles.newsList}>
          {news.map((n) => (
            <li key={n.id} className={styles.newsListItem}>
              {n.url ? (
                <Link
                  to={n.url}
                  title={n.title}
                  className={styles.newsListItemLink}
                >
                  <NewsArticle data={n} displayAsRow />
                </Link>
              ) : (
                <NewsHighlight data={n} />
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  if (noResults) {
    return (
      <div>
        <CompetitionThemedHeading>
          <h2 className={styles.newsTitle}>{title}</h2>
        </CompetitionThemedHeading>
        <p>{noResults}</p>
      </div>
    );
  }

  return null;
});
