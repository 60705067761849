import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";
import styles from "./style.scss";

const URL_TEMPLATE = "/player/:playerId";
const PLACEHOLDER = ":playerId";

const LABELS = {
  TITLE: "Team",
};

const Component = ({
  teamId,
  competitionId,
  onGetTeamPlayers,
  onResetTeamPlayers,
  isReady,
  isError,
  players,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetTeamPlayers({ teamId, competitionId });
    }
  }, [teamId, competitionId, onGetTeamPlayers, isReady]);

  React.useEffect(
    () => () => onResetTeamPlayers(teamId, competitionId),
    [onResetTeamPlayers, teamId, competitionId]
  );

  if (isError) {
    return (
      <section className={styles.section}>
        <CompetitionThemedHeading>
          <h2>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section className={styles.section}>
        <CompetitionThemedHeading>
          <h2>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={styles.skeletonList}>
          {[...Array(3)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(players && players.length)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2>{LABELS.TITLE}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.list}>
          {players.map((n) => (
            <li className={styles.listItem} key={n.id}>
              <Link
                to={URL_TEMPLATE.replace(PLACEHOLDER, n.id)}
                className={styles.listItemContent}
              >
                <div className={styles.player}>
                  <div className={styles.container}>
                    <div className={styles.thumbnail}>
                      <img
                        src={n.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                        alt=""
                        className={styles.img}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className={styles.details}>
                    <span className={styles.name}>{n.name}</span>
                    <span className={styles.position}>{n.position}</span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default Component;
