import * as React from "react";
import { Link, generatePath, useRouteMatch } from "react-router-dom";

import CompetitionThemedBackground from "../../components/competitionThemedBackground";
import ErrorBoundary from "../../components/errorBoundary";
import styles from "./style.scss";

const getUrl = (route, playerId, season) => {
  if (season) {
    return generatePath(route, {
      playerId,
      season,
    });
  }

  return generatePath(route, { playerId });
};

export const PlayerSeasons = ({
  playerId,
  onGetPlayerSeasons,
  onResetPlayerSeasons,
  isReady,
  seasons,
  season,
}) => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    if (!isReady) {
      onGetPlayerSeasons({ id: playerId });
    }
  }, [onGetPlayerSeasons, playerId, isReady]);

  React.useEffect(
    () => () => onResetPlayerSeasons(playerId),
    [onResetPlayerSeasons, playerId]
  );

  if (!isReady) {
    return <div className={styles.section} />;
  }

  return (
    <section className={styles.section}>
      <ErrorBoundary>
        <ul className={styles.list}>
          {seasons.map((n) => (
            <li className={`${styles.listItem}`} key={n.id}>
              <Link
                replace
                to={getUrl(path, playerId, n.id === Number(season) ? "" : n.id)}
                className={`${styles.link} ${
                  n.id === Number(season) ? styles.active : ""
                }`}
              >
                <CompetitionThemedBackground />
                {n.title}
              </Link>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};
