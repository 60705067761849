import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../competitionThemedHeading";
import ErrorBoundary from "../errorBoundary";
import styles from "./styles.scss";

const URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const COMPETITION_PLACEHOLDER = ":competitionId";

const LABELS = {
  TITLE: "Standings",
  POS: "Pos.",
  CLUB: "Club",
  MP: "MP",
  W: "Won",
  D: "Drawn",
  L: "Lost",
  GD: "GD",
  PTS: "PTS",
  NEXT: "Next",
};

const CompetitionStandings = ({ teamId, competitionId, title, table }) => (
  <ErrorBoundary>
    <CompetitionThemedHeading>
      <h3 className={styles.title}>{title}</h3>
    </CompetitionThemedHeading>
    <ul className={styles.list}>
      <li className={`${styles.listItem} ${styles.listItemHeading}`}>
        <span className={`${styles.column} ${styles.columnPos}`}>
          {LABELS.POS}
        </span>
        <span className={`${styles.column} ${styles.columnTeam}`}>
          {LABELS.CLUB}
        </span>
        <span className={styles.column}>{LABELS.MP}</span>
        <span className={`${styles.column} ${styles.columnDetail}`}>
          {LABELS.W}
        </span>
        <span className={`${styles.column} ${styles.columnDetail}`}>
          {LABELS.D}
        </span>
        <span className={`${styles.column} ${styles.columnDetail}`}>
          {LABELS.L}
        </span>
        <span className={styles.column}>{LABELS.GD}</span>
        <span className={styles.column}>{LABELS.PTS}</span>
        <span className={`${styles.column} ${styles.columnDetail}`}>
          {LABELS.NEXT}
        </span>
      </li>
    </ul>
    <ul className={`${styles.list} ${styles.listData}`}>
      {table.map((n) => (
        <li
          key={n.id}
          className={`${styles.listItem} ${
            teamId === n.teamId ? styles.listItemSelected : ""
          }`}
        >
          <span className={`${styles.column} ${styles.columnPos}`}>
            {n.pos}
          </span>
          <div
            className={`${styles.column} ${styles.columnTeam} ${
              n.isLive ? styles.isLive : ""
            }`}
          >
            <span className={styles.columnImgWrapper}>
              <img
                src={n.logoUrl}
                alt=""
                className={styles.columnImg}
                loading="lazy"
              />
            </span>
            <Link
              to={URL_TEMPLATE.replace(TEAM_PLACEHOLDER, n.teamId).replace(
                COMPETITION_PLACEHOLDER,
                competitionId
              )}
              className={styles.columnName}
            >
              {n.name}
            </Link>
          </div>
          <span className={styles.column}>{n.mp}</span>
          <span className={`${styles.column} ${styles.columnDetail}`}>
            {n.w}
          </span>
          <span className={`${styles.column} ${styles.columnDetail}`}>
            {n.d}
          </span>
          <span className={`${styles.column} ${styles.columnDetail}`}>
            {n.l}
          </span>
          <span className={styles.column}>{n.gd}</span>
          <span className={`${styles.column} ${styles.columnPts}`}>
            {n.pts}
          </span>
          <span className={`${styles.column} ${styles.columnDetail}`}>
            {n.next && (
              <img
                src={n.next.logoUrl}
                alt=""
                className={styles.nextTeamImg}
                title={n.next.name}
                loading="lazy"
              />
            )}
          </span>
        </li>
      ))}
    </ul>
  </ErrorBoundary>
);

export default CompetitionStandings;
