import * as React from "react";

const ResponsiveImage = ({
  src,
  srcset,
  alt,
  title,
  lazyload,
  className,
  ...props
}) => (
  <img
    src={src}
    srcSet={srcset}
    alt={alt}
    title={title}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    loading={lazyload ? "lazy" : "auto"}
    className={className ?? ""}
  />
);

export default ResponsiveImage;
