import * as React from "react";

import HomeNews from "../homeNews";
import styles from "./style.scss";

const LABELS = {
  LOADING: "Loading...",
};

export const HomeNewsCategories = ({
  onGetHomeNewsCategories,
  isReady,
  isError,
  newsCategories,
}) => {
  React.useEffect(() => {
    onGetHomeNewsCategories();
  }, [onGetHomeNewsCategories]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return (
      <div className={styles.category}>
        <h2>{LABELS.LOADING}</h2>
        {[...Array(1)].map((n, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`${styles.skeleton}`}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      {newsCategories.map((n) => (
        <div key={n.id} className={styles.category}>
          <HomeNews categoryId={n.id} title={n.title} />
        </div>
      ))}
    </>
  );
};
