import Api from "../../../../service";
import {
  RESET_COMPETITIONTABLE,
  SET_COMPETITIONTABLE,
  SET_ERROR,
  getKey,
} from "./constants";

const setCompetitionTable = (data, key) => ({
  type: SET_COMPETITIONTABLE,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getCompetitionTable = (data) => (dispatch) =>
  Api.nationalTeam
    .getCompetitionTable(data)
    .then((response) => {
      dispatch(setCompetitionTable(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });

export const resetCompetitionTable = (data) => ({
  type: RESET_COMPETITIONTABLE,
  data,
});
