import * as React from "react";

import Advertisement from "../../containers/advertisement";
import ResponsiveImage from "../shared/responsiveImage";
import styles from "./style.scss";

const ADVERT_TYPE = "banner";
const WIDTH = "468";
const HEIGHT = "60";

const BannerAdvertisement = ({ uid, competitionId }) => (
  <Advertisement type={ADVERT_TYPE} uid={uid} competitionId={competitionId}>
    {(advertisement, handleClick) => (
      <div className={styles.advert}>
        <ResponsiveImage
          width={WIDTH}
          height={HEIGHT}
          src={advertisement.images.src}
          srcset={advertisement.images.srcset}
          alt={advertisement.images.alt}
          title={advertisement.name}
          className={styles.advertImg}
          lazyload
          onClick={handleClick}
          data-advert={advertisement.key}
          data-uid={uid}
        />
      </div>
    )}
  </Advertisement>
);

export default BannerAdvertisement;
