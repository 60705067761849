import Api from "../../../../service";
import { makeGetPage } from "../page/actions";
import { SET_ERROR, SET_NEWS } from "./constants";

const setNews = (data) => ({
  type: SET_NEWS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getPage = (data) => (dispatch) =>
  Api.news
    .getNewsPage(data)
    .then((response) => {
      dispatch(setNews(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const getNewsPage = (data) => (dispatch) =>
  makeGetPage(getPage)(data)(dispatch);
