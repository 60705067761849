import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getHomeNews } from "./actions";
import { HomeNewsSection as Component } from "./homeNewsSection";
import { selectIsError, selectIsReady, selectNews } from "./reducer";

const PAGE_SIZE = 4;

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  news: selectNews,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHomeNews: (data) => dispatch(getHomeNews(data)),
});

export function fetchData(store) {
  return store.dispatch(getHomeNews({ pageSize: PAGE_SIZE }));
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
