import * as React from "react";

import styles from "./critical.scss";

const HomeBannerParticipants = ({ homeTeam, awayTeam, children }) => {
  return (
    <div className={styles.teams}>
      <div className={`${styles.team} ${styles.homeTeam}`}>
        <span className={styles.teamName}>{homeTeam.name}</span>
        <img src={homeTeam.logoUrl} alt="" className={styles.teamImg} />
      </div>
      <div className={styles.teamsSeparator}>{children}</div>
      <div className={`${styles.team} ${styles.awayTeam}`}>
        <img src={awayTeam.logoUrl} alt="" className={styles.teamImg} />
        <span className={styles.teamName}>{awayTeam.name}</span>
      </div>
    </div>
  );
};

export default HomeBannerParticipants;
