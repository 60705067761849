import { toImage } from "../adapter";

export const toAdvertisement = (data) => ({
  type: data.type,
  competitionTypeId: data.competitionTypeId,
  images: toImage(data.image),
  url: data.url,
  key: data.key,
  id: data.id,
});
