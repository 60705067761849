import * as React from "react";

import styles from "./style.scss";

const Component = ({ tier, onGetHomeSponsors, isReady, isError, sponsors }) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetHomeSponsors();
    }
  }, [onGetHomeSponsors, isReady]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  return (
    <section className={styles.section}>
      <ul className={styles.list}>
        {sponsors.map((n) => (
          <li
            className={`${styles.listItem} ${
              tier === 2 ? styles.listItemTierTwo : ""
            }`}
            key={n.id}
          >
            <a href={n.url} target="_blank" rel="noreferrer">
              <img
                src={n.image}
                alt={n.name}
                title={n.name}
                className={styles.listItemImg}
                loading="lazy"
              />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Component;
