import * as React from "react";

import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import PlayerOverview from "../playerOverview";
import PlayerTeam from "../playerTeam";
import styles from "./critical.scss";

const LABELS = {
  TITLE: "Player Profile",
};

const PlayerHomePage = ({ player }) => (
  <section className={styles.section}>
    <PlayerTeam team={player.team} />
    <PlayerTeam team={player.nationalTeam} />
    <h2>{LABELS.TITLE}</h2>
    <PlayerOverview overview={player.overview} />
    <LeaderboardOrSquareAdvertisement uid="player-home-page-1" />
  </section>
);

export default PlayerHomePage;
