import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

import CompetitionMatches from "../competitionMatches";
import { TYPE } from "../competitionMatches/constants";
import styles from "./critical.scss";

const LABELS = {
  LOAD_MORE: "More matches",
  COMPETITION_TITLE: "Filter by competition",
  DATE_TITLE: "Filter by date",
  NO_RESULTS: "No matches matched your search criteria.",
};

const URL_TEMPLATE = "/competition/:competitionId";
const PLACEHOLDER = ":competitionId";

const DEFAULT_COMPETITION = 58539;

export const UpcomingMatchesSection = ({
  isReady,
  isError,
  competitions,
  title,
  onGetCompetitions,
  active,
}) => {
  const [competitionId, setCompetitionId] = useState(DEFAULT_COMPETITION);
  const [date, setDate] = useState(null);

  const pageSize = useMemo(() => {
    const competition = competitions.find(
      (n) => `${n.id}` === `${competitionId}`
    );

    if (competition) {
      return competition.matchPageSize;
    }

    return null;
  }, [competitionId, competitions]);

  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitions();
    }
  }, [onGetCompetitions, isReady]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  return (
    <div important="true">
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <label htmlFor="competition" className={styles.filterTitle}>
            {LABELS.COMPETITION_TITLE}
          </label>
          {Boolean(competitions && competitions.length) && (
            <select
              id="competitoin"
              className={styles.list}
              defaultValue={DEFAULT_COMPETITION}
              onChange={(e) => setCompetitionId(e.target.value)}
            >
              {competitions.map((n) => (
                <option
                  className={`${styles.listItem} ${
                    n.id === active ? styles.listItemActive : ""
                  }`}
                  key={n.id}
                  value={n.id}
                >
                  {n.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={styles.filter}>
          <label htmlFor="date" className={styles.filterTitle}>
            {LABELS.DATE_TITLE}
          </label>
          <input
            id="date"
            type="date"
            className={`${styles.date} ${styles.dateMobile}`}
            value={date ? format(date, "yyyy-MM-dd") : ""}
            onChange={(n) => setDate(n.target.valueAsDate)}
            min={format(new Date(), "yyyy-MM-dd")}
          />
          <DatePicker
            className={`${styles.date} ${styles.dateDesktop}`}
            selected={date}
            onChange={(n) => setDate(n)}
            minDate={new Date()}
            dateFormat="dd MMM yyyy"
          />
        </div>
      </div>
      <CompetitionMatches
        competitionId={competitionId}
        date={date}
        type={TYPE.UPCOMING}
        noResultsMessage={LABELS.NO_RESULTS}
        pageSize={pageSize}
      />
      <div className={styles.footer}>
        <Link
          to={URL_TEMPLATE.replace(PLACEHOLDER, competitionId)}
          className={styles.readMore}
        >
          {LABELS.LOAD_MORE}
        </Link>
      </div>
    </div>
  );
};
