import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";
import styles from "./style.scss";

const URL_TEMPLATE = "/team/:teamId";
const PLACEHOLDER = ":teamId";
const PLAYER_URL_TEMPLATE = "/player/:playerId";
const PLAYER_PLACEHOLDER = ":playerId";

const LABELS = {
  TITLE: "Top Scorers",
  NAME: "Name",
  CLUB: "Club",
  MP: "Matches",
  GOALS: "Goals",
  NO_RESULTS: "There are currently no scorers for this competition.",
};

export const CompetitionTopScorers = ({
  competitionId,
  season,
  onGetCompetitionTopScorers,
  isReady,
  isError,
  table,
  className,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionTopScorers({ competitionId, season });
    }
  }, [competitionId, season, onGetCompetitionTopScorers, isReady]);

  if (isError) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnName}`}>
              {LABELS.NAME}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.GOALS}</span>
          </li>
        </ul>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnName}`}>
              {LABELS.NAME}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.GOALS}</span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(16)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  return (
    <section className={className ?? ""}>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{LABELS.TITLE}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.competitionList}>
          {table.map((n) => (
            <li key={n.id}>
              <CompetitionThemedHeading>
                <h3 className={styles.title}>{n.name}</h3>
              </CompetitionThemedHeading>
              <ul className={styles.list}>
                <li className={`${styles.listItem} ${styles.listItemHeading}`}>
                  <span className={`${styles.column} ${styles.columnName}`}>
                    {LABELS.NAME}
                  </span>
                  <span className={`${styles.column} ${styles.columnTeam}`}>
                    {LABELS.CLUB}
                  </span>
                  <span className={styles.column}>{LABELS.GOALS}</span>
                </li>
              </ul>
              <ul className={`${styles.list} ${styles.listData}`}>
                {n.data.length === 0 && (
                  <li className={styles.listItem}>{LABELS.NO_RESULTS}</li>
                )}
                {n.data.map((m) => (
                  <li key={m.id} className={styles.listItem}>
                    <Link
                      to={PLAYER_URL_TEMPLATE.replace(PLAYER_PLACEHOLDER, m.id)}
                      className={`${styles.column} ${styles.columnName}`}
                    >
                      <img
                        src={m.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                        alt=""
                        className={`${styles.columnImg} ${styles.profilePhoto}`}
                        loading="lazy"
                      />
                      {m.name}
                    </Link>
                    {m.team && (
                      <div className={`${styles.column} ${styles.columnTeam}`}>
                        <img
                          src={m.team.logoUrl}
                          alt=""
                          className={styles.columnImg}
                          loading="lazy"
                        />
                        <Link
                          to={URL_TEMPLATE.replace(PLACEHOLDER, m.team.id)}
                          className={styles.teamName}
                        >
                          {m.team.name}
                        </Link>
                      </div>
                    )}
                    <span className={`${styles.column} ${styles.columnPts}`}>
                      {m.goals}
                    </span>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};
