import * as React from "react";

import TeamNews from "../../containers/teamNews";
import styles from "./critical.scss";

const LABELS = {
  TITLE_FEATURED: "Featured News",
  TITLE: "Latest News",
};

const TeamNewsPage = ({ teamId }) => (
  <section className={styles.section}>
    <TeamNews title={LABELS.TITLE_FEATURED} isFeatured teamId={teamId} />
    <TeamNews
      title={LABELS.TITLE}
      teamId={teamId}
      showPagination
      paginationQueryStringParamName="page"
    />
  </section>
);

export default TeamNewsPage;
