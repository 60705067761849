import fetch from "node-fetch";

export default class {
  async getHighlights({ id, pageToken }) {
    const res = await fetch(
      `/api/competition/highlights?id=${id}${
        pageToken ? `&pageToken=${pageToken}` : ""
      }`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
