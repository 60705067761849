import * as React from "react";

import { useCompetitionContext } from "../competition/context";
import ThemedHeading from "../themedHeading";

const CompetitionThemedHeading = ({ children }) => {
  const {
    state: { themeColor1 },
  } = useCompetitionContext();

  return <ThemedHeading color={themeColor1}>{children}</ThemedHeading>;
};

export default CompetitionThemedHeading;
