import * as React from "react";
import { useParams } from "react-router-dom";

import ErrorBoundary from "../../components/errorBoundary";
import TeamHistoryContent from "../../components/teamHistoryContent";
import Page from "../page";

const LABELS = {
  SOCIAL: "Social",
  HISTORY: "History",
};

const TeamHistory = ({ team, onGetTeamPage }) => {
  const { teamId, competitionId: routeCompetitionId } = useParams();

  const competitionId = Number(routeCompetitionId) || team.competitionId;

  const getPage = React.useCallback(onGetTeamPage, [onGetTeamPage]);

  const params = React.useMemo(
    () => ({ teamId, competitionId }),
    [teamId, competitionId]
  );

  return (
    <Page
      url={`/team/${teamId}`}
      params={params}
      onGetPage={getPage}
      scrollToTop
    >
      {({ page, isReady: isPageReady }) => {
        if (!isPageReady) {
          return null;
        }

        return (
          <section>
            <h2>{LABELS.HISTORY}</h2>
            <ErrorBoundary>
              {page.html && Boolean(page.html.length) ? (
                <TeamHistoryContent html={page.html} />
              ) : null}
            </ErrorBoundary>
          </section>
        );
      }}
    </Page>
  );
};

export default TeamHistory;
