import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import Scoreboard from "../../components/scoreboard";
import MatchEvents from "../matchEvents";
import styles from "./style.scss";

const LABELS = {
  DETAILS: "Details",
  UNKNOWN_VENUE: "Venue: TBC",
  SEPARATOR: "vs",
  SHOW_MORE: "Load more",
  NO_RESULTS:
    "There are no results for this competition. Please check back later.",
};

const URL_TEMPLATE = "/match/:matchId";
const PLACEHOLDER = ":matchId";

export const CompetitionResults = ({
  title,
  competitionId,
  onGetCompetitionMatches,
  isReady,
  matches,
  type,
  listClassName,
  showPagination,
  nextPage,
  pageSize,
  season,
  date,
  teamId,
  waitForParentReady,
  renderFooter,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionMatches({
        competitionId,
        type,
        pageSize,
        date,
        season,
      });
    }
  }, [
    competitionId,
    type,
    pageSize,
    date,
    season,
    onGetCompetitionMatches,
    isReady,
  ]);

  if (!isReady || waitForParentReady) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{title}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          {[...Array(8)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(matches && matches.length)) {
    return null;
  }

  return (
    <section>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{title}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={`${styles.list} ${listClassName ?? ""}`}>
          {matches && (
            <>
              {Boolean(matches.length) &&
                matches.map((n) => (
                  <li
                    key={n.id}
                    className={`${styles.listItem} ${
                      teamId === n.homeTeam.id || teamId === n.awayTeam.id
                        ? styles.listItemSelected
                        : ""
                    }`}
                  >
                    <Link
                      to={URL_TEMPLATE.replace(PLACEHOLDER, n.id)}
                      className={styles.listItemContent}
                    >
                      {n.isLive ? (
                        <div className={styles.matchTime}>
                          <span className={styles.matchTimeValue}>
                            {n.matchTime && n.matchTime.startsWith("-")
                              ? "-"
                              : n.matchTime}
                          </span>
                        </div>
                      ) : (
                        <span className={styles.startDate}>
                          {n.formattedStartDate}
                        </span>
                      )}
                      <div className={styles.details}>
                        <div className={styles.detailsVenue}>
                          {n.competitionName && (
                            <span className={styles.competitionName}>
                              {n.competitionName}
                            </span>
                          )}
                          <span className={styles.detailsVenueName}>
                            {n.venue ? n.venue.name : LABELS.UNKNOWN_VENUE}
                          </span>
                        </div>
                        <div className={styles.teams}>
                          <div className={`${styles.team} ${styles.teamHome}`}>
                            <span
                              className={`${styles.teamName} ${styles.teamNameHome}`}
                            >
                              {n.homeTeam.name}
                            </span>
                            <img
                              src={n.homeTeam.logoUrl}
                              alt=""
                              className={styles.teamImg}
                              loading="lazy"
                            />
                          </div>
                          <div className={styles.teamsSeparator}>
                            {n.isEnded || n.isLive ? (
                              <MatchEvents matchId={n.id} isLive={n.isLive}>
                                {({ score, isReady: isScoreboardReady }) => (
                                  <Scoreboard
                                    score={score}
                                    isReady={isScoreboardReady}
                                  />
                                )}
                              </MatchEvents>
                            ) : (
                              LABELS.SEPARATOR
                            )}
                          </div>
                          <div className={`${styles.team} ${styles.teamAway}`}>
                            <img
                              src={n.awayTeam.logoUrl}
                              alt=""
                              className={styles.teamImg}
                              loading="lazy"
                            />
                            <span
                              className={`${styles.teamName} ${styles.teamNameAway}`}
                            >
                              {n.awayTeam.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <span className={styles.link}>{LABELS.DETAILS}</span>
                    </Link>
                  </li>
                ))}
              {!matches.length && (
                <li className={styles.noResultsMessage}>{LABELS.NO_RESULTS}</li>
              )}
            </>
          )}
          {isLoading &&
            [...Array(pageSize)].map((_n, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={`${styles.listItem} ${styles.skeleton}`}
              />
            ))}
        </ul>
        {showPagination && Boolean(nextPage) && (
          <button
            type="button"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              onGetCompetitionMatches({
                competitionId,
                type,
                date,
                season,
                page: nextPage,
                append: true,
                pageSize,
              })
                .then(() => {
                  setIsLoading(false);
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }}
            className={styles.showMore}
          >
            {LABELS.SHOW_MORE}
          </button>
        )}
      </ErrorBoundary>
      {renderFooter && Boolean(matches.length) && renderFooter()}
    </section>
  );
};
