import * as React from "react";
import { useParams } from "react-router-dom";

import PlayerHistory from "../../containers/playerHistory";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE: "Player History",
};

const PlayerHistoryPage = () => {
  const { playerId } = useParams();

  return (
    <section className={styles.section}>
      <h2>{LABELS.TITLE}</h2>
      <PlayerHistory playerId={playerId} />
      <LeaderboardOrSquareAdvertisement uid="player-history-page-1" />
    </section>
  );
};

export default PlayerHistoryPage;
