import Api from "../../../../service";
import { setCompetitionId } from "../app/actions";
import { makeGetPage } from "../page/actions";
import { RESET_MATCH, SET_ERROR, SET_MATCH } from "./constants";

const setMatch = (data) => ({
  type: SET_MATCH,
  data,
});

export const resetMatch = () => ({
  type: RESET_MATCH,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getMatch = (data) => (dispatch) =>
  Api.match
    .getMatchDetails(data)
    .then((response) => {
      dispatch(setMatch(response));
      dispatch(setCompetitionId(response.competitionId));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

const getPage = (data) => (dispatch) =>
  Api.match
    .getPage(data)
    .then((response) => {
      dispatch(setMatch(response.match));
      dispatch(setCompetitionId(response.match.competitionId));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getMatchPage = (data) => (dispatch) =>
  makeGetPage(getPage)(data)(dispatch);
