import { toNavigation } from "./adapter";
import DataService from "./dataService";

export default class {
  constructor({ dataService } = {}) {
    this.dataService = dataService || new DataService();
  }

  getNavigation(data) {
    return this.dataService
      .getNavigation(data)
      .then((response) => {
        return toNavigation(response);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
