import * as React from "react";

import styles from "./critical.scss";

const LABELS = {
  PENALTIES: "PEN",
};

const Scoreboard = ({
  score,
  isReady,
  isError,
  className,
  separator = "-",
  showPenalties,
  showResultSupplement,
}) => {
  if (isError) {
    return null;
  }

  if (!isReady) {
    return (
      <div className={className ?? ""}>
        <svg className={styles.spinner} viewBox="0 0 50 50">
          <circle
            className={styles.path}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </svg>
      </div>
    );
  }

  return (
    <>
      <div className={className ?? ""}>
        <span className={`${styles.score} ${styles.homeScore}`}>
          {score.homeScore !== null ? score.homeScore || 0 : ""}
          {score.isPenalties && score.homePenalties > score.awayPenalties
            ? "˙"
            : ""}
        </span>
        {separator && <span className={styles.hyphen}>{separator}</span>}
        <span className={`${styles.score} ${styles.awayScore}`}>
          {score.awayScore !== null ? score.awayScore || 0 : ""}
          {score.isPenalties && score.awayPenalties > score.homePenalties
            ? "˙"
            : ""}
        </span>
        {showPenalties && score.isPenalties && (
          <div className={styles.penalties}>
            <span className={`${styles.score} ${styles.homeScore}`}>
              {score.homePenalties || 0}
            </span>
            {separator && (
              <span className={styles.penaltiesHyphen}>{separator}</span>
            )}
            <span className={`${styles.score} ${styles.awayScore}`}>
              {score.awayPenalties || 0}
            </span>
            <span className={styles.penaltiesLabel}>{LABELS.PENALTIES}</span>
          </div>
        )}
        {showResultSupplement && score.resultSupplement && (
          <div className={styles.resultSupplement}>
            {score.resultSupplement}
          </div>
        )}
      </div>
    </>
  );
};

export default Scoreboard;
