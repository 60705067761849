import * as React from "react";

const Component = ({
  navigation,
  isReady,
  isError,
  onGetNavigation,
  children,
  type,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetNavigation({ type });
    }
  }, [onGetNavigation, isReady, type]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!(navigation && navigation.length)) {
    return null;
  }

  return <>{children({ navigation })}</>;
};

export default Component;
