import Api from "../../../../service";
import {
  RESET_NATIONALTEAMCOMPETITIONS,
  SET_ERROR,
  SET_NATIONALTEAMCOMPETITIONS,
} from "./constants";

const setNationalTeamCompetitions = (data) => ({
  type: SET_NATIONALTEAMCOMPETITIONS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getNationalTeamCompetitions = (data) => (dispatch) =>
  Api.nationalTeam
    .getNationalTeamCompetitions(data)
    .then((response) => {
      dispatch(setNationalTeamCompetitions(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetNationalTeamCompetitions = (data) => ({
  type: RESET_NATIONALTEAMCOMPETITIONS,
  data,
});
