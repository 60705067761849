import * as React from "react";

import HomeNewsCategories from "../../containers/homeNewsCategories";
import styles from "./critical.scss";

const HomeNewsPage = () => (
  <div className={styles.section}>
    <HomeNewsCategories />
  </div>
);

export default HomeNewsPage;
