import * as React from "react";

import NationalTeamHistory from "../../containers/nationalTeamHistory";
import styles from "./critical.scss";

const Component = ({ nationalTeamId }) => {
  return (
    <section className={styles.section}>
      <NationalTeamHistory teamId={nationalTeamId} />
    </section>
  );
};

export default Component;
