import React, { useRef } from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";
import { ReactStickyChildren } from "react-sticky-children";

import { useCompetitionContext } from "../../components/competition/context";
import ParticipantsBanner from "../../components/participantsBanner/participantsBanner";
import Scoreboard from "../../components/scoreboard";
import ShareButton from "../../components/shareButton";
import SkeletonTabs from "../../components/skeletonTabs";
import StickyParticipantsBanner from "../../components/stickyParticipantsBanner";
import { TabButton } from "../../components/tabButton";
import { Tabs } from "../../components/tabs";
import VenueBanner from "../../components/venueBanner/venueBanner";
import MatchEvents from "../matchEvents";
import MatchLiveEmbed from "../matchLiveEmbed";
import Page from "../page";
import styles from "./critical.scss";
import { ROUTES, Routes } from "./routes";

const LABELS = {
  SEPARATOR: "vs",
  LINEUP: "Lineup",
  PAST_ENCOUNTERS: "Past Encounters",
  STATISTICS: "Statistics",
  FURTHER_INFO: "Further Info",
  NEWS: "Info",
  SHARE: "Follow",
};

const getUrl = (route, matchId) => {
  return generatePath(route, { matchId });
};

export const Match = ({
  isReady,
  match,
  onGetMatch,
  onResetMatch,
  onGetMatchPage,
}) => {
  const {
    state: { themeColor1, themeColor2 },
  } = useCompetitionContext();

  const { path, url } = useRouteMatch();
  const { matchId } = useParams();
  const isMounted = useRef(null);

  React.useEffect(() => {
    isMounted.current = true;
  }, []);

  React.useEffect(() => {
    if (!isReady) {
      onGetMatch({ id: matchId });
    }
  }, [onGetMatch, matchId, isReady]);

  React.useEffect(() => () => onResetMatch(matchId), [onResetMatch, matchId]);

  const initialStyle = React.useMemo(
    () => ({
      opacity: 0,
      background: `linear-gradient(to bottom right, ${themeColor1}, ${themeColor2})`,
      visibility: "hidden",
    }),
    [themeColor1, themeColor2]
  );

  const intersectingStyle = React.useMemo(
    () => ({
      opacity: 1,
      background: `linear-gradient(to bottom right, ${themeColor1}, ${themeColor2})`,
      visibility: "visible",
    }),
    [themeColor1, themeColor2]
  );

  const getPage = React.useCallback(onGetMatchPage, [onGetMatchPage]);

  return (
    <Page url={`/match/${matchId}`} onGetPage={getPage} scrollToTop>
      {({ isReady: isPageReady }) => {
        if (!(isPageReady && isReady)) {
          return (
            <>
              <div className={styles.banner} />
              <SkeletonTabs />
            </>
          );
        }

        return (
          <>
            <section important="true">
              <MatchEvents matchId={match.id} isLive={match.isLive}>
                {({
                  homeEvents,
                  awayEvents,
                  score,
                  isReady: isScoreboardReady,
                  isError,
                  isLive,
                  isEnded,
                  matchTime,
                }) => (
                  <>
                    <VenueBanner
                      venue={match.venue}
                      formattedStartDate={match.formattedStartDate}
                      isLive={isLive}
                      isPostponed={match.isPostponed}
                      isCancelled={match.isCancelled}
                      isEnded={isEnded}
                      matchTime={matchTime}
                    >
                      {isMounted.current && (
                        <ShareButton
                          title={`${match.homeTeam.name} ${LABELS.SEPARATOR} ${match.awayTeam.name}`}
                          text={`${LABELS.SHARE} ${match.homeTeam.name} ${LABELS.SEPARATOR} ${match.awayTeam.name}. ${match.formattedStartDate}`}
                          url={`${window.location.protocol}//${
                            window.location.hostname
                          }${
                            window.location.port
                              ? `:${window.location.port}`
                              : ""
                          }${url}`}
                        />
                      )}
                      <ParticipantsBanner
                        homeTeam={match.homeTeam}
                        awayTeam={match.awayTeam}
                        homeEvents={homeEvents}
                        awayEvents={awayEvents}
                        score={score}
                        competitionName={match.competitionName}
                      >
                        {isEnded || isLive ? (
                          <Scoreboard
                            score={score}
                            isReady={isScoreboardReady}
                            isError={isError}
                            className={styles.matchScoreboard}
                            showPenalties
                            showResultSupplement
                          />
                        ) : (
                          LABELS.SEPARATOR
                        )}
                      </ParticipantsBanner>
                    </VenueBanner>
                    <ReactStickyChildren
                      initialStyle={initialStyle}
                      intersectingStyle={intersectingStyle}
                      className={styles.sticky}
                      top={48}
                    >
                      <div className={styles.stickyContent}>
                        <StickyParticipantsBanner
                          isEnded={isEnded}
                          isPostponed={match.isPostponed}
                          isCancelled={match.isCancelled}
                          matchTime={matchTime}
                          homeTeam={match.homeTeam}
                          awayTeam={match.awayTeam}
                        >
                          {isEnded || isLive ? (
                            <Scoreboard
                              score={score}
                              isReady={isScoreboardReady}
                              isError={isError}
                            />
                          ) : (
                            LABELS.SEPARATOR
                          )}
                        </StickyParticipantsBanner>
                      </div>
                    </ReactStickyChildren>
                  </>
                )}
              </MatchEvents>
              <Tabs>
                <TabButton
                  to={`${getUrl(path, match.id)}${ROUTES.LINEUP}`}
                  exact
                  replace
                >
                  {LABELS.LINEUP}
                </TabButton>
                <TabButton
                  to={`${getUrl(path, match.id)}${ROUTES.STATISTICS}`}
                  replace
                >
                  {match.isLive || match.isEnded
                    ? LABELS.STATISTICS
                    : LABELS.PAST_ENCOUNTERS}
                </TabButton>
                <TabButton
                  to={`${getUrl(path, match.id)}${ROUTES.NEWS}`}
                  replace
                >
                  {LABELS.NEWS}
                </TabButton>
              </Tabs>
              {!match.isEnded && <MatchLiveEmbed id={match.id} />}
              <Routes path={path} match={match} />
            </section>
          </>
        );
      }}
    </Page>
  );
};
