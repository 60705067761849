import Api from "../../../../service";
import { makeGetPage } from "../page/actions";
import { SET_ERROR, SET_TEAM } from "./constants";

const setTeam = (data) => ({
  type: SET_TEAM,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getTeam = (data) => (dispatch) =>
  Api.nationalTeam
    .getTeamDetails(data)
    .then((response) => {
      dispatch(setTeam(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

const getPage = (data) => (dispatch) =>
  Api.nationalTeam
    .getPage(data)
    .then((response) => {
      dispatch(setTeam(response.team));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getTeamPage = (data) => (dispatch) =>
  makeGetPage(getPage)(data)(dispatch);
