import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getHomeHighlights } from "./actions";
import Component from "./homeHighlightsSection";
import { selectHighlights, selectIsError, selectIsReady } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  highlights: selectHighlights,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHighlights: (data) => dispatch(getHomeHighlights(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
