import * as React from "react";

import styles from "./critical.scss";

const LABELS = {
  FULL_TIME: "FT",
  POSTPONED: "-",
};

const StickyParticipantsBanner = ({
  isEnded,
  isPostponed,
  isCancelled,
  matchTime,
  homeTeam,
  awayTeam,
  children,
}) => {
  return (
    <div className={styles.section}>
      <span className={styles.matchTime}>
        {isEnded ? (
          matchTime || LABELS.FULL_TIME
        ) : (
          <>
            {isPostponed || isCancelled ? (
              LABELS.POSTPONED
            ) : (
              <>{matchTime && matchTime.startsWith("-") ? "-" : matchTime}</>
            )}
          </>
        )}
      </span>
      <div className={styles.teams}>
        <div className={`${styles.team} ${styles.teamHome}`}>
          <span className={styles.teamName}>{homeTeam.name}</span>
          <img src={homeTeam.logoUrl} alt="" className={styles.teamImg} />
        </div>
        <div className={styles.teamsSeparator}>{children}</div>
        <div className={`${styles.team} ${styles.teamAway}`}>
          <img src={awayTeam.logoUrl} alt="" className={styles.teamImg} />
          <span className={styles.teamName}>{awayTeam.name}</span>
        </div>
      </div>
    </div>
  );
};

export default StickyParticipantsBanner;
