import * as React from "react";
import { Link } from "react-router-dom";

import { useCompetitionContext } from "../../components/competition/context";
import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import LeaderboardOrSquareAdvertisement from "../../components/leaderboardOrSquareAdvertisement";
import SkeletonMatchStatistics from "../../components/skeletonMatchStatistics";
import usePoll from "../../hooks/poll";
import { NATIONAL_TEAM_ID } from "../nationalTeam/constants";
import styles from "./style.scss";

const POLL_INTERVAL = 60000;

const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const PLACEHOLDER = ":competitionId";

const MATCH_URL_TEMPLATE = "/match/:matchId";
const MATCH_PLACEHOLDER = ":matchId";

const LABELS = {
  DETAILS: "Details",
  SEASON: "Season Statistics",
  PAST_ENCOUNTERS: "Past Encounters",
};

export const MatchStatistics = ({
  onGetMatchStatistics,
  isReady,
  matchId,
  homeTeam,
  awayTeam,
  isLive,
  isEnded,
  isError,
  inPlay,
  season,
  pastEncounters,
  onResetMatchStatistics,
}) => {
  const {
    state: { id: competitionId },
  } = useCompetitionContext();

  React.useEffect(() => {
    if (!isReady) {
      onGetMatchStatistics({ matchId });
    }
  }, [onGetMatchStatistics, matchId, isReady]);

  React.useEffect(
    () => () => onResetMatchStatistics({ matchId }),
    [onResetMatchStatistics, matchId]
  );

  const isNationalTeamParticipating =
    homeTeam.id === NATIONAL_TEAM_ID || awayTeam.id === NATIONAL_TEAM_ID;

  const handleGetMatchStatistics = React.useCallback(
    () => onGetMatchStatistics({ matchId }),
    [matchId, onGetMatchStatistics]
  );

  usePoll({
    callback: handleGetMatchStatistics,
    interval: isLive ? POLL_INTERVAL : 0,
  });

  if (isError) {
    return null;
  }

  if (!isReady) {
    return (
      <SkeletonMatchStatistics
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        isEnded={isEnded}
        isLive={isLive}
        competitionId={competitionId}
      />
    );
  }

  return (
    <>
      <section className={styles.section}>
        {(isEnded || isLive) && Boolean(inPlay.length) && (
          <div>
            <ul className={styles.inplayTeams}>
              <li className={`${styles.inplayTeam} ${styles.inplayTeamHome}`}>
                {isNationalTeamParticipating ? (
                  <span className={styles.inplayTeamHeading}>
                    {homeTeam.name}
                  </span>
                ) : (
                  <Link
                    to={TEAM_URL_TEMPLATE.replace(
                      TEAM_PLACEHOLDER,
                      homeTeam.id
                    ).replace(PLACEHOLDER, competitionId)}
                    className={styles.inplayTeamHeading}
                  >
                    {homeTeam.name}
                  </Link>
                )}
                <div className={styles.inplayTeamLogo}>
                  <img
                    src={homeTeam.logoUrl}
                    alt=""
                    title={homeTeam.name}
                    className={styles.inplayTeamLogoImg}
                  />
                </div>
              </li>
              <li
                className={`${styles.inplayTeam} ${styles.inplayTeamSeparator}`}
              />
              <li className={`${styles.inplayTeam} ${styles.inplayTeamAway}`}>
                <div className={styles.inplayTeamLogo}>
                  <img
                    src={awayTeam.logoUrl}
                    alt=""
                    title={awayTeam.name}
                    className={styles.inplayTeamLogoImg}
                  />
                </div>
                {isNationalTeamParticipating ? (
                  <span className={styles.inplayTeamHeading}>
                    {awayTeam.name}
                  </span>
                ) : (
                  <Link
                    to={TEAM_URL_TEMPLATE.replace(
                      TEAM_PLACEHOLDER,
                      awayTeam.id
                    ).replace(PLACEHOLDER, competitionId)}
                    className={styles.inplayTeamHeading}
                  >
                    {awayTeam.name}
                  </Link>
                )}
              </li>
            </ul>
            <ul className={styles.inplayStats}>
              {inPlay.map((n) => (
                <li key={n.title} className={styles.inplayStatsItem}>
                  <div className={styles.inplayStatsItemHeading}>
                    <span
                      className={`${styles.inplayStatsItemHeadingTitle} ${styles.inplayStatsItemHeadingTitleHome}`}
                    >
                      {n.home}
                    </span>
                    <span className={styles.inplayStatsItemHeadingTitle}>
                      {n.title}
                    </span>
                    <span
                      className={`${styles.inplayStatsItemHeadingTitle} ${styles.inplayStatsItemHeadingTitleAway}`}
                    >
                      {n.away}
                    </span>
                  </div>
                  <div className={styles.inplayStatsItemCharts}>
                    <div className={`${styles.inplayStatsItemChartsChart}`}>
                      <div
                        className={`${styles.inplayStatsItemChartsChartValue} ${styles.inplayStatsItemChartsChartValueHome}`}
                        style={{ width: `${n.homePercentage}%` }}
                      >
                        {n.homePercentage}
                      </div>
                    </div>
                    <div className={`${styles.inplayStatsItemChartsChart}`}>
                      <div
                        className={`${styles.inplayStatsItemChartsChartValue} ${styles.inplayStatsItemChartsChartValueAway}`}
                        style={{ width: `${n.awayPercentage}%` }}
                      >
                        {n.awayPercentage}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isLive && (
          <>
            {season && (
              <div>
                <CompetitionThemedHeading>
                  <h2 className={styles.seasonTitle}>{LABELS.SEASON}</h2>
                </CompetitionThemedHeading>
                <ul className={styles.seasonStats}>
                  <li
                    className={`${styles.seasonStatsItem} ${styles.seasonStatsItemHeading}`}
                  >
                    <span className={styles.seasonStatsItemTitle} />
                    <span
                      className={`${styles.seasonStatsItemValue} ${styles.seasonStatsItemValueLogo}`}
                    >
                      <img
                        src={homeTeam.logoUrl}
                        alt=""
                        className={styles.seasonStatsItemValueImg}
                      />
                    </span>
                    <span
                      className={`${styles.seasonStatsItemValue} ${styles.seasonStatsItemValueLogo}`}
                    >
                      <img
                        src={awayTeam.logoUrl}
                        alt=""
                        className={styles.seasonStatsItemValueImg}
                      />
                    </span>
                  </li>
                  {season.map((n) => (
                    <li key={n.title} className={styles.seasonStatsItem}>
                      <span className={styles.seasonStatsItemTitle}>
                        {n.title}
                      </span>
                      <span className={styles.seasonStatsItemValue}>
                        {n.home}
                      </span>
                      <span className={styles.seasonStatsItemValue}>
                        {n.away}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {pastEncounters && (
              <div>
                <CompetitionThemedHeading>
                  <h2 className={styles.pastEncountersTitle}>
                    {LABELS.PAST_ENCOUNTERS}
                  </h2>
                </CompetitionThemedHeading>
                <ul className={styles.pastEncountersStats}>
                  {pastEncounters
                    .filter((n) => n.id !== matchId)
                    .map((n) => (
                      <li key={n.id} className={styles.listItem}>
                        <Link
                          to={MATCH_URL_TEMPLATE.replace(
                            MATCH_PLACEHOLDER,
                            n.id
                          )}
                          className={styles.listItemContent}
                        >
                          <span className={styles.startDate}>
                            {n.formattedStartDate}
                          </span>
                          <div className={styles.details}>
                            <div className={styles.teams}>
                              <div
                                className={`${styles.team} ${styles.teamHome}`}
                              >
                                {n.homeTeamId === homeTeam.id ? (
                                  <>
                                    <span
                                      className={`${styles.teamName} ${styles.teamNameHome}`}
                                    >
                                      {homeTeam.name}
                                    </span>
                                    <img
                                      src={homeTeam.logoUrl}
                                      alt=""
                                      className={styles.teamImg}
                                      loading="lazy"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className={`${styles.teamName} ${styles.teamNameHome}`}
                                    >
                                      {awayTeam.name}
                                    </span>
                                    <img
                                      src={awayTeam.logoUrl}
                                      alt=""
                                      className={styles.teamImg}
                                      loading="lazy"
                                    />
                                  </>
                                )}
                              </div>
                              <div className={styles.teamsSeparator}>
                                {n.score}
                              </div>
                              <div
                                className={`${styles.team} ${styles.teamAway}`}
                              >
                                {n.awayTeamId === awayTeam.id ? (
                                  <>
                                    <img
                                      src={awayTeam.logoUrl}
                                      alt=""
                                      className={styles.teamImg}
                                      loading="lazy"
                                    />
                                    <span
                                      className={`${styles.teamName} ${styles.teamNameAway}`}
                                    >
                                      {awayTeam.name}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={homeTeam.logoUrl}
                                      alt=""
                                      className={styles.teamImg}
                                      loading="lazy"
                                    />
                                    <span
                                      className={`${styles.teamName} ${styles.teamNameAway}`}
                                    >
                                      {homeTeam.name}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <span className={styles.link}>{LABELS.DETAILS}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </>
        )}
        <LeaderboardOrSquareAdvertisement
          uid="match-statistics-1"
          competitionId={competitionId}
        />
      </section>
    </>
  );
};
