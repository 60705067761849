import Api from "../../../../service";
import { setCompetitionId } from "../app/actions";
import { makeGetPage } from "../page/actions";
import { DEFAULT_COMPETITION_ID, SET_ERROR, SET_HOME } from "./constants";

const setHome = (data) => ({
  type: SET_HOME,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

const getPage = (data) => (dispatch) =>
  Api.home
    .getHome(data)
    .then((response) => {
      dispatch(setHome(response));
      dispatch(setCompetitionId(DEFAULT_COMPETITION_ID));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const getHomePage = (data) => (dispatch) =>
  makeGetPage(getPage)(data)(dispatch);
