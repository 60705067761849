import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getNationalTeams } from "./actions";
import Component from "./nationalTeams";
import { selectIsError, selectIsReady, selectNationalTeams } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  nationalTeams: selectNationalTeams,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNationalTeams: (data) => dispatch(getNationalTeams(data)),
});

export function fetchData(store) {
  return store.dispatch(getNationalTeams());
}

const NationalTeams = connect(mapStateToProps, mapDispatchToProps)(Component);

export default NationalTeams;
