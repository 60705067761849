import Competition, { fetchData as fetchCompetition } from "../competition";
import { fetchData as fetchCompetitions } from "../competitions";
import Home, { fetchData as fetchHome } from "../home";
import { fetchData as fetchHomeNewsSection } from "../homeNewsSection";
import Match, { fetchData as fetchMatch } from "../match";
import NationalTeam, { fetchData as fetchNationalTeam } from "../nationalTeam";
import { fetchData as fetchNationalTeams } from "../nationalTeams";
import { fetchData as fetchNavigation } from "../navigation";
import News, { fetchData as fetchNews } from "../news";
import Player, { fetchData as fetchPlayer } from "../player";
import Team, { fetchData as fetchTeam } from "../team";

const Empty = () => null;

export const getRouteConfig = (name) => {
  switch (name) {
    case "home":
      return {
        component: Home,
        fetchData: [
          fetchCompetitions,
          fetchHome,
          fetchHomeNewsSection,
          fetchNavigation,
          fetchNationalTeams,
        ],
      };
    case "match":
      return {
        component: Match,
        fetchData: [fetchNavigation, fetchCompetitions, fetchMatch],
      };
    case "competition":
      return {
        component: Competition,
        fetchData: [fetchNavigation, fetchCompetitions, fetchCompetition],
      };
    case "team":
      return {
        component: Team,
        fetchData: [fetchNavigation, fetchCompetitions, fetchTeam],
      };
    case "player":
      return {
        component: Player,
        fetchData: [fetchNavigation, fetchCompetitions, fetchPlayer],
      };
    case "news":
      return {
        component: News,
        fetchData: [fetchNavigation, fetchNews],
      };
    case "nationalteam":
      return {
        component: NationalTeam,
        fetchData: [fetchNavigation, fetchNationalTeam],
      };
    default:
      return {
        component: Empty,
        fetchData: [],
      };
  }
};
