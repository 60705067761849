import { connect } from "react-redux";
import { compose } from "redux";

import { getMatchEvents } from "./actions";
import { MatchEvents as Component } from "./matchEvents";
import {
  makeSelectAwayEvents,
  makeSelectHomeEvents,
  makeSelectIsEnded,
  makeSelectIsError,
  makeSelectIsLive,
  makeSelectIsReady,
  makeSelectMatchTime,
  makeSelectScore,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectHomeEvents = makeSelectHomeEvents();
  const selectAwayEvents = makeSelectAwayEvents();
  const selectScore = makeSelectScore();
  const selectIsLive = makeSelectIsLive();
  const selectIsEnded = makeSelectIsEnded();
  const selectMatchTime = makeSelectMatchTime();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    isLive: selectIsLive(state, ownProps),
    isEnded: selectIsEnded(state, ownProps),
    matchTime: selectMatchTime(state, ownProps),
    homeEvents: selectHomeEvents(state, ownProps),
    awayEvents: selectAwayEvents(state, ownProps),
    score: selectScore(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetMatchEvents: (data) => dispatch(getMatchEvents(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
