import * as React from "react";

import NationalTeamTopScorers from "../../containers/nationalTeamTopScorers";
import styles from "./critical.scss";

const Component = ({ nationalTeamId }) => (
  <section className={styles.section}>
    <NationalTeamTopScorers nationalTeamId={nationalTeamId} />
  </section>
);

export default Component;
