/* eslint-disable css-modules/no-undef-class */
import * as React from "react";

import styles from "./critical.scss";

const TeamHistoryContent = ({ html }) => (
  <div
    className={`${styles.content} richtext}`}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

export default TeamHistoryContent;
