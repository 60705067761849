import * as React from "react";

import ResponsiveImage from "../shared/responsiveImage";
import styles from "./style.scss";

const MatchVenue = ({ venue }) => {
  if (!venue.images) {
    return null;
  }

  return (
    <section className={`${styles.section}`}>
      <div className={styles.image}>
        <ResponsiveImage
          src={venue.images.src}
          srcset={venue.images.srcset}
          alt={venue.images.alt}
          title={venue.name}
          className={styles.imageImg}
          important="true"
        />
        <span className={styles.title}>{venue.name}</span>
      </div>
    </section>
  );
};

export default MatchVenue;
