import { getFirstImportantPaint } from "first-important-paint";
import * as React from "react";
import ReactDOM from "react-dom";

import App from "./js/containers/app/index";

getFirstImportantPaint(() => {
  // send the data to SpeedCurve
  const LUX = window.LUX || {};
  if (LUX.loaded) {
    // delay sending the data
    setTimeout(() => {
      LUX.send();
    }, 2000);
  }
});

ReactDOM.hydrate(<App />, document.getElementById("root"));

if (!process.env.IS_DEVELOPMENT) {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.register("/sw.js").catch((err) => {
        console.warn("Error whilst registering service worker", err);
      });
    });
  }
}
