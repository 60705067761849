import * as React from "react";
import { ReactStickyChildren } from "react-sticky-children";

import CompetitionSelector from "../../containers/competitionSelector";
import CompetitionSponsors from "../../containers/competitionSponsors";
import ResponsiveImage from "../shared/responsiveImage";
import StickyCompetitionBanner from "../stickyCompetitionBanner";
import styles from "./critical.scss";

const LABELS = {
  COMPETITION_SELECTOR_LABEL: "Select Competition",
  COMPETITION_SELECTOR_LABEL_SHORT: "Choose",
};

const CompetitionBanner = ({
  id,
  name,
  themeColor1,
  themeColor2,
  banner,
  logoUrl,
  className,
  children,
}) => {
  const initialStyle = React.useMemo(
    () => ({
      opacity: 0,
      background: `linear-gradient(to bottom right, ${themeColor1}, ${themeColor2})`,
      visibility: "hidden",
    }),
    [themeColor1, themeColor2]
  );

  const intersectingStyle = React.useMemo(
    () => ({
      opacity: 1,
      background: `linear-gradient(to bottom right, ${themeColor1}, ${themeColor2})`,
      visibility: "visible",
    }),
    [themeColor1, themeColor2]
  );

  return (
    <>
      <section className={`${styles.section} ${className ?? ""}`}>
        <div className={styles.banner}>
          {banner ? (
            <ResponsiveImage
              src={banner.src}
              srcset={banner.srcset}
              alt={banner.alt}
              title={name}
              className={styles.bannerImage}
              important="true"
            />
          ) : (
            <div className={styles.emptyBanner} />
          )}
          <div className={styles.bannerOverlay} />
        </div>
        <div className={styles.bannerText}>
          <CompetitionSponsors competitionId={id} />
          <CompetitionSelector
            active={id}
            label={LABELS.COMPETITION_SELECTOR_LABEL}
            competitionId={id}
          />
          <div className={styles.bannerTextCompetition}>
            <span className={styles.bannerTextCompetitionName}>{name}</span>
            <div className={styles.bannerTextCompetitionLogo}>
              <img
                src={logoUrl}
                alt=""
                className={styles.bannerTextCompetitionLogoImage}
              />
            </div>
          </div>
        </div>
        {children}
      </section>
      <ReactStickyChildren
        initialStyle={initialStyle}
        intersectingStyle={intersectingStyle}
        className={styles.sticky}
        top={48}
      >
        <div className={styles.stickyContent}>
          <StickyCompetitionBanner id={id} name={name} logoUrl={logoUrl} />
        </div>
      </ReactStickyChildren>
    </>
  );
};

export default CompetitionBanner;
