import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_TEAM } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  id: null,
  name: null,
  logoUrl: null,
  images: null,
  competitions: [],
  competitionId: null,
  website: null,
  social: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM:
      return {
        ...state,
        id: action.data.id,
        name: action.data.name,
        logoUrl: action.data.logoUrl,
        images: action.data.images,
        competitions: action.data.competitions,
        competitionId: action.data.competitionId,
        website: action.data.website,
        social: action.data.social,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectTeam = createSelector(getState, (n) => ({
  id: n.id,
  name: n.name,
  logoUrl: n.logoUrl,
  images: n.images,
  competitions: n.competitions,
  competitionId: n.competitionId,
  website: n.website,
  social: n.social,
}));

reducerRegistry.register(REDUCER_NAME, reducer);
