import * as React from "react";
import { Link, generatePath, useRouteMatch } from "react-router-dom";

import CompetitionThemedBackground from "../../components/competitionThemedBackground";
import styles from "./style.scss";

const getUrl = (route, competitionId, season, teamId) => {
  return generatePath(route, { competitionId, season, teamId });
};

export const CompetitionSeasons = ({
  teamId,
  competitionId,
  onGetCompetitionSeasons,
  onResetCompetitionSeasons,
  isReady,
  seasons,
  season,
}) => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionSeasons({ id: competitionId });
    }
  }, [onGetCompetitionSeasons, competitionId, isReady]);

  React.useEffect(
    () => () => onResetCompetitionSeasons(competitionId),
    [onResetCompetitionSeasons, competitionId]
  );

  if (!isReady) {
    return <div className={styles.section} />;
  }

  return (
    <section className={styles.section}>
      <ul className={styles.list}>
        {seasons.map((n) => (
          <li className={`${styles.listItem}`} key={n.id}>
            <Link
              replace
              to={getUrl(path, competitionId, n.id, teamId)}
              className={`${styles.link} ${
                n.id === Number(season) ? styles.active : ""
              }`}
            >
              <CompetitionThemedBackground />
              {n.title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
