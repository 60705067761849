import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_COMPETITIONS, SET_ERROR } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  competitions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITIONS:
      return {
        ...state,
        isReady: true,
        isError: false,
        competitions: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectCompetitions = createSelector(
  getState,
  (n) => n.competitions
);

export const selectActiveCompetitions = createSelector(getState, (n) =>
  n.competitions.filter((m) => !m.isHidden)
);

export const selectAllCompetitions = createSelector(
  getState,
  (n) => n.competitions
);

reducerRegistry.register(REDUCER_NAME, reducer);
