import { connect } from "react-redux";

import { getNavigation } from "./actions";
import Component from "./navigation";
import {
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectNavigation,
} from "./reducer";

const SERVER_SIDE_MENU_TYPE = "mc-header";

const makeMapStateToProps = () => {
  const selectNavigation = makeSelectNavigation();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    navigation: selectNavigation(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetNavigation: (data) => dispatch(getNavigation(data)),
});

export function fetchData(store) {
  return store.dispatch(getNavigation({ type: SERVER_SIDE_MENU_TYPE }));
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
