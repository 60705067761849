/* eslint-disable no-underscore-dangle */
import "react-datepicker/dist/react-datepicker.css";

import "./critical.scss";

/*
 * Root component
 */
import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { WidgetRoutes } from "../../components/widgetRoutes";
import OfflineManager from "../../util/offlineManager";
import { configureStore } from "../../util/store";
import { Routes } from "../routes";
import { selectRoutes } from "../routes/reducer";
import { setDeferredPrompt } from "./actions";

const initOffline = () => {
  window.addEventListener(
    "online",
    () => {
      OfflineManager.setOffline(false);
    },
    false
  );

  window.addEventListener(
    "offline",
    () => {
      OfflineManager.setOffline(true);
    },
    false
  );

  if (!navigator.onLine) {
    OfflineManager.setOffline(true);
  }
};

export default class App extends React.PureComponent {
  constructor(props) {
    super(props);

    // grab the state from a global variable injected into the server-generated HTML
    const preloadedState = window.__PRELOADED_STATE__;

    // allow the passed state to be garbage-collected
    delete window.__PRELOADED_STATE__;

    this.store = configureStore(preloadedState);
  }

  componentDidMount() {
    initOffline();

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // store the event so it can be triggered later.
      this.store.dispatch(setDeferredPrompt(e));

      return false;
    });
  }

  render() {
    const routes = selectRoutes(this.store.getState());

    return (
      <Provider store={this.store}>
        <div id="app">
          <BrowserRouter>
            <Switch>
              <Route path="/widgets/*">
                <WidgetRoutes />
              </Route>
              <Route>
                <Routes routes={routes} />
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
      </Provider>
    );
  }
}
