import { format, isToday, parseISO, subHours } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { STATUS_POSTPONED } from "../match/constants";
import { REDUCER_NAME, SET_ERROR, SET_TEAMMATCHES, getKey } from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  matches: {},
  nextPage: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAMMATCHES:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        matches: {
          ...state.matches,
          [action.key]: action.data.matches,
        },
        nextPage: {
          ...state.nextPage,
          [action.key]: action.data.nextPage,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "E dd MMM HH:mm");
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectTeamMatches = (state, props) => {
  return getState(state).matches[getKey(props)];
};

export const makeSelectTeamMatches = () =>
  createSelector(
    selectTeamMatches,
    (n) =>
      n &&
      n
        .sort((a, b) =>
          // eslint-disable-next-line no-nested-ternary
          b.startDate
            ? parseISO(a.startDate) < new Date()
              ? parseISO(b.startDate) - parseISO(a.startDate)
              : parseISO(a.startDate) - parseISO(b.startDate)
            : -1
        )
        .map((m) => ({
          id: m.id,
          startDate: m.startDate,
          homeTeam: m.homeTeam,
          awayTeam: m.awayTeam,
          venue: m.venue,
          referees: m.referees,
          isLive: m.isLive,
          matchTime: m.matchTime,
          isLineupAvailable: m.isLineupAvailable,
          competitionId: m.competitionId,
          isRecent:
            parseISO(m.startDate) < new Date() &&
            parseISO(m.startDate) > subHours(new Date(), 24),
          formattedStartDate: toFormattedDate(m.startDate),
          isEnded:
            !m.isLive &&
            parseISO(m.startDate) < new Date() &&
            m.status !== STATUS_POSTPONED,
          isToday: isToday(parseISO(m.startDate)),
          isPostponed: m.status === STATUS_POSTPONED,
        }))
  );

const selectNextPage = (state, props) =>
  getState(state).nextPage[getKey(props)];

export const makeSelectNextPage = () =>
  createSelector(selectNextPage, (n) => n);

reducerRegistry.register(REDUCER_NAME, reducer);
