import * as React from "react";

import styles from "./critical.scss";

const NationalTeamSponsors = ({ sponsors }) => (
  <section className={styles.sponsors}>
    <ul className={styles.list}>
      {sponsors
        .filter((n) => n.tier === 1)
        .map((n) => (
          <li className={`${styles.listItem} ${styles.firstTier}`} key={n.id}>
            <a href={n.url} target="_blank" rel="noreferrer">
              <img
                src={n.image}
                alt={n.name}
                title={n.name}
                className={styles.listItemImg}
              />
            </a>
          </li>
        ))}
    </ul>
    <ul className={styles.list}>
      {sponsors
        .filter((n) => n.tier === 2)
        .map((n) => (
          <li className={`${styles.listItem} ${styles.secondTier}`} key={n.id}>
            <a href={n.url} target="_blank" rel="noreferrer">
              <img
                src={n.image}
                alt={n.name}
                title={n.name}
                className={styles.listItemImg}
              />
            </a>
          </li>
        ))}
    </ul>
  </section>
);

export default NationalTeamSponsors;
