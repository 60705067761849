import { format, isToday, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { toImage, toMeta, toPage, toResponsiveImage } from "../adapter";

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj) && dateObj > new Date()) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "E dd-MM-yyyy HH:mm");
};

const replacePlaceholders = ({
  input,
  homeTeamName,
  awayTeamName,
  formattedStartDate,
}) =>
  input
    .replace(/{homeTeamName}/g, homeTeamName)
    .replace(/{awayTeamName}/g, awayTeamName)
    .replace(/{formattedStartDate}/g, formattedStartDate);

export const toMatchPage = ({ page, match }) => {
  if (page) {
    return {
      ...toPage(page),
      meta: {
        ...toMeta(page.meta),
        title: replacePlaceholders({
          input: page.meta.title,
          homeTeamName: match.homeTeam.name,
          awayTeamName: match.awayTeam.name,
          formattedStartDate: toFormattedDate(match.startDate),
        }),
        description: replacePlaceholders({
          input: page.meta.description,
          homeTeamName: match.homeTeam.name,
          awayTeamName: match.awayTeam.name,
          formattedStartDate: toFormattedDate(match.startDate),
        }),
      },
      match,
    };
  }
  return null;
};

const toTeam = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        logoUrl: data.image,
      }
    : null;

const toVenue = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        images: toResponsiveImage(data.images),
      }
    : null;

const toReferees = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        role: n.role.replace(/_/g, " "),
        image: n.image,
      }))
    : [];

export const toMatch = (data) =>
  data
    ? {
        id: data.id,
        startDate: data.startDate,
        homeTeam: toTeam(data.homeTeam),
        awayTeam: toTeam(data.awayTeam),
        venue: toVenue(data.venue),
        referees: toReferees(data.referees),
        isLive: data.isLive,
        matchTime: data.matchTime,
        isLineupAvailable: data.isLineupAvailable,
        competitionName: data.competitionName,
        competitionId: data.competitionTypeId,
        status: data.status,
      }
    : null;

export const toMatchDetails = (data) => toMatch(data);

const toEvents = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        type: n.type,
        team: n.team,
        time: n.time,
      }))
    : [];

const toPlayers = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        number: n.number,
        position: n.position,
        isStartingLineup: n.startingLineup,
        profilePhotoUrl: n.profilePhoto,
        events: toEvents(n.events),
        isGoalkeeper: n.isGoalkeeper,
        isCaptain: n.isCaptain,
      }))
    : [];

const toTeamLineup = (data) =>
  data
    ? {
        startingLineup: data.players
          ? toPlayers(data.players.filter((n) => n.startingLineup))
          : [],
        substitutions: data.players
          ? toPlayers(data.players.filter((n) => !n.startingLineup))
          : [],
        coach: data.coach,
      }
    : null;

export const toLineup = (data) =>
  data
    ? {
        homeLineup: toTeamLineup(data.homeTeam),
        awayLineup: toTeamLineup(data.awayTeam),
      }
    : null;

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
        url: data.url,
      }
    : null;

export const toMatchNews = (data) =>
  data
    ? data.map((n) => ({
        ...toPage(n),
        summary: n.summary,
        tags: n.tags && n.tags.filter((m) => m),
        date: n.date,
        isFeatured: n.isFeatured,
        category: toNewsCategory(n.category),
        video: n.video,
      }))
    : [];

export const toMatchHighlights = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
        thumbnail: toImage(n.thumbnail),
        date: n.date,
        video: n.video,
      }))
    : [];

export const toPotm = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        number: data.number,
        profilePhotoUrl: data.profilePhoto,
      }
    : null;

const toInPlayStatistics = (data) =>
  data
    ? data.map((n) => ({
        title: n.title.replace(/_/g, " "),
        home: n.home,
        away: n.away,
      }))
    : [];

const toSeasonStatistics = (data) =>
  data
    ? data.map((n) => ({
        title: n.title,
        home: n.home,
        away: n.away,
      }))
    : [];

const toPastEncounters = (data) =>
  data
    ? data
        .map((n) => ({
          id: n.id,
          startDate: n.startDate,
          score: n.score,
          homeTeamId: n.homeTeamId,
          awayTeamId: n.awayTeamId,
        }))
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    : [];

export const toMatchStatistics = (data) =>
  data
    ? {
        inPlayStatistics: toInPlayStatistics(data.matchStatistics),
        seasonStatistics: toSeasonStatistics(data.seasonStatistics),
        pastEncounters: toPastEncounters(data.encounterStatistics),
      }
    : null;

export const toPoll = (data) =>
  data && data.players
    ? data.players.map((n) => ({
        id: n.id,
        title: n.name,
        value: n.vote,
      }))
    : null;

export const toPollRequest = (data) =>
  data
    ? {
        matchId: data.id,
        personId: data.option,
      }
    : null;

export const toChannel = (data) =>
  data
    ? {
        id: data.id,
        channel: data.channel,
        isYouTube: /youtu\.?be/.test(data.channel.toLowerCase()),
      }
    : null;
