import * as React from "react";
import { Route, Switch } from "react-router-dom";

import PlayerHistoryPage from "../../components/playerHistoryPage";
import PlayerHomePage from "../../components/playerHomePage";
import PlayerStatisticsPage from "../../components/playerStatisticsPage";
import { ROUTES } from "./constants";

export const Routes = React.memo(({ path, player }) => {
  return (
    <Switch>
      <Route path={`${path}${ROUTES.STATISTICS}`}>
        <PlayerStatisticsPage />
      </Route>
      <Route path={`${path}${ROUTES.HISTORY}`}>
        <PlayerHistoryPage />
      </Route>
      <Route path={`${path}${ROUTES.HOME}`} exact>
        <PlayerHomePage player={player} />
      </Route>
    </Switch>
  );
});
