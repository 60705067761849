import { toAdvertisement } from "./adapter";
import DataService from "./dataService";

export default class {
  constructor({ dataService } = {}) {
    this.dataService = dataService || new DataService();
  }

  getAdvertisement(data) {
    return this.dataService
      .getAdvertisement(data)
      .then((response) => {
        return toAdvertisement(response);
      })
      .catch(() => {
        // do nothing
      });
  }
}
