import Api from "../../../../service";
import { RESET_PLAYERHISTORY, SET_ERROR, SET_PLAYERHISTORY } from "./constants";

const setPlayerHistory = (data) => ({
  type: SET_PLAYERHISTORY,
  data,
});

export const resetPlayerHistory = (data) => ({
  type: RESET_PLAYERHISTORY,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getPlayerHistory = (data) => (dispatch) =>
  Api.player
    .getPlayerHistory(data)
    .then((response) => {
      dispatch(setPlayerHistory(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
