import * as React from "react";
import { Helmet } from "react-helmet";

import logo from "../../../img/logo.jpg";

const Meta = ({ meta }) => {
  if (!meta) {
    return null;
  }

  let ogImage = <meta property="og:image" content={logo} />;
  let imageSrc = <link rel="icon" href={logo} />;

  if (meta.thumbnail) {
    ogImage = <meta property="og:image" content={meta.thumbnail} />;
    imageSrc = <link rel="icon" href={meta.thumbnail} />;
  }

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <meta property="og:title" content={meta.title} />
      {ogImage}
      {imageSrc}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@maltafa1900" />
      <meta name="twitter:creator" content="@maltafa1900" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.thumbnail} />
    </Helmet>
  );
};

export default Meta;
