import Api from "../../../../service";
import {
  RESET_MATCHSTATISTICS,
  SET_ERROR,
  SET_MATCHSTATISTICS,
  getKey,
} from "./constants";

const setMatchStatistics = (data, key) => ({
  type: SET_MATCHSTATISTICS,
  data,
  key,
});

export const resetMatchStatistics = () => ({
  type: RESET_MATCHSTATISTICS,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getMatchStatistics = (data) => (dispatch) =>
  Api.match
    .getMatchStatistics(data)
    .then((response) => {
      dispatch(setMatchStatistics(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });
