import Api from "../../../../service";
import {
  RESET_COMPETITIONTABLE,
  SET_COMPETITIONTABLE,
  SET_ERROR,
} from "./constants";

const setCompetitionTable = (data) => ({
  type: SET_COMPETITIONTABLE,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getCompetitionTable = (data) => (dispatch) =>
  Api.competition
    .getCompetitionTable(data)
    .then((response) => {
      dispatch(setCompetitionTable(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetCompetitionTable = (data) => ({
  type: RESET_COMPETITIONTABLE,
  data,
});
