import fetch from "node-fetch";

export default class {
  getMatchNews({ id, isFeatured = false, competitionId, pageSize }) {
    return fetch(
      `${
        process.env.CMS_URL
      }/news/getmatchnews?matchId=${id}&isFeatured=${isFeatured}&competitionId=${competitionId}${
        pageSize ? `&pageSize=${pageSize}` : ""
      }`
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  getMatchHighlights({ id, isFeatured = false }) {
    return fetch(
      `${process.env.CMS_URL}/highlights?matchId=${id}&isFeatured=${isFeatured}`
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }
}
