import fetch from "node-fetch";

export default class {
  async getPage({ url }) {
    const res = await fetch(`${process.env.CMS_URL}/pages/?url=${url}`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
