import * as React from "react";

import { U14_COMPETITIONS } from "../../containers/competition/constants";
import CompetitionMatches from "../../containers/competitionMatches";
import { TYPE } from "../../containers/competitionMatches/constants";
import CompetitionNews from "../../containers/competitionNews";
import CompetitionResults from "../../containers/competitionResults";
import CompetitionTableSummary from "../../containers/competitionTableSummary";
import BannerAdvertisement from "../bannerAdvertisement";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

const PAGINATION_QUERY_STRING_PARAM_NAME = "page";

const LABELS = {
  TITLE_LIVE: "Live Events",
  TITLE_UPCOMING: "Upcoming Events",
  TITLE_PAST: "Past Results",
  TITLE_FEATURED_NEWS: "Featured News",
};

export const CompetitionHomePage = ({
  competitionId,
  isCup,
  matchPageSize,
}) => {
  return (
    <div className={styles.container}>
      <section className={`${styles.content} ${isCup ? styles.isCup : ""}`}>
        <CompetitionMatches
          title={LABELS.TITLE_LIVE}
          competitionId={competitionId}
          type={TYPE.LIVE}
          pageSize={matchPageSize}
        />
        <CompetitionNews
          title={LABELS.TITLE_FEATURED_NEWS}
          isFeatured
          competitionId={competitionId}
        />
        <CompetitionMatches
          title={LABELS.TITLE_UPCOMING}
          competitionId={competitionId}
          type={TYPE.UPCOMING}
          showPagination
          pageSize={matchPageSize}
        />
        <LeaderboardOrSquareAdvertisement
          uid="competition-home-page-1"
          competitionId={competitionId}
        />
        <CompetitionResults
          title={LABELS.TITLE_PAST}
          competitionId={competitionId}
          type={TYPE.PAST}
          listClassName={styles.results}
          showPagination
          paginationQueryStringParamName={PAGINATION_QUERY_STRING_PARAM_NAME}
          pageSize={matchPageSize}
        />
      </section>
      {!(isCup || U14_COMPETITIONS.includes(competitionId.toString())) && (
        <aside className={styles.aside}>
          <CompetitionTableSummary competitionId={competitionId} />
          <BannerAdvertisement
            uid="competition-home-page-2"
            competitionId={competitionId}
          />
        </aside>
      )}
    </div>
  );
};
