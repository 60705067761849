import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getTeam, getTeamPage } from "./actions";
import { selectIsError, selectIsReady, selectTeam } from "./reducer";
import Component from "./team";

const mapStateToProps = createStructuredSelector({
  team: selectTeam,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTeam: (data) => dispatch(getTeam(data)),
  onGetTeamPage: (data) => dispatch(getTeamPage(data)),
});

export function fetchData(store, { match }) {
  const {
    url,
    params: { teamId, competitionId },
  } = match;
  return store.dispatch(getTeamPage({ url, teamId, competitionId }));
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
