import * as React from "react";

import NationalTeamCoaches from "../../containers/nationalTeamCoaches";
import styles from "./critical.scss";

const NationalTeamCoachesPage = ({ nationalTeamId }) => {
  return (
    <section className={styles.section}>
      <NationalTeamCoaches nationalTeamId={nationalTeamId} />
    </section>
  );
};

export default NationalTeamCoachesPage;
