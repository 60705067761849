import * as React from "react";

import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import DEFAULT_COMPETITION_LOGO from "./img/default-competition.svg";
import styles from "./style.scss";

const LABELS = {
  STATISTIC: "",
  EMPTY_STATISTIC: "-",
  NO_RESULTS: "No player statistics matched your search criteria.",
};

const Component = ({
  playerId,
  onGetPlayerStatistics,
  onResetPlayerStatistics,
  isReady,
  isError,
  competitions,
  keys,
  season,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetPlayerStatistics({ id: playerId, season });
    }
  }, [onGetPlayerStatistics, playerId, season, isReady]);

  React.useEffect(
    () => () => onResetPlayerStatistics(playerId, season),
    [onResetPlayerStatistics, playerId, season]
  );

  if (isError) {
    return (
      <section>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <div className={styles.statisticsTitle}>{LABELS.STATISTIC}</div>
          </li>
        </ul>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <div className={styles.statisticsTitle}>{LABELS.STATISTIC}</div>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(4)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  return (
    <ErrorBoundary>
      <ul className={styles.list}>
        <li className={`${styles.listItem} ${styles.listItemHeading}`}>
          <span className={styles.statisticsTitle}>{LABELS.STATISTIC}</span>
          <div className={styles.statisticsData}>
            {competitions.map((n) => (
              <div
                key={n.id}
                className={`${styles.column} ${styles.competitionLogo}`}
                title={n.title}
              >
                <img
                  src={n.logoUrl || DEFAULT_COMPETITION_LOGO}
                  title={n.title}
                  alt=""
                  className={styles.competitionLogoImg}
                />
              </div>
            ))}
          </div>
        </li>
      </ul>
      <ul className={`${styles.list} ${styles.listData}`}>
        {!keys.length && (
          <li className={`${styles.listItem} ${styles.noResultsMessage}`}>
            {LABELS.NO_RESULTS}
          </li>
        )}
        {keys.map((n) => (
          <li key={n} className={`${styles.listItem}`}>
            <span className={styles.statisticsTitle}>{n}</span>
            <div className={styles.statisticsData}>
              {competitions.map((m) => (
                <div key={m.id} className={styles.column}>
                  {m.statistics[n] || LABELS.EMPTY_STATISTIC}
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </ErrorBoundary>
  );
};

export default Component;
