import * as React from "react";

import NationalTeamCompetitionTableSummary from "../../containers/nationalTeamCompetitionTable";
import NationalTeamMatches from "../../containers/nationalTeamMatches";
import { TYPE } from "../../containers/teamMatches/constants";
import TeamNews from "../../containers/teamNews";
import BannerAdvertisement from "../bannerAdvertisement";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE_LIVE: "Live Events",
  TITLE_UPCOMING: "Upcoming Events",
  TITLE_PAST: "Past Results",
  TITLE_NEWS: "Featured News",
  TITLE_STANDINGS: "Standings",
};

const PAGE_SIZE = 15;

const NationalTeamEventsPage = ({
  nationalTeamId,
  defaultCompetitionTitle,
  defaultCompetitionId,
}) => (
  <div className={styles.container}>
    <section className={styles.content}>
      <NationalTeamMatches
        title={LABELS.TITLE_LIVE}
        nationalTeamId={nationalTeamId}
        type={TYPE.LIVE}
      />
      <NationalTeamMatches
        title={LABELS.TITLE_UPCOMING}
        nationalTeamId={nationalTeamId}
        type={TYPE.UPCOMING}
        pageSize={PAGE_SIZE}
      />
      <LeaderboardOrSquareAdvertisement uid="nationalteam-home-page-1" />
      <TeamNews
        title={LABELS.TITLE_NEWS}
        isFeatured
        teamId={nationalTeamId}
        className={styles.news}
      />
      <NationalTeamMatches
        title={LABELS.TITLE_PAST}
        nationalTeamId={nationalTeamId}
        type={TYPE.PAST}
        pageSize={PAGE_SIZE}
        showPagination
      />
    </section>
    <aside className={styles.aside}>
      {Boolean(defaultCompetitionId) && (
        <NationalTeamCompetitionTableSummary
          title={defaultCompetitionTitle || LABELS.TITLE_STANDINGS}
          competitionId={defaultCompetitionId}
        />
      )}
    </aside>
    <BannerAdvertisement uid="nationalteam-home-page-aside" />
  </div>
);

export default NationalTeamEventsPage;
