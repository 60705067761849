import * as React from "react";
import { Link } from "react-router-dom";

import ErrorBoundary from "../../components/errorBoundary";
import styles from "./style.scss";

const LABELS = {
  LOAD_MORE: "See all",
};

const READ_MORE_URL = "/highlights";

const HomeHighlights = ({ title, onGetHighlights, isReady, highlights }) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetHighlights();
    }
  }, [onGetHighlights, isReady]);

  if (!(isReady || highlights || highlights.length > 0)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <h2 className={styles.title}>{title}</h2>
      <Link
        to={READ_MORE_URL}
        className={styles.readMore}
        onClick={() => window.scrollTo(0, 0)}
      >
        {LABELS.LOAD_MORE}
      </Link>
      <ErrorBoundary>
        <ul className={styles.highlights}>
          {highlights.splice(0, 1).map((n) => (
            <li key={n.id} className={styles.item}>
              <iframe
                loading="lazy"
                type="text/html"
                width="560"
                height="315"
                frameBorder="0"
                src={`https://www.youtube.com/embed/${n.id}?controls=1&modestbranding=1`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={n.title}
                className={styles.iframe}
              />
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default HomeHighlights;
