import * as React from "react";
import { NavLink } from "react-router-dom";

import styles from "./critical.scss";

export const TabButton = ({
  children,
  to,
  onClick,
  exact,
  replace = false,
}) => (
  <NavLink
    className={styles.tabButton}
    activeClassName={styles.tabButtonActive}
    onClick={onClick}
    to={to}
    exact={exact}
    replace={replace}
  >
    {children}
  </NavLink>
);
