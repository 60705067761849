export const REDUCER_NAME = "competitionMatches";
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;
export const SET_COMPETITIONMATCHES = `${REDUCER_NAME}/SET_COMPETITIONMATCHES`;
export const APPEND_COMPETITIONMATCHES = `${REDUCER_NAME}/APPEND_COMPETITIONMATCHES`;

export const TYPE = {
  LIVE: "live",
  UPCOMING: "upcoming",
  PAST: "past",
};

export const getKey = (data) =>
  `${data.competitionId}_${data.type}_${data.date ?? "current"}`;
