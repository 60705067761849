import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_TEAMPLAYERS,
  SET_ERROR,
  SET_TEAMPLAYERS,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  players: [],
};

const SORT_ORDER = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAMPLAYERS:
      return {
        ...state,
        isReady: true,
        isError: false,
        players: action.data,
      };
    case RESET_TEAMPLAYERS:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (state) => state.isReady);

export const selectIsError = createSelector(getState, (state) => state.isError);

export const selectTeamPlayers = createSelector(getState, (state) => {
  return state.players.sort((a, b) => {
    if (!a.position) {
      if (!b.position) {
        return a.name.localeCompare(b.name);
      }
      return 1;
    }
    if (!b.position) {
      return -1;
    }

    return (
      SORT_ORDER.indexOf(a.position) - SORT_ORDER.indexOf(b.position) ||
      a.name.localeCompare(b.name)
    );
  });
});

reducerRegistry.register(REDUCER_NAME, reducer);
