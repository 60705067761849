import * as React from "react";
import { useLocation } from "react-router-dom";

import ErrorBoundary from "../../components/errorBoundary";
import { NewsPagination } from "../../components/newsPagination/newsPagination";
import { NewsSection } from "../../components/newsSection/newsSection";
import styles from "./style.scss";

const LABELS = {
  LOAD_MORE: "Show more",
};

const PAGE_SIZE = 8;

export const CompetitionNews = ({
  competitionId,
  onGetCompetitionNews,
  isReady,
  news,
  isFeatured,
  title,
  showPagination,
  paginationQueryStringParamName,
  onResetCompetitionNews,
  noResults,
}) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const page = showPagination
    ? searchParams.get(paginationQueryStringParamName) || 1
    : 1;

  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionNews({
        competitionId,
        isFeatured,
        page,
        pageSize: PAGE_SIZE,
      });
    }
  }, [competitionId, page, isFeatured, onGetCompetitionNews, isReady]);

  React.useEffect(
    () => () => onResetCompetitionNews(competitionId),
    [onResetCompetitionNews, competitionId]
  );

  return (
    <ErrorBoundary>
      <NewsSection
        news={news}
        title={title}
        noResults={noResults}
        isReady={isReady}
      />
      {showPagination && (
        <NewsPagination
          className={styles.pagination}
          linkClassName={styles.paginationLink}
          label={LABELS.LOAD_MORE}
          variableName={paginationQueryStringParamName}
        />
      )}
    </ErrorBoundary>
  );
};
