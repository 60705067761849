import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  SET_ERROR,
  SET_NATIONALTEAMTOPSCORERS,
  getKey,
} from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  topScorers: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NATIONALTEAMTOPSCORERS:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        topScorers: {
          ...state.topScorers,
          [action.key]: action.data,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectNationalTeamTopScorers = (state, props) => {
  return getState(state).topScorers[getKey(props)];
};

export const makeSelectNationalTeamTopScorers = () =>
  createSelector(selectNationalTeamTopScorers, (n) => n);

reducerRegistry.register(REDUCER_NAME, reducer);
