import * as React from "react";
import Glider from "react-glider";

import ResponsiveImage from "../shared/responsiveImage";
import styles from "./critical.scss";

function Gallery({ data }) {
  return (
    <div className={styles.banner}>
      <Glider
        hasDots={data.length > 1}
        dots={`.${styles.dots}`}
        hasArrows={false}
        slidesToShow={1}
        scrollLock
        scrollLockDelay={50}
        duration={0.5}
      >
        {data.map(({ id, title, alt, images }, index) => (
          <div key={id} className={styles.content}>
            <div className={styles.image}>
              <ResponsiveImage
                src={images.src}
                srcset={images.srcset}
                alt={alt}
                title={title}
                className={styles.picture}
                lazyload={index === 0}
              />
            </div>
            {title && <span className={styles.caption}>{title}</span>}
          </div>
        ))}
      </Glider>
      <div className={styles.dots} />
    </div>
  );
}

export default Gallery;
