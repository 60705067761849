/* eslint-disable css-modules/no-undef-class */
import * as React from "react";
import Glider from "react-glider";

import Footer from "../../components/footer";
import HomeBannerSection from "../../components/homeBannerSection";
import SkeletonTabs from "../../components/skeletonTabs";
import { TabButton } from "../../components/tabButton";
import { Tabs } from "../../components/tabs";
import Page from "../page";
import { MATCH_CENTRE_HOME_PAGE_URL } from "./constants";
import styles from "./critical.scss";
import { ROUTES, Routes } from "./routes";

const LABELS = {
  TITLE_LIVE: "LIVE MATCHES",
  TITLE_NEWS: "LATEST NEWS",
  TITLE_HIGHLIGHTS: "HIGHLIGHTS",
  HOME: "HOME",
  PAST_MATCHES: "PAST MATCHES",
  HIGHLIGHTS: "HIGHLIGHTS",
  NEWS: "NEWS",
};

export const Home = ({ banners, onGetHomePage, onGetMatch }) => {
  React.useEffect(() => {
    const script = document.createElement("script");

    // eslint-disable-next-line func-names
    script.innerHTML = (function (d, s, id) {
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://embedsocial.com/cdn/ht.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    })(document, "script", "EmbedSocialHashtagScript");
    script.async = true;
  }, []);

  const getPage = React.useCallback(onGetHomePage, [onGetHomePage]);

  return (
    <Page url={MATCH_CENTRE_HOME_PAGE_URL} onGetPage={getPage} scrollToTop>
      {({ isReady: isPageReady }) => {
        if (!isPageReady) {
          return (
            <>
              <div className={styles.banner} />
              <SkeletonTabs />
            </>
          );
        }

        return (
          <>
            <section className={styles.home}>
              <div className={styles.banner}>
                {banners && (
                  <>
                    <Glider
                      hasDots={banners.length > 1}
                      hasArrows={false}
                      slidesToShow={1}
                      scrollLock
                      scrollLockDelay={50}
                      duration={0.5}
                    >
                      {banners.map(
                        ({
                          id,
                          match,
                          title,
                          images,
                          ctaText,
                          color,
                          url: bannerUrl,
                          isExternalUrl,
                          isNewWindow,
                        }) => (
                          <HomeBannerSection
                            key={(match && match.id) || id}
                            match={match}
                            title={title}
                            images={images}
                            ctaText={ctaText}
                            color={color}
                            bannerUrl={bannerUrl}
                            isExternalUrl={isExternalUrl}
                            isNewWindow={isNewWindow}
                            onGetMatch={onGetMatch}
                          />
                        )
                      )}
                    </Glider>
                  </>
                )}
              </div>
              <Tabs>
                <TabButton to={`${ROUTES.HOME}`} exact replace>
                  {LABELS.HOME}
                </TabButton>
                <TabButton to={`${ROUTES.PAST_MATCHES}`} exact replace>
                  {LABELS.PAST_MATCHES}
                </TabButton>
                <TabButton to={`${ROUTES.NEWS}`} exact replace>
                  {LABELS.NEWS}
                </TabButton>
                <TabButton to={`${ROUTES.HIGHLIGHTS}`} exact replace>
                  {LABELS.HIGHLIGHTS}
                </TabButton>
              </Tabs>
              <Routes />
              <div className={styles.instagramFeed}>
                <div
                  className="embedsocial-hashtag"
                  data-ref="ef7e1dc6ec1c2df28b49e8748fbe7dabfe33577c"
                />
              </div>
            </section>
            <Footer />
          </>
        );
      }}
    </Page>
  );
};
