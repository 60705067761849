import * as React from "react";
import { Tabs as ReactTabs, Tab, TabList, TabPanel } from "react-tabs";

import CompetitionAchievements from "../../components/competitionAchievements";
import CompetitionStandings from "../../components/competitionStandings";
import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import { Tabs } from "../../components/tabs";
import usePoll from "../../hooks/poll";
import styles from "./style.scss";

const POLL_INTERVAL = 60000;

const LABELS = {
  TITLE: "Standings",
  POS: "Pos.",
  CLUB: "Club",
  MP: "MP",
  W: "Won",
  D: "Drawn",
  L: "Lost",
  GD: "GD",
  PTS: "PTS",
  NEXT: "Next",
  PHASE: "Phase",
};

export const CompetitionTable = ({
  competitionId,
  teamId,
  season,
  onGetCompetitionTable,
  onResetCompetitionTable,
  isReady,
  isError,
  standings,
  showAchievements,
  isCurrent,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionTable({ id: competitionId, season });
    }
  }, [competitionId, season, onGetCompetitionTable, isReady]);

  React.useEffect(
    () => () => onResetCompetitionTable(competitionId, season),
    [onResetCompetitionTable, competitionId, season]
  );

  const handleGetCompetitionTable = React.useCallback(
    () => onGetCompetitionTable({ id: competitionId, season }),
    [onGetCompetitionTable, competitionId, season]
  );

  usePoll({
    callback: handleGetCompetitionTable,
    interval: isCurrent ? POLL_INTERVAL : 0,
  });

  if (isError) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnPos}`}>
              {LABELS.POS}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.MP}</span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.W}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.D}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.L}
            </span>
            <span className={styles.column}>{LABELS.GD}</span>
            <span className={styles.column}>{LABELS.PTS}</span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.NEXT}
            </span>
          </li>
        </ul>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnPos}`}>
              {LABELS.POS}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.MP}</span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.W}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.D}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.L}
            </span>
            <span className={styles.column}>{LABELS.GD}</span>
            <span className={styles.column}>{LABELS.PTS}</span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.NEXT}
            </span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(16)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!standings.length) {
    return null;
  }

  const achievement = null;
  //   !isCurrent && standings.find((n) => teamId === n.teamId && n.pos <= 3);

  return (
    <section>
      {showAchievements && Boolean(achievement) && (
        <CompetitionAchievements achievement={achievement} />
      )}
      <h2 className={styles.title}>{LABELS.TITLE}</h2>
      <ErrorBoundary>
        {standings.length === 1 &&
          standings[0].map((n) => (
            <CompetitionStandings
              key={n.id}
              teamId={teamId}
              competitionId={competitionId}
              title={n.title}
              table={n.table}
            />
          ))}
        {standings.length > 1 && (
          <ReactTabs defaultIndex={standings.length - 1}>
            <TabList>
              <Tabs>
                {standings.map((_, index) => (
                  <Tab
                    className={styles.tabButton}
                    selectedClassName={styles.tabButtonActive}
                  >{`${LABELS.PHASE} ${index + 1}`}</Tab>
                ))}
              </Tabs>
            </TabList>
            {standings.map((n) => (
              <TabPanel>
                {n
                  .sort((a, b) => a.id - b.id)
                  .map((m) => (
                    <CompetitionStandings
                      key={m.id}
                      teamId={teamId}
                      competitionId={competitionId}
                      title={m.title}
                      table={m.table}
                    />
                  ))}
              </TabPanel>
            ))}
          </ReactTabs>
        )}
      </ErrorBoundary>
    </section>
  );
};
