import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionLogo from "../../containers/competitionLogo";
import MatchEvents from "../../containers/matchEvents";
import HomeBanner from "../homeBanner";
import HomeBannerParticipants from "../homeBannerParticipants";
import Scoreboard from "../scoreboard";
import styles from "./critical.scss";

const LABELS = {
  SEPARATOR: "vs",
  MATCH_CTA: "MATCH DETAILS",
};

const URL_TEMPLATE = "/match/:matchId";
const PLACEHOLDER = ":matchId";

const HomeBannerSection = ({
  match,
  title,
  images,
  ctaText,
  color,
  bannerUrl,
  isExternalUrl,
  isNewWindow,
}) => {
  return match && Boolean(match.id) ? (
    <MatchEvents matchId={match.id} isLive={match.isLive}>
      {({ score, isReady: isScoreboardReady, isEnded, isLive, matchTime }) => (
        <HomeBanner
          hasMatch
          startDate={match.startDate}
          formattedStartDate={match.formattedStartDate}
          isLive={isLive}
          isEnded={isEnded}
          isRecent={match.isRecent}
          matchTime={matchTime}
          venue={match.venue}
          images={images}
        >
          <section className={styles.section}>
            {match && match.homeTeam && match.awayTeam && (
              <>
                <HomeBannerParticipants
                  homeTeam={match.homeTeam}
                  awayTeam={match.awayTeam}
                  isLive={isLive}
                >
                  {isEnded || isLive ? (
                    <Scoreboard
                      score={score}
                      isReady={isScoreboardReady}
                      className={styles.scoreboard}
                      showPenalties
                    />
                  ) : (
                    LABELS.SEPARATOR
                  )}
                </HomeBannerParticipants>
                <div className={styles.competitionLogo}>
                  <CompetitionLogo competitionId={match.competitionId} />
                </div>
              </>
            )}
            <>
              <h2 className={styles.bannerTitle}>{title}</h2>
              <div className={styles.cta}>
                {ctaText &&
                  bannerUrl &&
                  (isExternalUrl ? (
                    <a
                      href={bannerUrl}
                      className={styles.ctaLink}
                      style={{ color }}
                      target={isNewWindow ? "_blank" : "_self"}
                      rel="noreferrer"
                    >
                      {ctaText}
                    </a>
                  ) : (
                    <Link
                      to={bannerUrl}
                      className={styles.ctaLink}
                      style={{ color }}
                      target={isNewWindow ? "_blank" : "_self"}
                      rel={isNewWindow ? "noreferrer" : ""}
                    >
                      {ctaText}
                    </Link>
                  ))}
                {match && Boolean(match.id) && (
                  <div className={styles.cta}>
                    <Link
                      to={URL_TEMPLATE.replace(PLACEHOLDER, match.id)}
                      className={styles.ctaLink}
                    >
                      {LABELS.MATCH_CTA}
                    </Link>
                  </div>
                )}
              </div>
            </>
          </section>
        </HomeBanner>
      )}
    </MatchEvents>
  ) : (
    <HomeBanner images={images}>
      <section className={styles.section}>
        <>
          <h2 className={styles.bannerTitle}>{title}</h2>
          <div className={styles.cmsButtons}>
            {ctaText &&
              bannerUrl &&
              (isExternalUrl ? (
                <a
                  href={bannerUrl}
                  className={styles.ctaLink}
                  style={{ color }}
                  target={isNewWindow ? "_blank" : "_self"}
                  rel="noreferrer"
                >
                  {ctaText}
                </a>
              ) : (
                <Link
                  to={bannerUrl}
                  className={styles.ctaLink}
                  style={{ color }}
                  target={isNewWindow ? "_blank" : "_self"}
                  rel={isNewWindow ? "noreferrer" : ""}
                >
                  {ctaText}
                </Link>
              ))}
          </div>
        </>
      </section>
    </HomeBanner>
  );
};

export default HomeBannerSection;
