import * as React from "react";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import MatchChannelLogo from "../../components/matchChannelLogo";
import styles from "./style.scss";

const LABELS = {
  CHANNEL: "Broadcast",
  TEXT: "Watch the game LIVE on {channel}",
  YOUTUBE: "YouTube",
};

const MatchChannel = ({
  id,
  onGetMatchChannel,
  isReady,
  channel,
  date,
  isYouTube,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchChannel({ id });
    }
  }, [onGetMatchChannel, id, isReady]);

  if (!(isReady && channel)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{LABELS.CHANNEL}</h2>
      </CompetitionThemedHeading>
      <div className={styles.channel}>
        <div className={styles.text}>
          <span className={styles.line}>
            {LABELS.TEXT.replace(
              "{channel}",
              isYouTube ? LABELS.YOUTUBE : channel
            )}
          </span>
          <span className={`${styles.line} ${styles.date}`}>{date}</span>
        </div>
        <MatchChannelLogo channel={channel} doubleSize />
      </div>
    </section>
  );
};

export default MatchChannel;
