import * as React from "react";
import { Link } from "react-router-dom";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";
import styles from "./style.scss";

const PLAYER_URL_TEMPLATE = "/player/:playerId";
const PLAYER_PLACEHOLDER = ":playerId";

const LABELS = {
  TITLE: "All Time Appearances",
  NAME: "Name",
  MP: "Appearances",
  DEBUT: "Debut",
  DEBUT_DATE: "Debut Date",
  DOB: "D.O.B",
};

const Component = ({
  nationalTeamId,
  onGetNationalTeamAppearances,
  isReady,
  isError,
  table,
  className,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetNationalTeamAppearances({ nationalTeamId });
    }
  }, [nationalTeamId, onGetNationalTeamAppearances, isReady]);

  if (isError) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnName}`}>
              {LABELS.NAME}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DOB}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DEBUT}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DEBUT_DATE}
            </span>
            <span className={`${styles.column}`}>{LABELS.MP}</span>
          </li>
        </ul>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{LABELS.TITLE}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnName}`}>
              {LABELS.NAME}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DOB}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DEBUT}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DEBUT_DATE}
            </span>
            <span className={`${styles.column}`}>{LABELS.MP}</span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(16)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  return (
    <section className={className ?? ""}>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{LABELS.TITLE}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.list}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnName}`}>
              {LABELS.NAME}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DOB}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DEBUT}
            </span>
            <span className={`${styles.column} ${styles.columnDetail}`}>
              {LABELS.DEBUT_DATE}
            </span>
            <span className={`${styles.column}`}>{LABELS.MP}</span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {table.map((n) => (
            <li key={n.id} className={styles.listItem}>
              <Link
                to={PLAYER_URL_TEMPLATE.replace(PLAYER_PLACEHOLDER, n.id)}
                className={`${styles.column} ${styles.columnName}`}
              >
                <img
                  src={n.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
                  alt=""
                  className={`${styles.columnImg} ${styles.profilePhoto}`}
                  loading="lazy"
                />
                {n.name}
              </Link>
              <span className={`${styles.column} ${styles.columnDetail}`}>
                {n.dob}
              </span>
              <span className={`${styles.column} ${styles.columnDetail}`}>
                {n.debut}
              </span>
              <span className={`${styles.column} ${styles.columnDetail}`}>
                {n.debutDate}
              </span>
              <span className={`${styles.column} ${styles.columnPts}`}>
                {n.mp}
              </span>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default Component;
