import { format, isToday, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_NEWS } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  category: null,
  date: null,
  video: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS:
      return {
        ...state,
        category: action.data.category,
        date: action.data.date,
        video: action.data.video,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectCategory = createSelector(getState, (n) => n.category);

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj) && dateObj > new Date()) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(
    utcToZonedTime(dateObj, "Europe/Paris"),
    "E dd MMM yyyy, HH:mm"
  );
};

export const selectDate = createSelector(getState, (n) =>
  toFormattedDate(n.date)
);

export const selectVideo = createSelector(getState, (n) => n.video);

reducerRegistry.register(REDUCER_NAME, reducer);
