import {
  toChannel,
  toLineup,
  toMatchDetails,
  toMatchHighlights,
  toMatchNews,
  toMatchPage,
  toMatchStatistics,
  toPoll,
  toPollRequest,
  toPotm,
} from "./adapter";
import DataService from "./dataService";
import NewsService from "./newsService";
import PageService from "./pageService";

export default class {
  constructor({ pageService, dataService, newsService } = {}) {
    this.pageService = pageService || new PageService();
    this.dataService = dataService || new DataService();
    this.newsService = newsService || new NewsService();
  }

  getPage(data) {
    return this.pageService
      .getPage(data)
      .then((page) => {
        const { matchId } = page.params;
        return this.getMatchDetails({ id: matchId })
          .then((match) => toMatchPage({ page, match }))
          .catch((error) => {
            console.error(error);
            throw error;
          });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatchDetails(data) {
    return this.dataService
      .getMatchDetails(data)
      .then((response) => toMatchDetails(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getLineup({ matchId, ...data }) {
    return this.dataService
      .getLineup({ id: matchId, ...data })
      .then((response) => toLineup(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatchNews({ matchId, ...data }) {
    return this.newsService
      .getMatchNews({ id: matchId, ...data })
      .then((response) => toMatchNews(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatchHighlights(data) {
    return this.newsService
      .getMatchHighlights(data)
      .then((response) => toMatchHighlights(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatchPotm(data) {
    return this.dataService
      .getPotm(data)
      .then((response) => toPotm(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatchStatistics({ matchId, ...data }) {
    return this.dataService
      .getMatchStatistics({ id: matchId, ...data })
      .then((response) => toMatchStatistics(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatchPoll(data) {
    return this.dataService
      .getPoll(data)
      .then((response) => toPoll(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  submitMatchPoll(data) {
    return this.dataService.submitPoll(toPollRequest(data)).catch((error) => {
      console.error(error);
      throw error;
    });
  }

  getChannel(data) {
    return this.dataService
      .getChannel(data)
      .then((response) => toChannel(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
