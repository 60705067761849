import { format, isToday, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_COMPETITIONCUP,
  SET_COMPETITIONCUP,
  SET_ERROR,
  STATUS_POSTPONED,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  cup: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITIONCUP:
      return {
        ...state,
        isReady: true,
        isError: false,
        cup: action.data,
      };
    case RESET_COMPETITIONCUP:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "E dd MMM HH:mm");
};

export const selectIsReady = createSelector(getState, (state) => state.isReady);

export const selectIsError = createSelector(getState, (state) => state.isError);

export const selectCompetitionCup = createSelector(getState, (state) =>
  state.cup
    .sort((a, b) => a.gameWeek - b.gameWeek || a.order - b.order)
    .reduce((acc, n) => {
      (acc[n.gameWeek] = acc[n.gameWeek] || []).push({
        ...n,
        formattedStartDate: toFormattedDate(n.startDate),
        isEnded:
          !n.isLive &&
          parseISO(n.startDate) < new Date() &&
          n.status !== STATUS_POSTPONED,
      });
      return acc;
    }, {})
);

reducerRegistry.register(REDUCER_NAME, reducer);
