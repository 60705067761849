import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getTeam, getTeamPage } from "../team/actions";
import { selectIsError, selectIsReady, selectTeam } from "../team/reducer";
import Component from "./teamHistory";

const mapStateToProps = createStructuredSelector({
  team: selectTeam,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTeam: (data) => dispatch(getTeam(data)),
  onGetTeamPage: (data) => dispatch(getTeamPage(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
