import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  getNationalTeamCompetitions,
  resetNationalTeamCompetitions,
} from "./actions";
import Component from "./nationalTeamCompetitions";
import {
  selectIsError,
  selectIsReady,
  selectNationalTeamCompetitions,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  competitions: selectNationalTeamCompetitions,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNationalTeamCompetitions: (data) =>
    dispatch(getNationalTeamCompetitions(data)),
  onResetNationalTeamCompetitions: (data) =>
    dispatch(resetNationalTeamCompetitions(data)),
});

export function fetchData(store) {
  return store.dispatch(getNationalTeamCompetitions());
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
