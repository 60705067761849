import React, { useRef } from "react";

import {
  CompetitionContextProvider,
  defaultCompetition,
} from "../../components/competition/context";
import Header from "../../components/header";
import Meta from "../../components/meta";

export const Layout = ({
  children,
  meta,
  url,
  competitionId,
  competitions,
}) => {
  const competitionRef = useRef(defaultCompetition);
  if (Number(competitionId) !== competitionRef.current.id) {
    const competition = competitions.find(
      (n) => Number(n.id) === Number(competitionId)
    );

    if (competition) {
      competitionRef.current = {
        id: competition.id,
        name: competition.name,
        themeColor1: competition.themeColor1 || defaultCompetition.themeColor1,
        themeColor2: competition.themeColor2 || defaultCompetition.themeColor1,
        isCup: competition.themeColor1 || defaultCompetition.isCup,
        banner: competition.banner || defaultCompetition.banner,
        logoUrl: competition.logoUrl || defaultCompetition.logoUrl,
        season: competition.season,
        gameWeek: competition.gameWeek,
        matchPageSize: competition.matchPageSize,
      };
    } else {
      competitionRef.current = defaultCompetition;
    }
  }

  return (
    <>
      <Meta meta={meta} url={url} />
      <Header />
      <main id="main">
        <CompetitionContextProvider
          value={{
            state: competitionRef.current,
          }}
        >
          {children}
        </CompetitionContextProvider>
      </main>
    </>
  );
};
