import * as React from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useCompetitionContext } from "../../components/competition/context";
import SkeletonTabs from "../../components/skeletonTabs";
import { TabButton } from "../../components/tabButton";
import { Tabs } from "../../components/tabs";
import TeamBanner from "../../components/teamBanner";
import Page from "../page";
import { ROUTES } from "./constants";
import styles from "./critical.scss";
import { Routes } from "./routes";

const LABELS = {
  HOME: "Home",
  NEWS: "News",
  HIGHLIGHTS: "Highlights",
  PAST_SEASONS: "Archive",
  HISTORY: "History",
  PLAYERS: "Players",
  TABLE: "Table",
};

const getUrl = (route, competitionId, teamId) => {
  return generatePath(route, { competitionId, teamId });
};

const getArchiveUrl = (route, season) => {
  return generatePath(route, { season });
};

const Component = ({ isReady, team, onGetTeamPage }) => {
  const { path } = useRouteMatch();
  const { competitionId: routeCompetitionId, teamId } = useParams();

  const competitionId = Number(routeCompetitionId) || team.competitionId;

  const {
    state: { season: currentSeason },
  } = useCompetitionContext();

  const getPage = React.useCallback(onGetTeamPage, [onGetTeamPage]);

  const params = React.useMemo(
    () => ({ teamId, competitionId }),
    [teamId, competitionId]
  );

  return (
    <Page
      url={`/team/${teamId}`}
      params={params}
      onGetPage={getPage}
      scrollToTop
    >
      {({ isReady: isPageReady }) => {
        if (!(isPageReady && isReady)) {
          return (
            <>
              <div className={styles.banner} />
              <SkeletonTabs />
            </>
          );
        }

        return (
          <section important="true">
            <TeamBanner team={team} competitionId={competitionId} />
            <Tabs>
              <TabButton
                to={`${getUrl(path, competitionId, team.id)}${ROUTES.EVENTS}`}
                exact
                replace
              >
                {LABELS.HOME}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competitionId, team.id)}${ROUTES.TABLE}`}
                replace
              >
                {LABELS.TABLE}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competitionId, team.id)}${ROUTES.NEWS}`}
                replace
              >
                {LABELS.NEWS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competitionId, team.id)}${ROUTES.PLAYERS}`}
                replace
              >
                {LABELS.PLAYERS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competitionId, team.id)}${getArchiveUrl(
                  ROUTES.PAST_SEASONS
                )}`}
                replace
              >
                {LABELS.PAST_SEASONS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competitionId, team.id)}${ROUTES.HISTORY}`}
                replace
              >
                {LABELS.HISTORY}
              </TabButton>
            </Tabs>
            <Routes
              path={path}
              team={team}
              competitionId={competitionId}
              currentSeason={currentSeason}
            />
          </section>
        );
      }}
    </Page>
  );
};

export default Component;
