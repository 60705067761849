export const REDUCER_NAME = "competition";
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;
export const SET_COMPETITION = `${REDUCER_NAME}/SET_COMPETITION`;
export const RESET_COMPETITION = `${REDUCER_NAME}/RESET_COMPETITION`;

export const ROUTES = {
  HOME: "/",
  CLUBS: "/teams",
  CUP: "/cup",
  TABLE: "/table",
  TOP_SCORERS: "/top-scorers",
  ARCHIVE: "/archive",
  NEWS: "/articles",
};

export const U14_COMPETITIONS = ["6552815", "6552814", "22501675"];
