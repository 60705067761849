import * as React from "react";

import { NewsSection } from "../../components/newsSection/newsSection";

const PAGE_SIZE = 8;

export const MatchNews = ({
  matchId,
  competitionId,
  onGetMatchNews,
  isReady,
  news,
  isFeatured,
  title,
  noResults,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchNews({
        matchId,
        isFeatured,
        competitionId,
        pageSize: PAGE_SIZE,
      });
    }
  }, [matchId, competitionId, isFeatured, onGetMatchNews, isReady]);

  return (
    <NewsSection
      news={news}
      title={title}
      noResults={noResults}
      isReady={isReady}
    />
  );
};
