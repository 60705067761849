import Api from "../../../../service";
import { makeGetPage } from "../page/actions";
import { SET_ERROR, SET_PLAYER } from "./constants";

const setPlayer = (data) => ({
  type: SET_PLAYER,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getPlayer = (data) => (dispatch) =>
  Api.player
    .getPlayerDetails(data)
    .then((response) => {
      dispatch(setPlayer(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

const getPage = (data) => (dispatch) =>
  Api.player
    .getPage(data)
    .then((response) => {
      dispatch(setPlayer(response.player));
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getPlayerPage = (data) => (dispatch) =>
  makeGetPage(getPage)(data)(dispatch);
