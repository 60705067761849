import * as React from "react";

import TeamHistory from "../../containers/teamHistory";
import styles from "./critical.scss";

const TeamHistoryPage = ({ teamId }) => {
  return (
    <section className={styles.section}>
      <TeamHistory teamId={teamId} />
    </section>
  );
};

export default TeamHistoryPage;
