import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getMatchPoll, submitMatchPoll } from "./actions";
import { MatchPoll as Component } from "./matchPoll";
import {
  selectIsError,
  selectIsReady,
  selectSortedMatchPollOptions,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  sortedOptions: selectSortedMatchPollOptions,
});

const mapDispatchToProps = (dispatch) => ({
  onGetMatchPoll: (data) => dispatch(getMatchPoll(data)),
  onSubmitMatchPoll: (data) => dispatch(submitMatchPoll(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
