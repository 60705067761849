import { getRoutes } from "../../../../server/routes";
import { SET_ROUTES } from "./constants";

const setRoutes = (data) => {
  return {
    type: SET_ROUTES,
    data,
  };
};

export const fetchRoutes = (data) => (dispatch) =>
  getRoutes(data)
    .then((response) => {
      dispatch(setRoutes(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });
