import * as React from "react";
import { Link } from "react-router-dom";

import { useCompetitionContext } from "../../components/competition/context";
import ErrorBoundary from "../../components/errorBoundary";
import ErrorMessage from "../../components/errorMessage";
import { NATIONAL_TEAM_ID } from "../nationalTeam/constants";
import styles from "./style.scss";

const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const COMPETITION_PLACEHOLDER = ":competitionId";

const LABELS = {
  SEASON: "Season",
  TEAM: "Club",
};

const Component = ({
  playerId,
  onGetPlayerHistory,
  onResetPlayerHistory,
  isReady,
  isError,
  history,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetPlayerHistory({ id: playerId });
    }
  }, [onGetPlayerHistory, playerId, isReady]);

  React.useEffect(
    () => () => onResetPlayerHistory(playerId),
    [onResetPlayerHistory, playerId]
  );

  const {
    state: { id: competitionId },
  } = useCompetitionContext();

  if (isError) {
    return (
      <section>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnSeason}`}>
              {LABELS.SEASON}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.TEAM}
            </span>
          </li>
        </ul>
        <ErrorMessage />
      </section>
    );
  }

  if (!isReady) {
    return (
      <section>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnSeason}`}>
              {LABELS.SEASON}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.TEAM}
            </span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(4)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  return (
    <section>
      <ul className={styles.list}>
        <li className={`${styles.listItem} ${styles.listItemHeading}`}>
          <span className={`${styles.column} ${styles.columnSeason}`}>
            {LABELS.SEASON}
          </span>
          <span className={`${styles.column} ${styles.columnTeam}`}>
            {LABELS.TEAM}
          </span>
        </li>
      </ul>
      <ErrorBoundary>
        <ul className={`${styles.list} ${styles.listData}`}>
          {history.map((n) => (
            <li key={`${n.season}_${n.id}`} className={`${styles.listItem}`}>
              <span className={`${styles.column} ${styles.columnSeason}`}>
                {n.season}
              </span>
              <div className={`${styles.column} ${styles.columnTeam}`}>
                <img
                  src={n.teamLogoUrl}
                  alt=""
                  className={styles.teamLogoImg}
                />
              </div>
              {n.id === NATIONAL_TEAM_ID ? (
                <span className={`${styles.column} ${styles.columnTeamName}`}>
                  {n.name}
                </span>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(TEAM_PLACEHOLDER, n.id).replace(
                    COMPETITION_PLACEHOLDER,
                    competitionId
                  )}
                  className={`${styles.column} ${styles.columnTeamName}`}
                >
                  {n.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default Component;
