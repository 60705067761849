import { toMeta, toPage } from "../adapter";

const STATISTICS_SORT_ORDER = [
  "MATCHES PLAYED",
  "GOAL",
  "RED",
  "YELLOW",
  "OWN_GOAL",
  "PENALTY",
  "PENALTY_FAILED_SAVE",
  "PENALTY_FAILED_MISS",
  "SUBSTITUTION",
  "ACCUMULATED_FOUL",
  "EXPULSION",
  "TEN_METERS",
  "SIX_METERS",
  "TIME_OUT",
];

const replacePlaceholders = ({ input, name }) =>
  input.replace(/{playerName}/g, name);

export const toPlayerPage = ({ page, player }) => {
  if (page) {
    return {
      ...toPage(page),
      meta: {
        ...toMeta(page.meta),
        title: replacePlaceholders({
          input: page.meta.title,
          name: player.name,
        }),
        description: replacePlaceholders({
          input: page.meta.description,
          name: player.name,
        }),
        thumbnail: player.profilePhotoUrl,
      },
      player,
    };
  }
  return null;
};

const toPlayerOverview = (data) => {
  if (data && data.length) {
    return data
      .filter((n) => !n.title.includes("FIFA"))
      .map((n) => ({
        id: n.id,
        title: n.title
          .replace("International", "")
          .replace(/[a-z][A-Z]{1,1}/g, (m) => `${m[0]} ${m[1]}`)
          .replace(/_/g, " "),
        value: n.value,
      }));
  }
  return [];
};

const toPlayerTeam = (data) => ({
  id: data.id,
  name: data.name,
  number: data.number,
  position: data.position,
  logoUrl: data.image,
});

const toPlayerName = (data) =>
  data
    ? data
        .filter(
          (n) =>
            n.title === "InternationalFirstName" ||
            n.title === "InternationalLastName"
        )
        .map((n) => n.value)
        .join(" ")
    : "";

export const toPlayer = (data) => ({
  id: data.id,
  name: toPlayerName(data.details) || data.playerName,
  position: data.playerPosition,
  team: toPlayerTeam(data.team),
  profilePhotoUrl: data.profilePhoto,
  overview: toPlayerOverview(data.details),
});

const toCompetitionStatistics = (data) => {
  if (data) {
    return data
      .filter((n) => STATISTICS_SORT_ORDER.indexOf(n.title) !== -1)
      .sort(
        (a, b) =>
          STATISTICS_SORT_ORDER.indexOf(a.title) -
          STATISTICS_SORT_ORDER.indexOf(b.title)
      )
      .reduce(
        (acc, n) => ({
          ...acc,
          [n.title.replace(/_/g, " ")]: n.value,
        }),
        {}
      );
  }

  return null;
};

export const toPlayerStatistics = (data) => {
  if (data) {
    return data
      .filter((n) => n.statistics.length)
      .map((n) => ({
        id: n.id,
        title: n.title.replace(/_/g, " "),
        logoUrl: n.image,
        statistics: toCompetitionStatistics(n.statistics),
      }));
  }

  return [];
};

export const toSeasons = (data) =>
  data
    ? data
        .map((n) => ({
          id: n.id,
          title: n.title,
        }))
        .sort((a, b) => a.id - b.id)
    : null;

export const toPlayerHistory = (data) => {
  if (data) {
    return data
      .filter(
        (n, pos, self) =>
          self.findIndex(
            (m) => m.season === n.season && m.team.id === n.team.id
          ) === pos
      )
      .sort((a, b) => b.season - a.season)
      .map((n) => ({
        season: n.season,
        id: n.team.id,
        name: n.team.name,
        teamLogoUrl: n.team.logoUrl,
      }));
  }
  return [];
};
