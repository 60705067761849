import * as React from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import PlayerBanner from "../../components/playerBanner";
import SkeletonTabs from "../../components/skeletonTabs";
import { TabButton } from "../../components/tabButton";
import { Tabs } from "../../components/tabs";
import Page from "../page";
import { ROUTES } from "./constants";
import styles from "./critical.scss";
import { Routes } from "./routes";

const LABELS = {
  HOME: "Home",
  STATISTICS: "Statistics",
  HISTORY: "History",
};

const getUrl = (route, playerId) => {
  return generatePath(route, { playerId });
};

const getStatisticsUrl = (route, season) => {
  return generatePath(route, { season });
};

const Component = ({ isReady, player, onGetPlayerPage }) => {
  const { path } = useRouteMatch();
  const { playerId } = useParams();

  const getPage = React.useCallback(onGetPlayerPage, [onGetPlayerPage]);

  return (
    <Page url={`/player/${playerId}`} onGetPage={getPage} scrollToTop>
      {({ isReady: isPageReady }) => {
        if (!(isPageReady && isReady)) {
          return (
            <>
              <div className={styles.banner} />
              <SkeletonTabs />
            </>
          );
        }

        return (
          <section important="true">
            <PlayerBanner player={player} />
            <Tabs>
              <TabButton
                to={`${getUrl(path, player.id)}${ROUTES.HOME}`}
                exact
                replace
              >
                {LABELS.HOME}
              </TabButton>
              <TabButton
                to={`${getUrl(path, player.id)}${getStatisticsUrl(
                  ROUTES.STATISTICS
                )}`}
                replace
              >
                {LABELS.STATISTICS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, player.id)}${ROUTES.HISTORY}`}
                replace
              >
                {LABELS.HISTORY}
              </TabButton>
            </Tabs>
            <Routes path={path} player={player} />
          </section>
        );
      }}
    </Page>
  );
};

export default Component;
