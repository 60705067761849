import * as React from "react";

import TeamCompetitionSelector from "../../containers/teamCompetitionSelector";
import TeamSponsors from "../../containers/teamSponsors";
import { useCompetitionContext } from "../competition/context";
import FavoriteTeam from "../favoriteTeam";
import ResponsiveImage from "../shared/responsiveImage";
import styles from "./critical.scss";

const LABELS = {
  WEBSITE: "Official Website",
  COMPETITION_SELECTOR_LABEL: "Select Competition",
};

const TeamBanner = ({ team, className, competitionId }) => {
  const {
    state: { name: competitionName, banner },
  } = useCompetitionContext();

  return (
    <section className={`${styles.section} ${className ?? ""}`}>
      <div className={styles.image}>
        {team && team.images ? (
          <ResponsiveImage
            src={team.images.src}
            srcset={team.images.srcset}
            alt={team.images.alt}
            title={team.name}
            className={styles.imageImg}
            important="true"
          />
        ) : (
          <ResponsiveImage
            src={banner.src}
            srcset={banner.srcset}
            alt={banner.alt}
            title={competitionName}
            className={styles.imageImg}
            important="true"
          />
        )}
      </div>
      <div className={styles.text}>
        <FavoriteTeam teamId={team.id} />
        <TeamSponsors teamId={team.id} />
        <TeamCompetitionSelector
          active={competitionId}
          team={team.id}
          label={LABELS.COMPETITION_SELECTOR_LABEL}
          options={team.competitions}
        />
        <span className={styles.textName}>{team.name}</span>
        <div className={styles.logo}>
          <img src={team.logoUrl} alt={team.name} className={styles.logoImg} />
        </div>
        <div className={styles.footer}>
          {team.website && (
            <div className={styles.website}>
              <a
                href={team.website}
                title={team.name}
                target="_blank"
                rel="noreferrer"
                className={styles.websiteLink}
              >
                {team.website.replace(/^https?:\/\//, "")}
              </a>
            </div>
          )}
          {team.social && Boolean(team.social.length) && (
            <ul className={styles.social}>
              {team.social.map((n) => (
                <li key={n.id} className={styles.socialItem}>
                  <a
                    href={n.url}
                    title={n.platform.toLowerCase()}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.socialLink}
                  >
                    {n.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default TeamBanner;
