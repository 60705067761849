import fetch from "node-fetch";

export default class {
  async getHomeNews({ pageSize }) {
    const res = await fetch(
      `${process.env.CMS_URL}/news/getNews?${
        pageSize ? `pageSize=${pageSize}` : ""
      }`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getHomeNewsByCategory({ categoryId, page, pageSize }) {
    return fetch(
      `${process.env.CMS_URL}/news/getNewsByCategory?categoryId=${categoryId}${
        page ? `&page=${page}` : ""
      }${pageSize ? `&pageSize=${pageSize}` : ""}`
    );
  }

  async getHomeNewsCategories() {
    const res = await fetch(`${process.env.CMS_URL}/news/getCategories`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
