import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getMatch } from "../match/actions";
import { getHomePage } from "./actions";
import { MATCH_CENTRE_HOME_PAGE_URL } from "./constants";
import { Home as Component } from "./home";
import { selectBanners } from "./reducer";

const mapStateToProps = createStructuredSelector({
  banners: selectBanners,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHomePage: (data) => dispatch(getHomePage(data)),
  onGetMatch: (data) => dispatch(getMatch(data)),
});

export function fetchData(store) {
  return store.dispatch(getHomePage({ url: MATCH_CENTRE_HOME_PAGE_URL }));
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
