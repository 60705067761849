import { format, isToday, parseISO, subHours } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_HOME } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  banners: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME:
      return {
        ...state,
        banners: action.data.banners,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "E dd-MM-yyyy HH:mm");
};

const toMatch = (n) => ({
  id: n.id,
  startDate: n.startDate,
  homeTeam: n.homeTeam,
  awayTeam: n.awayTeam,
  isLive: n.isLive,
  venue: n.venue?.name,
  matchTime: n.matchTime,
  competitionId: n.competitionId,
  isRecent:
    parseISO(n.startDate) < new Date() &&
    parseISO(n.startDate) > subHours(new Date(), 24),
  formattedStartDate: toFormattedDate(n.startDate),
  isEnded: !n.isLive && parseISO(n.startDate) < new Date(),
  isToday: isToday(parseISO(n.startDate)),
});

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectBanners = createSelector(getState, (n) =>
  n.banners
    ? n.banners
        .filter((m) => Boolean(m))
        .map((m) => ({
          id: m.id,
          match: m.match ? toMatch(m.match) : {},
          images: m.images,
          ctaText: m.ctaText,
          url: m.url,
          color: m.color,
          title: m.title,
          isExternalUrl: m.isExternalUrl,
          isNewWindow: m.isNewWindow,
        }))
    : []
);

reducerRegistry.register(REDUCER_NAME, reducer);
