import { toPlayers, toSponsors, toTeamDetails, toTeamNews } from "./adapter";
import DataService from "./dataService";
import NewsService from "./newsService";
import PageService from "./pageService";

export default class {
  constructor({ pageService, dataService, newsService } = {}) {
    this.pageService = pageService || new PageService();
    this.dataService = dataService || new DataService();
    this.newsService = newsService || new NewsService();
  }

  getPage(data) {
    return this.pageService
      .getPage(data)
      .then((page) => {
        const { teamId } = page.params;
        return this.getTeamDetails({ id: Number(teamId) });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getTeamDetails(data) {
    return this.dataService
      .getTeamDetails(data)
      .then((response) => toTeamDetails(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getTeamSponsors(data) {
    return this.dataService
      .getSponsors(data)
      .then((response) => toSponsors(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getTeamNews({ teamId, ...data }) {
    return this.newsService
      .getTeamNews({ id: teamId, ...data })
      .then((response) => toTeamNews(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getPlayers({ teamId, ...data }) {
    return this.dataService
      .getPlayers({ id: teamId, ...data })
      .then((response) => toPlayers(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
