import * as React from "react";

import usePoll from "../../hooks/poll";

const POLL_INTERVAL = 60000;
const POLL_INTERVAL_LIVE = 12000;

export const MatchEvents = ({
  onGetMatchEvents,
  homeEvents,
  awayEvents,
  matchId,
  children,
  isLive,
  isEnded,
  matchTime,
  isReady,
  score,
  isError,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetMatchEvents({ matchId });
    }
  }, [onGetMatchEvents, matchId, isReady]);

  const handleGetMatchEvents = React.useCallback(
    () => onGetMatchEvents({ matchId }),
    [onGetMatchEvents, matchId]
  );

  usePoll({
    callback: handleGetMatchEvents,
    interval: isLive ? POLL_INTERVAL_LIVE : POLL_INTERVAL,
  });

  return (
    <>
      {children({
        homeEvents,
        awayEvents,
        isReady,
        score,
        isError,
        isLive,
        matchTime,
        isEnded,
      })}
    </>
  );
};
