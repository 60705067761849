import { connect } from "react-redux";
import { compose } from "redux";

import { getTeamNews, resetTeamNews } from "./actions";
import {
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectNews,
} from "./reducer";
import { TeamNews as Component } from "./teamNews";

const makeMapStateToProps = () => {
  const selectNews = makeSelectNews();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    news: selectNews(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetTeamNews: (data) => dispatch(getTeamNews(data)),
  onResetTeamNews: (data) => dispatch(resetTeamNews(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
