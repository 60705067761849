import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getTeam, getTeamPage } from "../nationalTeam/actions";
import {
  selectIsError,
  selectIsReady,
  selectTeam,
} from "../nationalTeam/reducer";
import Component from "./nationalTeamHistory";

const mapStateToProps = createStructuredSelector({
  team: selectTeam,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTeam: (data) => dispatch(getTeam(data)),
  onGetTeamPage: (data) => dispatch(getTeamPage(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
