import * as React from "react";

import ResponsiveImage from "../shared/responsiveImage";

const Thumbnail = ({ image, className, ...props }) => {
  if (image) {
    return (
      <div className={`${className ?? ""}`}>
        <ResponsiveImage
          src={image.src}
          alt={image.alt}
          title={image.title}
          srcset={image.srcset}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          className={`${className ? `${className}__img` : ""}`}
        />
      </div>
    );
  }
  return null;
};

export default Thumbnail;
