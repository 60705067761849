import Api from "../../../../service";
import {
  RESET_MATCHLINEUP,
  SET_ERROR,
  SET_MATCHLINEUP,
  getKey,
} from "./constants";

const setMatchLineup = (data, key) => ({
  type: SET_MATCHLINEUP,
  data,
  key,
});

export const resetMatchLineup = () => ({
  type: RESET_MATCHLINEUP,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getMatchLineup = (data) => (dispatch) =>
  Api.match
    .getLineup(data)
    .then((response) => {
      if (response) {
        dispatch(setMatchLineup(response, getKey(data)));
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });
