import * as React from "react";
import { Route, Switch } from "react-router-dom";

import TeamEventsPage from "../../components/teamEventsPage";
import TeamHistoryPage from "../../components/teamHistoryPage";
import TeamNewsPage from "../../components/teamNewsPage";
import TeamPastSeasonsPage from "../../components/teamPastSeasons";
import TeamPlayersPage from "../../components/teamPlayersPage";
import TeamTablePage from "../../components/teamTablePage";
import { ROUTES } from "./constants";

export const Routes = React.memo(
  ({ path, team, competitionId, currentSeason, isCup }) => {
    return (
      <Switch>
        <Route path={`${path}${ROUTES.TABLE}`}>
          <TeamTablePage teamId={team.id} competitionId={competitionId} />
        </Route>
        <Route path={`${path}${ROUTES.NEWS}`}>
          <TeamNewsPage teamId={team.id} />
        </Route>
        <Route path={`${path}${ROUTES.PLAYERS}`}>
          <TeamPlayersPage teamId={team.id} competitionId={competitionId} />
        </Route>
        <Route path={`${path}${ROUTES.PAST_SEASONS}`}>
          <TeamPastSeasonsPage
            teamId={team.id}
            competitionId={competitionId}
            currentSeason={currentSeason}
            isCup={isCup}
          />
        </Route>
        <Route path={`${path}${ROUTES.HISTORY}`}>
          <TeamHistoryPage teamId={team.id} competitionId={competitionId} />
        </Route>
        <Route path={`${path}${ROUTES.EVENTS}`} exact>
          <TeamEventsPage teamId={team.id} competitionId={competitionId} />
        </Route>
      </Switch>
    );
  }
);
