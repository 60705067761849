import Api from "../../../../service";
import { SET_COMPETITIONTOPSCORERS, SET_ERROR, getKey } from "./constants";

const setCompetitionTopScorers = (data, key) => ({
  type: SET_COMPETITIONTOPSCORERS,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getCompetitionTopScorers = (data) => (dispatch) =>
  Api.competition
    .getCompetitionTopScorers(data)
    .then((response) => {
      dispatch(setCompetitionTopScorers(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });
