import * as React from "react";

const Competitions = ({
  competitions,
  onGetCompetitions,
  isReady,
  isError,
  children,
}) => {
  React.useEffect(() => {
    if (!(competitions && competitions.length)) {
      onGetCompetitions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGetCompetitions]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!(competitions || competitions.length)) {
    return null;
  }

  return children(competitions);
};

export default Competitions;
