import * as React from "react";

import { U14_COMPETITIONS } from "../../containers/competition/constants";
import CompetitionTable from "../../containers/competitionTable";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

export const CompetitionTablePage = ({ competitionId, season }) => {
  return (
    <section className={styles.section}>
      {U14_COMPETITIONS.includes(competitionId.toString()) ? (
        <span className={styles.disclaimer}>
          The U14 leagues are development competitions in which no official
          classification is recorded.
        </span>
      ) : (
        <CompetitionTable
          competitionId={competitionId}
          isCurrent
          season={season}
        />
      )}
      <LeaderboardOrSquareAdvertisement
        uid="competition-table-page-1"
        competitionId={competitionId}
      />
    </section>
  );
};
