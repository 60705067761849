import * as React from "react";
import { Link } from "react-router-dom";

import { useCompetitionContext } from "../competition/context";
import styles from "./critical.scss";

const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const COMPETITION_PLACEHOLDER = ":competitionId";

const PlayerTeam = ({ team }) => {
  const {
    state: { id: competitionId },
  } = useCompetitionContext();

  if (!team) {
    return null;
  }

  return (
    <section className={`${styles.section}`}>
      <Link
        to={TEAM_URL_TEMPLATE.replace(TEAM_PLACEHOLDER, team.id).replace(
          COMPETITION_PLACEHOLDER,
          competitionId
        )}
        className={styles.teamLink}
      >
        <img src={team.logoUrl} alt="" className={styles.teamLogoImg} />
        <div className={styles.teamInfo}>
          <span className={styles.teamName}>{team.name}</span>
          {team.debut && (
            <span className={styles.teamDebut}>Debut match: {team.debut}</span>
          )}
        </div>
      </Link>
    </section>
  );
};

export default PlayerTeam;
