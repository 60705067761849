import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitionClubs, resetCompetitionClubs } from "./actions";
import { CompetitionClubs as Component } from "./competitionClubs";
import {
  selectCompetitionClubs,
  selectIsError,
  selectIsReady,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  clubs: selectCompetitionClubs,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionClubs: (data) => dispatch(getCompetitionClubs(data)),
  onResetCompetitionClubs: (data) => dispatch(resetCompetitionClubs(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
