import * as React from "react";
import { Link } from "react-router-dom";

import { NATIONAL_TEAM_ID } from "../../containers/nationalTeam/constants";
import { useCompetitionContext } from "../competition/context";
import styles from "./critical.scss";

const URL_TEMPLATE = "/competition/:competitionId";
const PLACEHOLDER = ":competitionId";
const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";

const ParticipantsBanner = ({
  className,
  homeTeam,
  awayTeam,
  homeEvents,
  awayEvents,
  children,
  competitionName,
}) => {
  const {
    state: { id: competitionId, logoUrl: competitionLogoUrl },
  } = useCompetitionContext();

  const isNationalTeamParticipating =
    homeTeam.id === NATIONAL_TEAM_ID || awayTeam.id === NATIONAL_TEAM_ID;

  return (
    <section className={`${styles.section} ${className ?? ""}`}>
      <div className={styles.teams}>
        {isNationalTeamParticipating ? (
          <span className={styles.team}>
            <img src={homeTeam.logoUrl} alt="" className={styles.teamImg} />
            <span className={styles.teamName}>{homeTeam.name}</span>
          </span>
        ) : (
          <Link
            to={TEAM_URL_TEMPLATE.replace(
              TEAM_PLACEHOLDER,
              homeTeam.id
            ).replace(PLACEHOLDER, competitionId)}
            className={styles.team}
          >
            <img src={homeTeam.logoUrl} alt="" className={styles.teamImg} />
            <span className={styles.teamName}>{homeTeam.name}</span>
          </Link>
        )}
        <div className={styles.teamsSeparator}>{children}</div>
        {isNationalTeamParticipating ? (
          <span className={styles.team}>
            <img src={awayTeam.logoUrl} alt="" className={styles.teamImg} />
            <span className={styles.teamName}>{awayTeam.name}</span>
          </span>
        ) : (
          <Link
            to={TEAM_URL_TEMPLATE.replace(
              TEAM_PLACEHOLDER,
              awayTeam.id
            ).replace(PLACEHOLDER, competitionId)}
            className={styles.team}
          >
            <img src={awayTeam.logoUrl} alt="" className={styles.teamImg} />
            <span className={styles.teamName}>{awayTeam.name}</span>
          </Link>
        )}
      </div>
      {competitionLogoUrl ? (
        <>
          {isNationalTeamParticipating ? (
            <div className={styles.competitionLogo}>
              <img
                src={competitionLogoUrl}
                alt=""
                className={styles.competitionLogoImg}
              />
            </div>
          ) : (
            <Link
              to={URL_TEMPLATE.replace(PLACEHOLDER, competitionId)}
              className={styles.competitionLogo}
            >
              <img
                src={competitionLogoUrl}
                alt=""
                className={styles.competitionLogoImg}
              />
            </Link>
          )}
        </>
      ) : (
        <div className={styles.competitionLogo}>
          <div className={styles.competitionLogoImg} />
        </div>
      )}
      {competitionName && (
        <span className={styles.competitionName}>{competitionName}</span>
      )}
      <section className={styles.matchEvents}>
        <ul className={`${styles.matchEventsList}`}>
          {homeEvents.map((n) => (
            <li
              key={n.id}
              className={`${styles.matchEventsListItem} ${styles.matchEventsListItemHome}`}
              title={n.type}
              data-type={n.type}
            >
              <span>{n.value}</span>{" "}
              <span className={styles.matchEventsListItemTime}>{n.time}</span>
            </li>
          ))}
        </ul>
        <ul className={`${styles.matchEventsList}`}>
          {awayEvents.map((n) => (
            <li
              key={n.id}
              className={`${styles.matchEventsListItem} ${styles.matchEventsListItemAway}`}
              title={n.type}
              data-type={n.type}
            >
              <span className={styles.matchEventsListItemTime}>{n.time}</span>{" "}
              <span>{n.value}</span>
            </li>
          ))}
        </ul>
      </section>
    </section>
  );
};

export default ParticipantsBanner;
