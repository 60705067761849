import * as React from "react";

const NationalTeamSelector = ({
  onGetNationalTeams,
  isReady,
  isError,
  nationalTeams,
  children,
}) => {
  React.useEffect(() => {
    if (!(nationalTeams && nationalTeams.length)) {
      onGetNationalTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGetNationalTeams]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!(nationalTeams || nationalTeams.length)) {
    return null;
  }

  return children(nationalTeams);
};

export default NationalTeamSelector;
