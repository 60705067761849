import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getMatch, getMatchPage, resetMatch } from "./actions";
import { Match as Component } from "./match";
import { selectIsError, selectIsReady, selectMatch } from "./reducer";

const mapStateToProps = createStructuredSelector({
  match: selectMatch,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetMatch: (data) => dispatch(getMatch(data)),
  onResetMatch: (data) => dispatch(resetMatch(data)),
  onGetMatchPage: (data) => dispatch(getMatchPage(data)),
});

export function fetchData(store, { match: { url } }) {
  return store.dispatch(getMatchPage({ url }));
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
