export const REDUCER_NAME = "teamMatches";
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;
export const SET_TEAMMATCHES = `${REDUCER_NAME}/SET_TEAMMATCHES`;

export const TYPE = {
  LIVE: "live",
  UPCOMING: "upcoming",
  PAST: "past",
};

export const getKey = (data) =>
  `${data.teamId}_${data.competitionId}_${data.type}_${
    data.season || "current"
  }`;
