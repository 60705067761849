import fetch from "node-fetch";

export default class {
  getMatchDetails({ id }) {
    return fetch(`${process.env.API_URL}/matches/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  getLineup({ id }) {
    return fetch(`${process.env.API_URL}/matches/${id}/lineup`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  getPotm({ id }) {
    return fetch(`${process.env.API_URL}/polls/${id}/GetPlayerOfTheMatch`)
      .then((res) => {
        if (res.ok) {
          if (res.status !== 204) {
            return res.json();
          }
          return null;
        }

        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  getMatchStatistics({ id }) {
    return fetch(`${process.env.API_URL}/matches/${id}/statistics`)
      .then((res) => {
        if (res.ok) {
          if (res.status !== 204) {
            return res.json();
          }
          return null;
        }

        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  getPoll({ id }) {
    return fetch(`${process.env.API_URL}/polls/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  submitPoll(data) {
    return fetch(`${process.env.API_URL}/polls`, {
      method: "post",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }

  getChannel({ id }) {
    return fetch(`${process.env.API_URL}/matches/${id}/channel`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .catch((e) => console.error(e));
  }
}
