import * as React from "react";

import TeamNews from "../../containers/teamNews";
import styles from "./critical.scss";

const LABELS = {
  TITLE_FEATURED: "Featured News",
  TITLE: "Latest News",
};

const Component = ({ nationalTeamId }) => (
  <section className={styles.section}>
    <TeamNews
      title={LABELS.TITLE_FEATURED}
      isFeatured
      teamId={nationalTeamId}
    />
    <TeamNews
      title={LABELS.TITLE}
      teamId={nationalTeamId}
      showPagination
      paginationQueryStringParamName="page"
    />
  </section>
);

export default Component;
