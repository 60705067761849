import en from "./en.json";

export default class {
  get() {
    return Promise.resolve({
      lang: "en",
      routes: en,
    });
  }
}
