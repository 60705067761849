import fetch from "node-fetch";

export default class {
  async getSocialLinks() {
    const res = await fetch(`${process.env.CMS_URL}/socialLinks/`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
