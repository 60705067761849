import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getHomeNewsCategories } from "./actions";
import { HomeNewsCategories as Component } from "./homeNewsCategories";
import { selectIsError, selectIsReady, selectNewsCategories } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  newsCategories: selectNewsCategories,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHomeNewsCategories: (data) => dispatch(getHomeNewsCategories(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
