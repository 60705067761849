import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_MATCHSTATISTICS,
  SET_ERROR,
  SET_MATCHSTATISTICS,
  getKey,
} from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  inPlay: {},
  pastEncounters: {},
  season: {},
};

const SORT_ORDER = [
  "GOAL",
  "OWN GOAL",
  "PENALTY",
  "PENALTY FAILED SAVE",
  "PENALTY FAILED MISS",
  "RED",
  "YELLOW",
  "SUBSTITUTION",
];

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHSTATISTICS:
      if (action.data) {
        return {
          ...state,
          isReady: {
            ...state.isReady,
            [action.key]: true,
          },
          isError: {
            ...state.isError,
            [action.key]: false,
          },
          inPlay: {
            ...state.inPlay,
            [action.key]: action.data.inPlayStatistics,
          },
          pastEncounters: {
            ...state.pastEncounters,
            [action.key]: action.data.pastEncounters,
          },
          season: {
            ...state.season,
            [action.key]: action.data.seasonStatistics,
          },
        };
      }

      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
      };
    case RESET_MATCHSTATISTICS:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectInPlayStatistics = (state, props) => {
  return getState(state).inPlay[getKey(props)];
};

export const makeSelectInPlayStatistics = () =>
  createSelector(selectInPlayStatistics, (n) =>
    n
      ? n
          .filter((m) => SORT_ORDER.indexOf(m.title) !== -1)
          .sort(
            (a, b) => SORT_ORDER.indexOf(a.title) - SORT_ORDER.indexOf(b.title)
          )
          .map((m) => ({
            ...m,
            homePercentage: Math.round((m.home / (m.home + m.away)) * 100),
            awayPercentage: Math.round((m.away / (m.home + m.away)) * 100),
          }))
      : []
  );

const toFormattedStartDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "E dd-MM-yyyy");
};

const selectPastEncounters = (state, props) =>
  getState(state).pastEncounters[getKey(props)];

export const makeSelectPastEncounters = () =>
  createSelector(selectPastEncounters, (n) =>
    n
      ? n.map((m) => ({
          ...m,
          formattedStartDate: toFormattedStartDate(m.startDate),
        }))
      : []
  );

const selectSeasonStatistics = (state, props) =>
  getState(state).season[getKey(props)];

export const makeSelectSeasonStatistics = () =>
  createSelector(selectSeasonStatistics, (n) => n || []);

reducerRegistry.register(REDUCER_NAME, reducer);
