import * as React from "react";
import { Route, Switch } from "react-router-dom";

import NationalTeamAppearancesPage from "../../components/nationalTeamAppearancesPage";
import NationalTeamCoachesPage from "../../components/nationalTeamCoachesPage";
import NationalTeamEventsPage from "../../components/nationalTeamEventsPage";
import NationalTeamHistoryPage from "../../components/nationalTeamHistoryPage";
import NationalTeamNewsPage from "../../components/nationalTeamNewsPage";
import NationalTeamPastCompetitionsPage from "../../components/nationalTeamPastCompetitionsPage";
import NationalTeamTablePage from "../../components/nationalTeamTablePage";
import NationalTeamTopScorersPage from "../../components/nationalTeamTopScorersPage";
import { ROUTES } from "./constants";

export const Routes = React.memo(({ path, team }) => {
  const defaultCompetitionTitle =
    team.competitions.find((n) => n.id.toString() === team.defaultCompetitionId)
      ?.title || "";

  return (
    <Switch>
      <Route path={`${path}${ROUTES.COACHES}`}>
        <NationalTeamCoachesPage nationalTeamId={team.nationalTeamId} />
      </Route>
      <Route path={`${path}${ROUTES.TOP_SCORERS}`}>
        <NationalTeamTopScorersPage nationalTeamId={team.nationalTeamId} />
      </Route>
      <Route path={`${path}${ROUTES.APPEARANCES}`}>
        <NationalTeamAppearancesPage nationalTeamId={team.nationalTeamId} />
      </Route>
      <Route path={`${path}${ROUTES.HISTORY}`}>
        <NationalTeamHistoryPage nationalTeamId={team.nationalTeamId} />
      </Route>
      <Route path={`${path}${ROUTES.NEWS}`}>
        <NationalTeamNewsPage nationalTeamId={team.nationalTeamId} />
      </Route>
      <Route path={`${path}${ROUTES.PAST_COMPETITIONS}`}>
        <NationalTeamPastCompetitionsPage
          teamId={team.id}
          nationalTeamId={team.nationalTeamId}
        />
      </Route>
      <Route path={`${path}${ROUTES.TABLE}`}>
        <NationalTeamTablePage
          teamId={team.id}
          nationalTeamId={team.nationalTeamId}
          defaultCompetitionTitle={defaultCompetitionTitle}
          defaultCompetitionId={team.defaultCompetitionId}
        />
      </Route>
      <Route path={`${path}${ROUTES.PLAYERS}`}>
        <h3>PLAYERS</h3>
      </Route>
      <Route path={`${path}${ROUTES.EVENTS}`} exact>
        <NationalTeamEventsPage
          nationalTeamId={team.nationalTeamId}
          defaultCompetitionTitle={defaultCompetitionTitle}
          defaultCompetitionId={team.defaultCompetitionId}
        />
      </Route>
    </Switch>
  );
});
