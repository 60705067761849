import * as React from "react";
import { Link } from "react-router-dom";

import { TYPE } from "../../../../service/competition";
import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import Scoreboard from "../../components/scoreboard";
import usePoll from "../../hooks/poll";
import CompetitionMatchChannel from "../competitionMatchChannel";
import MatchEvents from "../matchEvents";
import styles from "./style.scss";

const URL_TEMPLATE = "/match/:matchId";
const PLACEHOLDER = ":matchId";

const PAGE_SIZE = 50;

const LABELS = {
  DETAILS: "Details",
  SEPARATOR: "vs",
  UNKNOWN_VENUE: "Venue: TBC",
  POSTPONED: "Post.",
};

const POLL_INTERVAL = 60000;
const POLL_INTERVAL_LIVE = 12000;

const Component = ({
  title,
  competitionId,
  teamId,
  onGetTeamMatches,
  isReady,
  matches,
  type,
  season,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetTeamMatches({
        pageSize: PAGE_SIZE,
        teamId,
        competitionId,
        type,
        season,
      });
    }
  }, [competitionId, teamId, type, onGetTeamMatches, isReady, season]);

  const handleGetTeamMatches = React.useCallback(
    () =>
      onGetTeamMatches({
        pageSize: PAGE_SIZE,
        teamId,
        competitionId,
        type,
        season,
      }),
    [teamId, competitionId, type, onGetTeamMatches, season]
  );

  usePoll({
    callback: handleGetTeamMatches,
    interval: type === TYPE.LIVE ? POLL_INTERVAL_LIVE : POLL_INTERVAL,
  });

  if (!isReady && !(type === TYPE.LIVE)) {
    return (
      <section className={styles.section}>
        {title && (
          <CompetitionThemedHeading>
            <h2 className={styles.title}>{title}</h2>
          </CompetitionThemedHeading>
        )}
        <ul className={`${styles.list}`}>
          {[...Array(2)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(matches && matches.length)) {
    return null;
  }

  return (
    <section className={styles.section}>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{title}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.list}>
          {matches.map((n) => (
            <li className={styles.listItem} key={n.id}>
              <Link
                to={URL_TEMPLATE.replace(PLACEHOLDER, n.id)}
                className={styles.listItemContent}
              >
                {n.isLive ? (
                  <div className={styles.matchTime}>
                    <span className={styles.matchTimeValue}>
                      {n.matchTime && n.matchTime.startsWith("-")
                        ? "-"
                        : n.matchTime}
                    </span>
                  </div>
                ) : (
                  <span className={styles.startDate}>
                    {n.isPostponed ? LABELS.POSTPONED : n.formattedStartDate}
                  </span>
                )}
                <div className={styles.details}>
                  <div className={styles.detailsVenue}>
                    <span className={styles.detailsVenueName}>
                      {n.venue ? n.venue.name : LABELS.UNKNOWN_VENUE}
                    </span>
                  </div>
                  <div className={styles.teams}>
                    <div className={`${styles.team} ${styles.teamHome}`}>
                      <span
                        className={`${styles.teamName} ${styles.teamNameHome}`}
                      >
                        {n.homeTeam.name}
                      </span>
                      <img
                        src={n.homeTeam.logoUrl}
                        alt=""
                        className={styles.teamImg}
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.teamsSeparator}>
                      {n.isEnded || n.isLive ? (
                        <MatchEvents matchId={n.id} isLive={n.isLive}>
                          {({ score, isReady: isScoreboardReady }) => (
                            <Scoreboard
                              score={score}
                              isReady={isScoreboardReady}
                            />
                          )}
                        </MatchEvents>
                      ) : (
                        LABELS.SEPARATOR
                      )}
                    </div>
                    <div className={`${styles.team} ${styles.teamAway}`}>
                      <img
                        src={n.awayTeam.logoUrl}
                        alt=""
                        className={styles.teamImg}
                        loading="lazy"
                      />
                      <span
                        className={`${styles.teamName} ${styles.teamNameAway}`}
                      >
                        {n.awayTeam.name}
                      </span>
                    </div>
                  </div>
                </div>
                {!n.isEnded ? <CompetitionMatchChannel id={n.id} /> : <div />}
                <span className={styles.link}>{LABELS.DETAILS}</span>
              </Link>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default Component;
