import * as React from "react";

import CompetitionSelector from "../../containers/competitionSelector";
import styles from "./critical.scss";

const LABELS = {
  COMPETITION_SELECTOR_LABEL_SHORT: "Choose",
};

const StickyCompetitionBanner = ({ logoUrl, name, id }) => (
  <section className={styles.sticky}>
    <div className={styles.stickyContent}>
      <div className={styles.stickyCompetitionLogo}>
        <img
          src={logoUrl}
          alt=""
          className={styles.stickyCompetitionLogoImage}
        />
      </div>
      <span className={styles.stickyCompetitionName}>{name}</span>
      <CompetitionSelector
        active={id}
        label={LABELS.COMPETITION_SELECTOR_LABEL_SHORT}
        alwaysShow
        competitionId={id}
      />
    </div>
  </section>
);

export default StickyCompetitionBanner;
