import * as React from "react";

import HomeNewsCategory from "../../components/homeNewsCategory";
import styles from "./style.scss";

const PAGE_SIZE = 10;

const LABELS = {
  SHOW_MORE: "Load more",
};

export const HomeNews = ({
  categoryId,
  onGetHomeNews,
  isReady,
  isError,
  news,
  isFeatured,
  title,
  noResults,
  nextPage,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetHomeNews({ categoryId, isFeatured, pageSize: PAGE_SIZE });
    }
  }, [categoryId, onGetHomeNews, isFeatured, isReady]);

  const [isLoadingPage, setIsLoadingPage] = React.useState(false);

  if (isError) {
    return null;
  }

  return (
    <>
      <HomeNewsCategory
        news={news}
        title={title}
        noResults={noResults}
        isReady={isReady}
      />
      {nextPage && (
        <button
          type="button"
          disabled={isLoadingPage}
          onClick={() => {
            setIsLoadingPage(true);
            onGetHomeNews({
              categoryId,
              isFeatured,
              page: nextPage,
              pageSize: PAGE_SIZE,
              append: true,
            })
              .then(() => {
                setIsLoadingPage(false);
              })
              .catch(() => {
                setIsLoadingPage(false);
              });
          }}
          className={styles.showMore}
        >
          {LABELS.SHOW_MORE}
        </button>
      )}
    </>
  );
};
