import * as React from "react";

import HomeHighlights from "../../containers/homeHighlights";
import styles from "./critical.scss";

const LABELS = {
  TITLE: "LATEST HIGHLIGHTS",
};

const HomeHighlightsPage = () => (
  <div className={styles.section}>
    <HomeHighlights title={LABELS.TITLE} />
  </div>
);

export default HomeHighlightsPage;
