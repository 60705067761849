import * as React from "react";
import { useParams } from "react-router-dom";

import ErrorBoundary from "../../components/errorBoundary";
import TeamHistoryContent from "../../components/teamHistoryContent";
import Page from "../page";

const LABELS = {
  HISTORY: "History",
};

const TeamHistory = ({ onGetTeamPage }) => {
  const { nationalTeamId } = useParams();

  const getPage = React.useCallback(onGetTeamPage, [onGetTeamPage]);

  return (
    <Page
      url={`/national-team/${nationalTeamId}`}
      onGetPage={getPage}
      scrollToTop
    >
      {({ page, isReady: isPageReady }) => {
        if (!isPageReady) {
          return null;
        }

        return (
          <section>
            <h2>{LABELS.HISTORY}</h2>
            <ErrorBoundary>
              {page.html && Boolean(page.html.length) ? (
                <TeamHistoryContent html={page.html} />
              ) : null}
            </ErrorBoundary>
          </section>
        );
      }}
    </Page>
  );
};

export default TeamHistory;
