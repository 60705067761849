import {
  toPlayer,
  toPlayerHistory,
  toPlayerPage,
  toPlayerStatistics,
  toSeasons,
} from "./adapter";
import DataService from "./dataService";
import PageService from "./pageService";

export default class {
  constructor({ pageService, dataService } = {}) {
    this.pageService = pageService || new PageService();
    this.dataService = dataService || new DataService();
  }

  getPage(data) {
    return this.pageService
      .getPage(data)
      .then((page) => {
        const { playerId } = page.params;
        return this.getPlayerDetails({ id: Number(playerId) })
          .then((player) => toPlayerPage({ page, player }))
          .catch((error) => {
            console.error(error);
            throw error;
          });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getPlayerDetails(data) {
    return this.dataService
      .getPlayerDetails(data)
      .then((response) => toPlayer({ id: data.id, ...response }))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getPlayerStatistics(data) {
    return this.dataService
      .getPlayerStatistics(data)
      .then((response) => toPlayerStatistics(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getPlayerSeasons(data) {
    return this.dataService
      .getSeasons(data)
      .then((response) => toSeasons(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getPlayerHistory(data) {
    return this.dataService
      .getPlayerHistory(data)
      .then((response) => toPlayerHistory(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
