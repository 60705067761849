import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitions } from "./actions";
import Component from "./competitions";
import {
  selectActiveCompetitions,
  selectIsError,
  selectIsReady,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  competitions: selectActiveCompetitions,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitions: (data) => dispatch(getCompetitions(data)),
});

export function fetchData(store) {
  return store.dispatch(getCompetitions());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
