import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getNewsPage } from "./actions";
import { News as Component } from "./news";
import { selectCategory, selectDate, selectVideo } from "./reducer";

const mapStateToProps = createStructuredSelector({
  category: selectCategory,
  date: selectDate,
  video: selectVideo,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNewsPage: (data) => dispatch(getNewsPage(data)),
});

export function fetchData(store, { match: { url } }) {
  return store.dispatch(getNewsPage({ url }));
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
