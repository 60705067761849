import Api from "../../../../service";
import { SET_ERROR, SET_NATIONALTEAMS } from "./constants";

const setNationalTeams = (data) => ({
  type: SET_NATIONALTEAMS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getNationalTeams = (data) => (dispatch) =>
  Api.nationalTeam
    .getAll(data)
    .then((response) => {
      dispatch(setNationalTeams(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
