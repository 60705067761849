import {
  toFeaturedMatches,
  toHighlights,
  toHome,
  toHomeNews,
  toHomeNewsCategories,
  toNextPage,
  toSponsors,
} from "./adapter";
import DataService from "./dataService";
import HighlightsService from "./highlightsService";
import NewsService from "./newsService";
import PageService from "./pageService";

const NEWS_PAGE_SIZE = 4;

export default class {
  constructor({
    pageService,
    newsService,
    highlightsService,
    dataService,
  } = {}) {
    this.pageService = pageService || new PageService();
    this.newsService = newsService || new NewsService();
    this.highlightsService = highlightsService || new HighlightsService();
    this.dataService = dataService || new DataService();
  }

  getHome(data) {
    return this.getPage(data)
      .then((response) =>
        this.getFeaturedMatches().then((matches) => {
          return {
            ...response,
            banners: matches.length ? matches : response.banners, // only show default banners when there are no match banners
          };
        })
      )
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getFeaturedMatches(data) {
    return this.dataService
      .getFeaturedMatches(data)
      .then((response) => {
        return toFeaturedMatches(response);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getPage(data) {
    return this.pageService
      .getPage(data)
      .then((response) => {
        return toHome(response);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getHomeNews(data) {
    if (data && data.categoryId) {
      const { page, pageSize } = data;
      return this.newsService
        .getHomeNewsByCategory({
          pageSize: pageSize || NEWS_PAGE_SIZE,
          ...data,
        })
        .then((res) => {
          if (res.ok) {
            return res
              .json()
              .then((response) => ({
                nextPage: toNextPage({
                  page: page || 1,
                  pageCount: res.headers.get("PageSize"),
                }),
                news: toHomeNews(response),
              }))
              .catch((error) => {
                console.error(error);
                throw error;
              });
          }
          throw new Error(res.statusText);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }

    return this.newsService
      .getHomeNews(data)
      .then((response) => toHomeNews(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getHomeNewsCategories(data) {
    return this.newsService
      .getHomeNewsCategories(data)
      .then((response) => toHomeNewsCategories(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getHomeHighlights(data) {
    return this.highlightsService
      .getHighlights(data)
      .then((response) => toHighlights(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getHomeSponsors(data) {
    return this.dataService
      .getSponsors(data)
      .then((response) => {
        return toSponsors(response);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
