import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectCompetitionId,
  selectIsError,
  selectIsLoading,
  selectMeta,
  selectUrl,
} from "../app/reducer";
import { selectAllCompetitions } from "../competitions/reducer";
import { Layout as Component } from "./layout";

const mapStateToProps = createStructuredSelector({
  isError: selectIsError,
  isLoading: selectIsLoading,
  meta: selectMeta,
  url: selectUrl,
  competitions: selectAllCompetitions,
  competitionId: selectCompetitionId,
});

export default connect(mapStateToProps)(Component);
