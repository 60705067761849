import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitionSponsors, resetCompetitionSponsors } from "./actions";
import { CompetitionSponsors as Component } from "./competitionSponsors";
import {
  selectCompetitionSponsors,
  selectIsError,
  selectIsReady,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  sponsors: selectCompetitionSponsors,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionSponsors: (data) => dispatch(getCompetitionSponsors(data)),
  onResetCompetitionSponsors: (data) =>
    dispatch(resetCompetitionSponsors(data)),
});

export function fetchData(store) {
  return store.dispatch(getCompetitionSponsors());
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
