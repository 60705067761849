import { toNewsPage } from "./adapter";
import NewsService from "./newsService";

export default class {
  constructor({ newsService } = {}) {
    this.newsService = newsService || new NewsService();
  }

  getNewsPage(data) {
    return this.newsService
      .getNewsPage(data)
      .then((response) => {
        return toNewsPage(response);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
