import * as React from "react";

import NationalTeamSelector from "../../containers/nationalTeamSelector";
import styles from "./critical.scss";

const LABELS = {
  COMPETITION_SELECTOR_LABEL_SHORT: "Choose",
};

const StickyNationalTeamBanner = ({ logoUrl, name, nationalTeamId }) => (
  <section className={styles.sticky}>
    <div className={styles.stickyContent}>
      <div className={styles.logo}>
        <img src={logoUrl} alt="" className={styles.logoImage} />
      </div>
      <span className={styles.name}>{name}</span>
      <NationalTeamSelector
        id={nationalTeamId}
        active={nationalTeamId}
        label={LABELS.COMPETITION_SELECTOR_LABEL_SHORT}
        alwaysShow
      />
    </div>
  </section>
);

export default StickyNationalTeamBanner;
