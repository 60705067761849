import Api from "../../../../service";
import { RESET_TEAMPLAYERS, SET_ERROR, SET_TEAMPLAYERS } from "./constants";

const setTeamPlayers = (data) => ({
  type: SET_TEAMPLAYERS,
  data,
});

export const resetTeamPlayers = (data) => ({
  type: RESET_TEAMPLAYERS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getTeamPlayers = (data) => (dispatch) =>
  Api.team
    .getPlayers(data)
    .then((response) => {
      dispatch(setTeamPlayers(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
