import { connect } from "react-redux";
import { compose } from "redux";

import { getCompetitionNews, resetCompetitionNews } from "./actions";
import { CompetitionNews as Component } from "./competitionNews";
import {
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectNews,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectNews = makeSelectNews();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    news: selectNews(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionNews: (data) => dispatch(getCompetitionNews(data)),
  onResetCompetitionNews: (data) => dispatch(resetCompetitionNews(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
