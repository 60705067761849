import * as React from "react";
import { ReactStickyChildren } from "react-sticky-children";

import NationalTeamSelector from "../../containers/nationalTeamSelector";
import NationalTeamSponsors from "../nationalTeamSponsors";
import ResponsiveImage from "../shared/responsiveImage";
import StickyNationalTeamBanner from "../stickyNationalTeamBanner";
import styles from "./critical.scss";

const LABELS = {
  COMPETITION_SELECTOR_LABEL: "Select Team",
};

const Component = ({ team }) => {
  const initialStyle = React.useMemo(
    () => ({
      opacity: 0,
      background: `linear-gradient(to bottom right, ${team.themeColor1}, ${team.themeColor2})`,
      visibility: "hidden",
    }),
    [team.themeColor1, team.themeColor2]
  );

  const intersectingStyle = React.useMemo(
    () => ({
      opacity: 1,
      background: `linear-gradient(to bottom right, ${team.themeColor1}, ${team.themeColor2})`,
      visibility: "visible",
    }),
    [team.themeColor1, team.themeColor2]
  );

  return (
    <>
      <section className={`${styles.section}`}>
        <div className={styles.image}>
          {team && team.images && (
            <ResponsiveImage
              src={team.images.src}
              srcset={team.images.srcset}
              alt={team.images.alt}
              title={team.name}
              className={styles.imageImg}
              important="true"
            />
          )}
        </div>
        <div className={styles.text}>
          {team.sponsors && <NationalTeamSponsors sponsors={team.sponsors} />}
          <NationalTeamSelector
            id={team.nationalTeamId}
            active={team.nationalTeamId}
            label={LABELS.COMPETITION_SELECTOR_LABEL}
          />
          <span className={styles.textName}>{team.name}</span>
          <div className={styles.logo}>
            <img
              src={team.logoUrl}
              alt={team.name}
              className={styles.logoImg}
            />
          </div>
        </div>
      </section>
      <ReactStickyChildren
        initialStyle={initialStyle}
        intersectingStyle={intersectingStyle}
        className={styles.sticky}
        top={48}
      >
        <div className={styles.stickyContent}>
          <StickyNationalTeamBanner
            nationalTeamId={team.nationalTeamId}
            name={team.name}
            logoUrl={team.logoUrl}
          />
        </div>
      </ReactStickyChildren>
    </>
  );
};

export default Component;
