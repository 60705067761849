import { connect } from "react-redux";
import { compose } from "redux";

import { getNationalTeamAppearances } from "./actions";
import Component from "./nationalTeamAppearances";
import {
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectNationalTeamAppearances,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectNationalTeamAppearances = makeSelectNationalTeamAppearances();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    table: selectNationalTeamAppearances(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetNationalTeamAppearances: (data) =>
    dispatch(getNationalTeamAppearances(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
