import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_NATIONALTEAM_COACHES,
  SET_ERROR,
  SET_NATIONALTEAM_COACHES,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  coaches: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NATIONALTEAM_COACHES:
      return {
        ...state,
        isReady: true,
        isError: false,
        coaches: action.data,
      };
    case RESET_NATIONALTEAM_COACHES:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "MMM yyyy");
};

export const selectIsReady = createSelector(getState, (state) => state.isReady);

export const selectIsError = createSelector(getState, (state) => state.isError);

export const selectNationalTeamCoaches = createSelector(getState, (state) =>
  state.coaches.map((n) => ({
    ...n,
    formattedFromDate: toFormattedDate(n.from),
    formattedToDate: n.to && toFormattedDate(n.to),
  }))
);

reducerRegistry.register(REDUCER_NAME, reducer);
