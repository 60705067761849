import { connect } from "react-redux";
import { compose } from "redux";

import { getNationalTeamTopScorers } from "./actions";
import { NationalTeamTopScorers as Component } from "./nationalTeamTopScorers";
import {
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectNationalTeamTopScorers,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectNationalTeamTopScorers = makeSelectNationalTeamTopScorers();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    table: selectNationalTeamTopScorers(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetNationalTeamTopScorers: (data) =>
    dispatch(getNationalTeamTopScorers(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
