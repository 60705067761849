import Api from "../../../../service";
import { SET_ERROR, SET_NATIONALTEAMAPPEARANCES, getKey } from "./constants";

const setNationalTeamAppearances = (data, key) => ({
  type: SET_NATIONALTEAMAPPEARANCES,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getNationalTeamAppearances = (data) => (dispatch) =>
  Api.nationalTeam
    .getNationalTeamAppearances(data)
    .then((response) => {
      dispatch(setNationalTeamAppearances(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });
