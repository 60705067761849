import { format, formatDistanceStrict, isToday, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_TEAMNEWS,
  SET_ERROR,
  SET_TEAMNEWS,
  getKey,
} from "./constants";

const initialState = {
  isReady: {},
  isError: {},
  news: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAMNEWS:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: true,
        },
        isError: {
          ...state.isError,
          [action.key]: false,
        },
        news: {
          ...state.news,
          [action.key]: [...(state.news[action.key] || []), ...action.data],
        },
      };
    case RESET_TEAMNEWS:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: {
          ...state.isReady,
          [action.key]: false,
        },
        isError: {
          ...state.isError,
          [action.key]: true,
        },
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj)) {
    return formatDistanceStrict(dateObj, new Date(), { addSuffix: true });
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "dd-MM-yyyy HH:mm");
};

const selectIsReady = (state, props) => getState(state).isReady[getKey(props)];

export const makeSelectIsReady = () => createSelector(selectIsReady, (n) => n);

const selectIsError = (state, props) => getState(state).isError[getKey(props)];

export const makeSelectIsError = () => createSelector(selectIsError, (n) => n);

const selectNews = (state, props) => {
  const news = getState(state).news[getKey(props)];
  if (news) {
    return news.map((m) => ({
      ...m,
      date: toFormattedDate(m.date),
    }));
  }
  return [];
};

export const makeSelectNews = () => createSelector(selectNews, (n) => n);

reducerRegistry.register(REDUCER_NAME, reducer);
