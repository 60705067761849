import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getPlayer, getPlayerPage } from "./actions";
import Component from "./player";
import { selectIsError, selectIsReady, selectPlayer } from "./reducer";

const mapStateToProps = createStructuredSelector({
  player: selectPlayer,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPlayer: (data) => dispatch(getPlayer(data)),
  onGetPlayerPage: (data) => dispatch(getPlayerPage(data)),
});

export function fetchData(store, { match: { url } }) {
  return store.dispatch(getPlayerPage({ url }));
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
