import * as React from "react";

import NationalTeamCompetitionTable from "../../containers/nationalTeamCompetitionTable";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE_STANDINGS: "Standings",
};

const NationalTeamTablePage = ({
  teamId,
  defaultCompetitionTitle,
  defaultCompetitionId,
}) => {
  return (
    <section className={styles.section}>
      <div key={defaultCompetitionId} className={styles.competitionTable}>
        <NationalTeamCompetitionTable
          teamId={teamId}
          title={defaultCompetitionTitle || LABELS.TITLE_STANDINGS}
          competitionId={defaultCompetitionId}
          isCurrent
        />
        <LeaderboardOrSquareAdvertisement
          uid={`nationalteam-table-page-${defaultCompetitionId}`}
        />
      </div>
    </section>
  );
};

export default NationalTeamTablePage;
