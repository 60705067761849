const HEADER_EVENTS = ["GOAL", "OWN_GOAL", "PENALTY"];

const toMatchEvents = (data, filter) => {
  if (data && data.length) {
    return data.filter(filter).map((n) => ({
      id: n.id,
      type: n.type,
      value: n.playerName,
      team: n.team,
      time: n.time,
    }));
  }
  return [];
};

export const toResult = (data) =>
  data
    ? {
        id: data.id,
        isLive: data.isLive,
        status: data.status,
        matchTime:
          data.status === "PLAYED" &&
          (data.matchTime === null || data.matchTime === undefined)
            ? "FT"
            : data.matchTime,
        homeScore:
          data.resultSupplementHome !== null &&
          data.resultSupplementHome !== undefined
            ? data.resultSupplementHome
            : data.homeScore,
        awayScore:
          data.resultSupplementAway !== null &&
          data.resultSupplementAway !== undefined
            ? data.resultSupplementAway
            : data.awayScore,
        resultSupplement: data.resultSupplement,
        homePenalties: Number.isNaN(parseInt(data.homePenalties, 10))
          ? 0
          : parseInt(data.homePenalties, 10),
        awayPenalties: Number.isNaN(parseInt(data.awayPenalties, 10))
          ? 0
          : parseInt(data.awayPenalties, 10),
        homeEvents: toMatchEvents(
          data.events,
          (n) =>
            n.team.toLowerCase() === "home" && HEADER_EVENTS.includes(n.type)
        ),
        awayEvents: toMatchEvents(
          data.events,
          (n) =>
            n.team.toLowerCase() === "away" && HEADER_EVENTS.includes(n.type)
        ),
      }
    : null;
