import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getHomeHighlights, resetHomeHighlights } from "./actions";
import Component from "./homeHighlights";
import {
  selectHighlights,
  selectIsError,
  selectIsReady,
  selectNextPageToken,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  highlights: selectHighlights,
  nextPageToken: selectNextPageToken,
});

const mapDispatchToProps = (dispatch) => ({
  onGetHighlights: (data) => dispatch(getHomeHighlights(data)),
  onResetHighlights: (data) => dispatch(resetHomeHighlights(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
