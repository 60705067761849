import fetch from "node-fetch";

export default class {
  async getCompetitionNews({ id, isFeatured = false, pageSize }) {
    const res = await fetch(
      `${
        process.env.CMS_URL
      }/news/getcompetitionnews?competitionTypeId=${id}&isFeatured=${isFeatured}${
        pageSize ? `&pageSize=${pageSize}` : ""
      }`
    );
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  getCompetitionHighlights({ id }) {
    return new Promise((resolve, reject) => {
      const response = this.news;
      if (response) {
        resolve(response);
      } else {
        reject(new Error(`404: ${id} not found`));
      }
    });
  }
}
