import { connect } from "react-redux";

import { getCompetitionTable } from "./actions";
import Component from "./nationalTeamCompetitionTable";
import {
  makeSelectCompetitionTable,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectCompetitionStandings = makeSelectCompetitionTable();
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    table: selectCompetitionStandings(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionTable: (data) => dispatch(getCompetitionTable(data)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
