import Api from "../../../../service";
import {
  RESET_HOMEHIGHLIGHTS,
  SET_ERROR,
  SET_HOMEHIGHLIGHTS,
} from "./constants";

const setHomeHighlights = (data) => ({
  type: SET_HOMEHIGHLIGHTS,
  data,
});

export const resetHomeHighlights = (data) => ({
  type: RESET_HOMEHIGHLIGHTS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getHomeHighlights = (data) => (dispatch) =>
  Api.home
    .getHomeHighlights(data)
    .then((response) => {
      dispatch(setHomeHighlights(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
