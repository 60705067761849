import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_NATIONALTEAMS } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  nationalTeams: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NATIONALTEAMS:
      return {
        ...state,
        isReady: true,
        isError: false,
        nationalTeams: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectNationalTeams = createSelector(getState, (n) =>
  n.nationalTeams.filter((m) => !m.team.isHidden).map((m) => ({ ...m.team }))
);

export const selectAllNationalTeams = createSelector(getState, (n) =>
  n.nationalTeams.map((m) => ({ ...m.team }))
);

reducerRegistry.register(REDUCER_NAME, reducer);
