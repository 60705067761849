import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_TEAM } from "./constants";

const initialState = {
  nationalTeamId: null,
  id: null,
  name: null,
  ageCategory: null,
  gender: null,
  discipline: null,
  logoUrl: null,
  themeColor1: null,
  themeColor2: null,
  images: [],
  competitions: [],
  defaultCompetitionId: null,
  sponsors: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM:
      return {
        ...state,
        nationalTeamId: action.data.nationalTeamId,
        id: action.data.id,
        name: action.data.name,
        ageCategory: action.data.ageCategory,
        gender: action.data.gender,
        discipline: action.data.discipline,
        logoUrl: action.data.logoUrl,
        themeColor1: action.data.themeColor1,
        themeColor2: action.data.themeColor2,
        images: action.data.images,
        competitions: action.data.competitions,
        defaultCompetitionId: action.data.defaultCompetitionId,
        sponsors: action.data.sponsors,
        isReady: true,
        isError: false,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectTeam = createSelector(getState, (n) => ({
  nationalTeamId: n.nationalTeamId,
  id: n.id,
  name: n.name,
  ageCategory: n.ageCategory,
  gender: n.gender,
  discipline: n.discipline,
  logoUrl: n.logoUrl,
  themeColor1: n.themeColor1,
  themeColor2: n.themeColor2,
  images: n.images,
  competitions: n.competitions,
  defaultCompetitionId: n.defaultCompetitionId,
  sponsors: n.sponsors,
}));

reducerRegistry.register(REDUCER_NAME, reducer);
