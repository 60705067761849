import * as React from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "../layout";
import { fetchRoutes } from "./actions";
import { getRouteConfig } from "./config";

export const Routes = React.memo(({ routes, ...params }) => {
  return (
    <Layout>
      <Switch>
        {routes.map((route) => {
          if (!(route.isPartial || route.isLayout)) {
            const Component = getRouteConfig(route.name).component;
            return (
              <Route
                key={route.url}
                path={route.url}
                // eslint-disable-next-line react/jsx-props-no-spreading
                render={(props) => <Component {...props} {...params} />}
                exact={route.exact}
              />
            );
          }
          return null;
        })}
      </Switch>
    </Layout>
  );
});

export const PartialRoutes = React.memo(({ routes, ...params }) => {
  return routes.map((route) => {
    if (route.isPartial) {
      const Component = getRouteConfig(route.name).component;
      return (
        <Route
          key={route.url}
          path={route.url}
          // eslint-disable-next-line react/jsx-props-no-spreading
          render={(props) => <Component {...props} {...params} />}
          exact={route.exact}
        />
      );
    }
    return null;
  });
});

export const getRoutes = (store) => {
  return store.dispatch(fetchRoutes());
};
