import Api from "../../../../service";
import { RESET_HOMENEWS, SET_ERROR, SET_HOMENEWS } from "./constants";

const setHomeNews = (data) => ({
  type: SET_HOMENEWS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getHomeNews = (data) => (dispatch) =>
  Api.home
    .getHomeNews(data)
    .then((response) => {
      dispatch(setHomeNews(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetHomeNews = () => ({
  type: RESET_HOMENEWS,
});
