import * as React from "react";

import { useCompetitionContext } from "../competition/context";
import ResponsiveImage from "../shared/responsiveImage";
import styles from "./critical.scss";

const LABELS = {
  LIVE: "live",
  FULL_TIME: "FT",
  POSTPONED: "POSTPONED",
  CANCELLED: "CANCELLED",
};

const VenueBanner = ({
  venue,
  formattedStartDate,
  className,
  children,
  isEnded,
  isLive,
  isPostponed,
  isCancelled,
  matchTime,
}) => {
  const {
    state: { name: competitionName, banner },
  } = useCompetitionContext();

  return (
    <section className={`${styles.section} ${className ?? ""}`}>
      <div className={styles.image}>
        {banner && (
          <ResponsiveImage
            src={banner.src}
            srcset={banner.srcset}
            alt={banner.alt}
            title={competitionName}
            className={styles.imageImg}
            important="true"
          />
        )}
      </div>
      <div className={styles.text}>
        {isLive ? (
          <>
            <div className={styles.textLive}>
              <span className={styles.textLiveLabel}>{LABELS.LIVE}</span>
            </div>
            {/* TODO: matchTime should come from the results API */}
            <span className={styles.textStartDate}>
              {matchTime && matchTime.startsWith("-") ? "-" : matchTime}
            </span>
            <span className={styles.textName}>{formattedStartDate}</span>
          </>
        ) : (
          <>
            {isEnded ? (
              <>
                <span className={styles.textStartDate}>
                  {matchTime || LABELS.FULL_TIME}
                </span>
                <span className={styles.textName}>{formattedStartDate}</span>
              </>
            ) : (
              <span className={styles.textStartDate}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  isPostponed
                    ? LABELS.POSTPONED
                    : isCancelled
                    ? LABELS.CANCELLED
                    : formattedStartDate
                }
              </span>
            )}
          </>
        )}
        {venue && venue.name && (
          <span className={styles.textName}>{venue.name}</span>
        )}
      </div>
      {children}
    </section>
  );
};

export default VenueBanner;
