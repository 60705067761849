/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";

import styles from "./styles.scss";

const Component = ({
  advertisement,
  isReady,
  isError,
  onGetAdvertisement,
  uid,
  type,
  competitionId,
  children,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetAdvertisement({ uid, type, competitionId });
    }
  }, [onGetAdvertisement, isReady, uid, type, competitionId]);

  const handleClick = React.useCallback(() => {
    if (advertisement && advertisement.url) {
      window.open(advertisement.url, "_blank");
    }
  }, [advertisement]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!advertisement) {
    return null;
  }

  return (
    <div role="complementary" className={styles.advertisement}>
      {children(advertisement, handleClick)}
    </div>
  );
};

export default Component;
