import * as React from "react";
import { Link } from "react-router-dom";

import { NATIONAL_TEAM_ID } from "../../containers/nationalTeam/constants";
import CompetitionThemedHeading from "../competitionThemedHeading";
import styles from "./style.scss";

const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const PLACEHOLDER = ":competitionId";

const LABELS = {
  SEASON: "Season Statistics",
};

const SkeletonMatchStatistics = ({
  homeTeam,
  awayTeam,
  isEnded,
  isLive,
  competitionId,
}) => {
  const isNationalTeamParticipating =
    homeTeam.id === NATIONAL_TEAM_ID || awayTeam.id === NATIONAL_TEAM_ID;

  return (
    <section className={styles.section}>
      {(isEnded || isLive) && (
        <div>
          <ul className={styles.inplayTeams}>
            <li className={`${styles.inplayTeam} ${styles.inplayTeamHome}`}>
              {isNationalTeamParticipating ? (
                <span className={styles.inplayTeamHeading}>
                  {homeTeam.name}
                </span>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(
                    TEAM_PLACEHOLDER,
                    homeTeam.id
                  ).replace(PLACEHOLDER, competitionId)}
                  className={styles.inplayTeamHeading}
                >
                  {homeTeam.name}
                </Link>
              )}
              <div className={styles.inplayTeamLogo}>
                <img
                  src={homeTeam.logoUrl}
                  alt=""
                  title={homeTeam.name}
                  className={styles.inplayTeamLogoImg}
                />
              </div>
            </li>
            <li
              className={`${styles.inplayTeam} ${styles.inplayTeamSeparator}`}
            />
            <li className={`${styles.inplayTeam} ${styles.inplayTeamAway}`}>
              <div className={styles.inplayTeamLogo}>
                <img
                  src={awayTeam.logoUrl}
                  alt=""
                  title={awayTeam.name}
                  className={styles.inplayTeamLogoImg}
                />
              </div>
              {isNationalTeamParticipating ? (
                <span className={styles.inplayTeamHeading}>
                  {awayTeam.name}
                </span>
              ) : (
                <Link
                  to={TEAM_URL_TEMPLATE.replace(
                    TEAM_PLACEHOLDER,
                    awayTeam.id
                  ).replace(PLACEHOLDER, competitionId)}
                  className={styles.inplayTeamHeading}
                >
                  {awayTeam.name}
                </Link>
              )}
            </li>
          </ul>
          <ul className={styles.inplayStats}>
            <ul className={styles.list}>
              {[...Array(4)].map((n, index) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${styles.listItem}`}
                />
              ))}
            </ul>
          </ul>
        </div>
      )}
      <div>
        <CompetitionThemedHeading>
          <h2>{LABELS.SEASON}</h2>
        </CompetitionThemedHeading>
        <ul className={styles.seasonStats}>
          {[...Array(4)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.seasonStatsItem}`}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SkeletonMatchStatistics;
