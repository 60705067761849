import * as React from "react";

import styles from "./critical.scss";

const handleClick = ({ title, text, url }) =>
  navigator
    .share({
      title,
      text,
      url,
    })
    .catch(console.error);

const ShareButton = ({ title, text, url }) => {
  if (typeof navigator === "undefined" || !navigator.share) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={() => handleClick({ title, text, url })}
      className={styles.button}
    >
      Share
    </button>
  );
};

export default ShareButton;
