import { toPage, toResponsiveImage } from "../adapter";

const PAGE_SIZE = 12;

const toTeam = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        logoUrl: data.image,
      }
    : null;

const toVenue = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        images: toResponsiveImage(data.images),
      }
    : null;

export const toMatch = (data) =>
  data
    ? {
        id: data.id,
        startDate: data.startDate,
        homeTeam: toTeam(data.homeTeam),
        awayTeam: toTeam(data.awayTeam),
        isLive: data.isLive,
        venue: toVenue(data.venue),
        matchTime: data.matchTime,
        isLineupAvailable: data.isLineupAvailable,
        competitionId: data.competitionTypeId,
      }
    : null;

const toBanner = (data) => ({
  id: data.id,
  images: toResponsiveImage(data.images),
  ctaText: data.link && data.link.title,
  url: data.link && data.link.url,
  color: data.textColor,
  title: data.title,
  isExternalUrl: data.link && !data.link.isInternal,
  isNewWindow: data.link && data.link.isNewWindow,
});

export const toBanners = (data) => {
  if (!data) {
    return null;
  }

  return data.map((n) => toBanner(n));
};

export const toHome = (data) => {
  if (data) {
    return {
      ...toPage(data),
      banners: toBanners(data.banners),
    };
  }
  return null;
};

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
      }
    : null;

export const toHomeNews = (data) =>
  data
    ? data.map((n) => ({
        ...toPage(n),
        summary: n.summary,
        tags: n.tags && n.tags.filter((m) => m),
        date: n.date,
        isFeatured: n.isFeatured,
        category: toNewsCategory(n.category),
        video: n.video,
      }))
    : [];

export const toHomeNewsCategories = (data) =>
  data ? data.map((n) => toNewsCategory(n)) : [];

export const toHighlightsItems = (data) =>
  data
    ? data.map((n) => {
        return {
          id: n.id.videoId,
          description: n.snippet.description,
          title: n.snippet.title,
          datePublished: n.snippet.publishedAt,
          thumbnails: Object.entries(n.snippet.thumbnails).map(([, value]) => ({
            imageUrl: value.url,
            width: value.width,
            height: value.height,
          })),
        };
      })
    : [];

export const toHighlights = (data) =>
  data
    ? {
        highlights: toHighlightsItems(data.items),
        nextPageToken:
          Boolean(data.items && data.items.length === PAGE_SIZE) &&
          data.nextPageToken,
      }
    : null;

export const toSponsors = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        url: n.url,
        image: n.logo && n.logo.imageUrl,
        tier: n.tier,
      }))
    : null;

export const toFeaturedMatches = (data) =>
  data
    ? data.map((n) => {
        if (n.banners && n.banners.length) {
          return {
            ...toBanner(n.banners[0]),
            match: toMatch(n),
          };
        }

        if (n.defaultBanners && n.defaultBanners.length) {
          return {
            ...toBanner(n.defaultBanners[0]),
            match: toMatch(n),
          };
        }

        return null;
      })
    : null;

export const toNextPage = ({ page, pageCount }) => {
  const pageCountAsInt = parseInt(pageCount, 10);
  if (
    pageCountAsInt &&
    !Number.isNaN(pageCountAsInt) &&
    pageCountAsInt > 1 &&
    page < pageCountAsInt
  ) {
    return page + 1;
  }

  return null;
};
