import * as React from "react";
import { Link } from "react-router-dom";

import { useCompetitionContext } from "../competition/context";
import ResponsiveImage from "../shared/responsiveImage";
import styles from "./critical.scss";
import DEFAULT_PROFILE_PHOTO from "./img/default-profile-photo.svg";

const TEAM_URL_TEMPLATE = "/team/:teamId/:competitionId";
const TEAM_PLACEHOLDER = ":teamId";
const COMPETITION_PLACEHOLDER = ":competitionId";

const PlayerBanner = ({ player }) => {
  const {
    state: { id: competitionId, name: competitionName, banner },
  } = useCompetitionContext();

  return (
    <section className={`${styles.section}`}>
      <div className={styles.image}>
        <ResponsiveImage
          src={banner.src}
          srcset={banner.srcset}
          alt={banner.alt}
          title={competitionName}
          className={styles.imageImg}
          important="true"
        />
      </div>
      <div className={styles.text}>
        <div className={styles.details}>
          <div className={styles.detailsUpper}>
            <span className={styles.textName}>{player.name}</span>
            {player.position && (
              <span className={styles.textPosition}>{player.position}</span>
            )}
          </div>
          <div className={styles.detailsLower}>
            <Link
              to={TEAM_URL_TEMPLATE.replace(
                TEAM_PLACEHOLDER,
                player.team.id
              ).replace(COMPETITION_PLACEHOLDER, competitionId)}
              className={styles.teamLink}
            >
              <img
                src={player.team.logoUrl}
                alt=""
                className={styles.teamLogoImg}
              />
              <div className={styles.teamInfo}>
                <span className={styles.teamName}>{player.team.name}</span>
                {player.team.debut && (
                  <span className={styles.teamDebut}>
                    Debut match: {player.team.debut}
                  </span>
                )}
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.thumb}>
          <img
            src={player.profilePhotoUrl || DEFAULT_PROFILE_PHOTO}
            alt=""
            className={styles.img}
          />
        </div>
      </div>
    </section>
  );
};

export default PlayerBanner;
