import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import { REDUCER_NAME, SET_ERROR, SET_HOMEHIGHLIGHTS } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  highlights: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEHIGHLIGHTS:
      return {
        ...state,
        isReady: true,
        isError: false,
        highlights: action.data.highlights,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (state) => state.isReady);

export const selectIsError = createSelector(getState, (state) => state.isError);

export const selectHighlights = createSelector(
  getState,
  (state) => state.highlights
);

reducerRegistry.register(REDUCER_NAME, reducer);
