import * as React from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Competitions from "../competitions";
import styles from "./critical.scss";

const getUrl = (route, competitionId, season, tab) => {
  return generatePath(route, { competitionId, season, tab });
};

const CompetitionSelector = ({ label, alwaysShow, active, competitionId }) => {
  const history = useHistory();
  const { season, tab } = useParams();
  const { path } = useRouteMatch();

  return (
    <Competitions>
      {(competitions) => (
        <section
          className={`${styles.section} ${alwaysShow ? styles.alwaysShow : ""}`}
        >
          <select
            className={styles.list}
            onChange={(e) => {
              history.push(getUrl(path, e.target.value, season, tab));
              window.scrollTo(0, 0);
            }}
            defaultValue={competitionId || ""}
          >
            <option className={styles.listItem} value="" disabled>
              {label}
            </option>
            {competitions.map((n) => (
              <option
                className={`${styles.listItem} ${
                  n.id === active ? styles.listItemActive : ""
                }`}
                key={n.id}
                value={n.id}
              >
                {n.name}
              </option>
            ))}
          </select>
        </section>
      )}
    </Competitions>
  );
};

export default CompetitionSelector;
