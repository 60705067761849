import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { MatchNewsPage } from "../../components/match/matchNewsPage";
import MatchLineup from "../matchLineup";
import MatchStatistics from "../matchStatistics";

export const ROUTES = {
  LINEUP: "/",
  STATISTICS: "/statistics",
  NEWS: "/articles",
};

export const Routes = React.memo(({ path, match }) => {
  return (
    <Switch>
      <Route path={`${path}${ROUTES.NEWS}`}>
        <MatchNewsPage match={match} />
      </Route>
      <Route path={`${path}${ROUTES.STATISTICS}`}>
        <MatchStatistics
          matchId={match.id}
          homeTeam={match.homeTeam}
          awayTeam={match.awayTeam}
          isLive={match.isLive}
          isEnded={match.isEnded}
        />
      </Route>
      <Route path={`${path}${ROUTES.LINEUP}`} exact>
        <MatchLineup
          homeTeam={match.homeTeam}
          awayTeam={match.awayTeam}
          isLineupAvailable={match.isLineupAvailable}
          matchId={match.id}
          isReady={match.isReady}
          isLive={match.isLive}
          isEnded={match.isEnded}
          referees={match.referees}
          formattedLineupAvailableDate={match.formattedLineupAvailableDate}
        />
      </Route>
    </Switch>
  );
});
