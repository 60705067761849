import * as React from "react";
import { useParams } from "react-router-dom";

import PlayerSeasons from "../../containers/playerSeasons";
import PlayerStatistics from "../../containers/playerStatistics";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE: "Player Statistics",
};

const PlayerStatisticsPage = () => {
  const { playerId, season } = useParams();

  return (
    <section className={styles.section}>
      <h2>{LABELS.TITLE}</h2>
      <PlayerSeasons playerId={playerId} season={season} />
      <PlayerStatistics playerId={playerId} season={season} />
      <LeaderboardOrSquareAdvertisement uid="player-statistics-page-1" />
    </section>
  );
};

export default PlayerStatisticsPage;
