import Api from "../../../../service";
import { RESET_PLAYERSEASONS, SET_ERROR, SET_PLAYERSEASONS } from "./constants";

const setPlayerSeasons = (data) => ({
  type: SET_PLAYERSEASONS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getPlayerSeasons = (data) => (dispatch) =>
  Api.player
    .getPlayerSeasons(data)
    .then((response) => {
      dispatch(setPlayerSeasons(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });

export const resetPlayerSeasons = (data) => ({
  type: RESET_PLAYERSEASONS,
  data,
});
