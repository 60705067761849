import * as React from "react";

import styles from "./style.scss";

const LABELS = {
  DATE_PREFIX: "Published",
  READ_MORE: "Read More",
};

export const NewsHighlight = ({ data }) => {
  return (
    <div className={styles.item}>
      {data.video && (
        <div
          className={styles.wrapper}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          role="none"
        >
          <iframe
            loading="lazy"
            type="text/html"
            width="560"
            height="315"
            frameBorder="0"
            src={`https://www.youtube.com/embed/${data.video}?controls=1&modestbranding=1`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={styles.iframe}
            title="Highlights"
          />
        </div>
      )}
      <div className={styles.content}>
        <div>
          <h3 className={styles.itemTitle}>{data.title}</h3>
        </div>
        <div className={styles.footer}>
          <span
            className={styles.date}
          >{`${LABELS.DATE_PREFIX}: ${data.date}`}</span>
        </div>
      </div>
    </div>
  );
};
