import Api from "../../../../service";
import { SET_ERROR, SET_HOMESPONSORS } from "./constants";

const setHomeSponsors = (data) => ({
  type: SET_HOMESPONSORS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getHomeSponsors = (data) => (dispatch) =>
  Api.home
    .getHomeSponsors(data)
    .then((response) => {
      dispatch(setHomeSponsors(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
