import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  getCompetition,
  getCompetitionPage,
  resetCompetition,
} from "./actions";
import { Competition as Component } from "./competition";
import { selectCompetition, selectIsError, selectIsReady } from "./reducer";

const mapStateToProps = createStructuredSelector({
  competition: selectCompetition,
  isReady: selectIsReady,
  isError: selectIsError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetition: (data) => dispatch(getCompetition(data)),
  onResetCompetition: (data) => dispatch(resetCompetition(data)),
  onGetCompetitionPage: (data) => dispatch(getCompetitionPage(data)),
});

export function fetchData(store, { match: { url } }) {
  return store.dispatch(getCompetitionPage({ url }));
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
