import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitionCup, resetCompetitionCup } from "./actions";
import Component from "./competitionCup";
import { selectCompetitionCup, selectIsError, selectIsReady } from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  cup: selectCompetitionCup,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionCup: (data) => dispatch(getCompetitionCup(data)),
  onResetCompetitionCup: (data) => dispatch(resetCompetitionCup(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
