import * as React from "react";

import styles from "./critical.scss";

const Component = ({ onGetSocialLinks, isReady, isError, socialLinks }) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetSocialLinks();
    }
  }, [onGetSocialLinks, isReady]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  if (!(socialLinks && socialLinks.length)) {
    return null;
  }

  return (
    <ul className={styles.social}>
      {socialLinks.map((n) => (
        <li key={n.id} className={styles.socialItem}>
          <a
            href={n.url}
            title={n.platform.toLowerCase()}
            target="_blank"
            rel="noreferrer"
            className={styles.socialLink}
          >
            {n.title}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Component;
