import * as React from "react";

import CompetitionTopScorers from "../../containers/competitionTopScorers";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

export const CompetitionTopScorersPage = ({ competitionId }) => {
  return (
    <section className={styles.section}>
      <CompetitionTopScorers competitionId={competitionId} />
      <LeaderboardOrSquareAdvertisement
        uid="competition-top-scorers-page-1"
        competitionId={competitionId}
      />
    </section>
  );
};
