import Api from "../../../../service";
import { SET_COMPETITIONS, SET_ERROR } from "./constants";

const setCompetitions = (data) => ({
  type: SET_COMPETITIONS,
  data,
});

const setError = (data) => ({
  type: SET_ERROR,
  data,
});

export const getCompetitions = (data) => (dispatch) =>
  Api.competition
    .getAll(data)
    .then((response) => {
      dispatch(setCompetitions(response));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error));
    });
