import { format, isToday, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { createSelector } from "reselect";

import reducerRegistry from "../../util/reducerRegistry";
import {
  REDUCER_NAME,
  RESET_HOMEHIGHLIGHTS,
  SET_ERROR,
  SET_HOMEHIGHLIGHTS,
} from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  highlights: [],
  nextPageToken: "0",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEHIGHLIGHTS:
      return {
        ...state,
        isReady: true,
        isError: false,
        highlights: [...state.highlights, ...action.data.highlights],
        nextPageToken: action.data.nextPageToken,
      };
    case RESET_HOMEHIGHLIGHTS:
      return initialState;
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsReady = createSelector(getState, (state) => state.isReady);

export const selectIsError = createSelector(getState, (state) => state.isError);

const toFormattedDate = (date) => {
  if (!date) {
    return date;
  }

  const dateObj = parseISO(date);
  if (isToday(dateObj) && dateObj > new Date()) {
    return `Today at ${format(
      utcToZonedTime(dateObj, "Europe/Paris"),
      "HH:mm"
    )}`;
  }
  return format(utcToZonedTime(dateObj, "Europe/Paris"), "dd-MM-yyyy");
};

export const selectHighlights = createSelector(getState, (state) =>
  state.highlights.map((n) => ({
    ...n,
    formattedDate: toFormattedDate(n.datePublished),
  }))
);

export const selectNextPageToken = createSelector(
  getState,
  (state) => state.nextPageToken
);

reducerRegistry.register(REDUCER_NAME, reducer);
