import * as React from "react";

import PastMatchesSection from "../../containers/pastMatchesSection";
import styles from "./critical.scss";

const LABELS = {
  TITLE: "PAST MATCHES",
};

const PastMatchesPage = () => (
  <div className={styles.container}>
    <PastMatchesSection title={LABELS.TITLE} showPagination />
  </div>
);

export default PastMatchesPage;
