import * as React from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import CompetitionBanner from "../../components/competitionBanner/competitionBanner";
import SkeletonTabs from "../../components/skeletonTabs";
import { TabButton } from "../../components/tabButton";
import { Tabs } from "../../components/tabs";
import Page from "../page";
import { ROUTES, U14_COMPETITIONS } from "./constants";
import styles from "./critical.scss";
import { Routes } from "./routes";

const LABELS = {
  HOME: "Home",
  CUP: "Cup",
  TABLE: "Table",
  TOP_SCORERS: "Top Scorers",
  CLUBS: "Teams",
  ARCHIVE: "Archive",
  HIGHLIGHTS: "Highlights",
  NEWS: "News",
};

const getUrl = (route, competitionId) => {
  return generatePath(route, { competitionId });
};

const getArchiveUrl = (route, season) => {
  return generatePath(route, { season });
};

export const Competition = ({
  isReady,
  competition,
  onResetCompetition,
  onGetCompetitionPage,
}) => {
  const { competitionId } = useParams();
  const { path } = useRouteMatch();

  React.useEffect(
    () => () => onResetCompetition(competitionId),
    [onResetCompetition, competitionId]
  );

  const getPage = React.useCallback(onGetCompetitionPage, [
    onGetCompetitionPage,
  ]);

  return (
    <Page
      url={`/competition/${competitionId}/`}
      onGetPage={getPage}
      scrollToTop
    >
      {({ isReady: isPageReady }) => {
        if (!(isPageReady && isReady)) {
          return (
            <>
              <div className={styles.banner} />
              <SkeletonTabs />
            </>
          );
        }

        return (
          <section important="true">
            <CompetitionBanner
              id={competition.id}
              name={competition.name}
              themeColor1={competition.themeColor1}
              themeColor2={competition.themeColor2}
              banner={competition.banner}
              logoUrl={competition.logoUrl}
            />
            <Tabs>
              <TabButton
                to={`${getUrl(path, competition.id)}${ROUTES.HOME}`}
                exact
                replace
              >
                {LABELS.HOME}
              </TabButton>
              {!(competition.isCup || competition.isSuperCup) && (
                <TabButton
                  to={`${getUrl(path, competition.id)}${ROUTES.TABLE}`}
                  replace
                >
                  {LABELS.TABLE}
                </TabButton>
              )}
              {!(
                U14_COMPETITIONS.includes(competitionId) ||
                competition.isSuperCup
              ) && (
                <TabButton
                  to={`${getUrl(path, competition.id)}${ROUTES.TOP_SCORERS}`}
                  replace
                >
                  {LABELS.TOP_SCORERS}
                </TabButton>
              )}
              <TabButton
                to={`${getUrl(path, competition.id)}${ROUTES.CLUBS}`}
                replace
              >
                {LABELS.CLUBS}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competition.id)}${getArchiveUrl(
                  ROUTES.ARCHIVE
                )}`}
                replace
              >
                {LABELS.ARCHIVE}
              </TabButton>
              <TabButton
                to={`${getUrl(path, competition.id)}${ROUTES.NEWS}`}
                replace
              >
                {LABELS.NEWS}
              </TabButton>
            </Tabs>
            <Routes path={path} competition={competition} />
          </section>
        );
      }}
    </Page>
  );
};
