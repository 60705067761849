import * as React from "react";

import CompetitionCup from "../../containers/competitionCup";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

export const CompetitionCupPage = ({ competitionId, season }) => {
  return (
    <section className={styles.section}>
      <CompetitionCup competitionId={competitionId} isCurrent season={season} />
      <LeaderboardOrSquareAdvertisement
        uid="competition-table-page-1"
        competitionId={competitionId}
      />
    </section>
  );
};
