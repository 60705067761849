import * as React from "react";
import { Link, generatePath, useRouteMatch } from "react-router-dom";

import CompetitionThemedBackground from "../../components/competitionThemedBackground";
import styles from "./style.scss";

const getUrl = (route, nationalTeamId, competitionId) => {
  return generatePath(route, {
    nationalTeamId: nationalTeamId.replace(/^\//, ""),
    competitionId,
  });
};

const NationalTeamCompetitions = ({
  nationalTeamId,
  onGetNationalTeamCompetitions,
  onResetNationalTeamCompetitions,
  isReady,
  competitions,
  competitionId,
}) => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    if (!isReady) {
      onGetNationalTeamCompetitions({ nationalTeamId });
    }
  }, [onGetNationalTeamCompetitions, nationalTeamId, isReady]);

  React.useEffect(
    () => () => onResetNationalTeamCompetitions(nationalTeamId),
    [onResetNationalTeamCompetitions, nationalTeamId]
  );

  if (!isReady) {
    return <div className={styles.section} />;
  }

  return (
    <section className={styles.section}>
      <ul className={styles.list}>
        {competitions.map((n) => (
          <li className={`${styles.listItem}`} key={n.id}>
            <Link
              replace
              to={getUrl(path, nationalTeamId, n.id)}
              className={`${styles.link} ${
                n.id === Number(competitionId) ? styles.active : ""
              }`}
            >
              <CompetitionThemedBackground />
              {n.title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default NationalTeamCompetitions;
