export const REDUCER_NAME = "national";
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;
export const SET_TEAM = `${REDUCER_NAME}/SET_TEAM`;

export const NATIONAL_TEAM_ID = 40059;

export const ROUTES = {
  NEWS: "/articles",
  PAST_COMPETITIONS: "/competition",
  HISTORY: "/history",
  PLAYERS: "/players",
  TABLE: "/table",
  COACHES: "/coaches",
  TOP_SCORERS: "/top-scorers",
  APPEARANCES: "/appearances",
  EVENTS: "/",
};
