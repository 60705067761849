import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { getCompetitionTable, resetCompetitionTable } from "./actions";
import { CompetitionTable as Component } from "./competitionTable";
import {
  selectCompetitionStandings,
  selectIsError,
  selectIsReady,
} from "./reducer";

const mapStateToProps = createStructuredSelector({
  isReady: selectIsReady,
  isError: selectIsError,
  standings: selectCompetitionStandings,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompetitionTable: (data) => dispatch(getCompetitionTable(data)),
  onResetCompetitionTable: (data) => dispatch(resetCompetitionTable(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Component);
