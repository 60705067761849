import { connect } from "react-redux";

import { getMatchStatistics, resetMatchStatistics } from "./actions";
import { MatchStatistics as Component } from "./matchStatistics";
import {
  makeSelectInPlayStatistics,
  makeSelectIsError,
  makeSelectIsReady,
  makeSelectPastEncounters,
  makeSelectSeasonStatistics,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectInPlayStatistics = makeSelectInPlayStatistics();
  const selectIsError = makeSelectIsError();
  const selectIsReady = makeSelectIsReady();
  const selectPastEncounters = makeSelectPastEncounters();
  const selectSeasonStatistics = makeSelectSeasonStatistics();

  const mapStateToProps = (state, ownProps) => ({
    inPlay: selectInPlayStatistics(state, ownProps),
    pastEncounters: selectPastEncounters(state, ownProps),
    season: selectSeasonStatistics(state, ownProps),
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetMatchStatistics: (data) => dispatch(getMatchStatistics(data)),
  onResetMatchStatistics: (data) => dispatch(resetMatchStatistics(data)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
