/* This class is used to define which modules will be consumed from Api
   and which will be overridden.
   Follows the singleton pattern: https://www.sitepoint.com/javascript-design-patterns-singleton/ */

import Advertisement from "./advertisement";
import Competition from "./competition";
import Home from "./home";
import Match from "./match";
import NationalTeam from "./nationalTeam";
import Navigation from "./navigation";
import News from "./news";
import Player from "./player";
import Result from "./result";
import Router from "./router";
import Social from "./social";
import Team from "./team";

class Main {
  constructor() {
    if (!Main.instance) {
      this.router = new Router();
      this.home = new Home();
      this.match = new Match();
      this.result = new Result();
      this.competition = new Competition();
      this.team = new Team();
      this.player = new Player();
      this.news = new News();
      this.navigation = new Navigation();
      this.social = new Social();
      this.nationalTeam = new NationalTeam();
      this.advertisement = new Advertisement();

      Main.instance = this;
    }

    return Main.instance;
  }
}

const instance = new Main();
Object.freeze(instance);
export default instance;
