import { connect } from "react-redux";
import { compose } from "redux";

import { getMatchChannel } from "./actions";
import Component from "./competitionMatchChannel";
import {
  makeSelectChannel,
  makeSelectIsError,
  makeSelectIsReady,
} from "./reducer";

const makeMapStateToProps = () => {
  const selectIsReady = makeSelectIsReady();
  const selectIsError = makeSelectIsError();
  const selectChannel = makeSelectChannel();
  const mapStateToProps = (state, ownProps) => ({
    isReady: selectIsReady(state, ownProps),
    isError: selectIsError(state, ownProps),
    channel: selectChannel(state, ownProps),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  onGetMatchChannel: (data) => dispatch(getMatchChannel(data)),
});

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(
  Component
);
