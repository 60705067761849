import { toPage } from "../adapter";

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
        url: data.url,
      }
    : null;

export const toNewsPage = (data) =>
  data
    ? {
        ...toPage(data),
        tags: data.tags && data.tags.filter((n) => n),
        summary: data.summary,
        date: data.date,
        isFeatured: data.isFeatured,
        category: toNewsCategory(data.category),
        video: data.video,
      }
    : null;
